import React from "react";
import { Grid } from "react-bootstrap";
import { basePathClient } from '../../../helper/basePathClient';

const Footer = () => {

  return (
    <footer className="footer">
      <Grid fluid>
        <p className="copyright pull-right">
          &copy; {new Date().getFullYear()}{" "}
          <a href={basePathClient}>Plaid Application</a>
        </p>
      </Grid>
    </footer>
  );

}

export default Footer;
