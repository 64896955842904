import React, { useState } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import Card from '../../../Card/Card';
import moment from "moment";
import { inventoryServices } from '../../../../services/inventory';
import FieldGroup from '../../TransactionStatus/components/FieldGroup';
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { alertActions } from '../../../../redux/actions/alert-actions';
import XLSX from "sheetjs-style"
import { formatInventoryReport } from './formatInventoryReport';


const startDateDefault = moment()
	.subtract(10, "day")
	.format("YYYY-MM-DD");

const InventoryReport = () => {

	const today = moment().format("YYYY-MM-DD");
	const [startDate, setStartDate] = useState(startDateDefault);
	const [endDate, setEndDate] = useState(today);

	const dispatch = useDispatch()

  const exportCSV = async (obj) => {
    try {

      let res = await inventoryServices.getInventoryReport(obj)

      if (res && res.status == 'success') {

        let result = res.data;

        if (result && result.length > 0) {


          const formatData = formatInventoryReport(result)

          let ws = XLSX.utils.aoa_to_sheet(formatData);
          ws["!merges"] = [XLSX.utils.decode_range("A1:J1")];
          ws["!rows"] = [{ hpt: 20 }];
          ws["A1"].s = {
            font: {
              sz: 16,
              color: {
                rgb: "FF0000",
              },
            },
            fill: {
              fgColor: { rgb: "FFFF00" },
            },
            alignment: {
              horizontal: "center",
              vertical: "center",
            },
          };
          const get_header_row = (sheet) => {
            let range = XLSX.utils.decode_range(sheet["!ref"]);
            let C,
              R = 1;
            let headers = [];
            for (C = range.s.c; C <= range.e.c; ++C) {
              let cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
              var cell_address = { c: C, r: R };
              var cell_ref = XLSX.utils.encode_cell(cell_address);
              if (cell && cell.t) headers.push(cell_ref);
            }
            return headers;
          };
          const h = get_header_row(ws);
          h.map(
            (a) =>
            (ws[a].s = {
              font: {
                bold: true,
              },
              alignment: {
                horizontal: "center",
                vertical: "center",
              },
            })
          );
          ws['!cols'] = [{ width: 20 }, { width: 20 }, { width: 15 }, { width: 15 }, { width: 30 }, { width: 15 }, { width: 15 },  { width: 15 }, { width: 20 }, { width: 20 }];
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Log Detail");
          XLSX.writeFile(wb, "ReceivedItemsReport.xlsx");


          dispatch(alertActions.success(res.message))
        } else {
			dispatch(alertActions.error("No Inventory Record Found"));
		}
      }
      else {
        dispatch(alertActions.error(res.message))
      }

    } catch (error) {
      console.log(error);
    }
  };

	return (
		<>
			<div>
				<Grid fluid>
					<Row>

						<Card
							ctTableResponsive
							content={
								<>
									<Grid fluid>
										<div className='secHeading' style={{ marginLeft: 20 }}>
											<i
												className='fa fa-history'
												aria-hidden='true'
												style={{ marginRight: 10 }}
											></i>
											{'  '}
											Inventory Report
										</div>

									</Grid>
								</>
							}
						/>

						<Col style={{ marginLeft: 50, marginTop: 10 }}>
							<Col xs={12} sm={12} md={2} lg={2}	>
								<span style={{ fontSize: 20, color: '#9999A1' }}> Start Date</span>
								<FieldGroup
									id='startDate'
									type='date'
									name='startDate'
									value={startDate}
									className='fieldGroup'
									onChange={e => {
										const d = e.target.value
										if (!d.trim()) setStartDate(startDateDefault)
										else setStartDate(d)
									}}
									max={endDate}
								/>
							</Col>

							<Col xs={12} sm={12} md={2} lg={2} >
								<span style={{ fontSize: 20, color: '#9999A1' }}> End Date</span>
								<FieldGroup
									id='endDate'
									type='date'
									name='endDate'
									value={endDate}
									className='fieldGroup'
									onChange={e => {
										const d = e.target.value
										if (!d.trim()) setEndDate(today)
										else setEndDate(d)
									}}
									max={today}
								/>
							</Col>

							<Col md={1} lg={1} sm={12} xs={12}>
								<div style={{ marginTop: '28px' }}>
                  <Button
										type='submit'
										className='btn btn-lg srchBtn'
										onClick={e => {
											let dateObj = {
												startDate ,
												endDate
											}
											exportCSV(dateObj)
										}}
									>
										Export
									</Button>
								</div>
							</Col>
						</Col>
					</Row>
				</Grid>
			</div>
		</>
	)
}

export default InventoryReport
