import React, { useState, useRef } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import { Card } from 'components/Card/Card.jsx'
import Pagination from 'components/ThemeComponents/Pagination'
import { addresServices } from '../../../services/address'
import { emailServices } from '../../../services/email'
// import { dateFormatMy } from '../../../helper/helperFunctions'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap-modal'
import Form from './form'

const SettingsPage = () => {
  const [addressPage, setAddressPage] = useState(true)
  const [emailPage, setEmailPage] = useState(false)
  const [addEmailModal, setAddEmailModal] = useState(false)
  const [updateEmailModal , setUpdateEmailModal]  = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  const [addressId, setAddressId] = useState()
  const [emailId , setEmailId] = useState()

  const pagination = useRef()

  const columns = [
    {
      Header: 'Address',
      accessor: 'address',
      sortable: false,
      Cell: ({row , value}) => {
        return (
          <>
          <div>
            {/* {console.log('row----',row)} */}
            {`${row.address.toUpperCase()}, ${row._original.city.toUpperCase()}, ${row._original.state.toUpperCase()}, ${row._original.zipcode.toUpperCase()}`}
          </div>
          </>
        )
      }
    },
    {
      Header: 'Update',
      accessor: 'id',
      sortable: false,
      Cell: ({ value }) => (
        <div
          onClick={() => {
            setAddressId(value)
            setUpdateModal(true)
          }}
        >
          <Button bsStyle='primary' bsSize='small' style={{ border: 'none' }}>
            <span>
              <i
                className='fa fa-pencil-square-o'
                style={{ fontSize: 20 }}
                aria-hidden='true'
              ></i>
            </span>
          </Button>
        </div>
      )
    }
  ]
  const emailColumns = [
    {
      Header: 'Email',
      accessor: 'email',
      sortable: false
    },
    {
      Header: 'Update',
      accessor: 'id',
      sortable: false,
      Cell: ({ value }) => (
        <div
          onClick={() => {
            setEmailId(value)
            setUpdateEmailModal(true)
          }}
        >
          <Button bsStyle='primary' bsSize='small' style={{ border: 'none' }}>
            <span>
              <i
                className='fa fa-pencil-square-o'
                style={{ fontSize: 20 }}
                aria-hidden='true'
              ></i>
            </span>
          </Button>
        </div>
      )
    }
  ]

   const paginationCall = async (data) => {
    
   let res =await addresServices.getAddress(data);
   return res;

  }
  
  const emailPaginationCall = data => {
    return emailServices.getEmails(data)
  }

  const paginationReload = () => {
    pagination.current.dataCall()
  }

  return (
    <>
      <div>
        <Grid fluid>
          <Row>
            <Col>
              <Card
                ctTableResponsive
                content={
                  <div>
                    <Grid fluid>
                      <div className='secHeading' style={{ marginLeft: 20 }}>
                        <i
                          className='fa fa-cog'
                          aria-hidden='true'
                          style={{ marginRight: 10 }}
                        ></i>
                        {'  '}
                        Settings Page
                      </div>
                      <div
                        className='row'
                        style={{ margin: 'auto', background: '#F7F8FA' }}
                      >
                        <div className='col-md-8'>
                          <ul className='tabsLst'>
                            <li>
                              <div
                                className='btn btn-default btn-sm  tab_lbl'
                                style={{
                                  marginBottom: -1,
                                  backgroundColor: addressPage && '#006EEB',
                                  color: addressPage && 'white'
                                }}
                                onClick={() => {
                                  setAddressPage(true)
                                  setEmailPage(false)
                                }}
                              >
                               Warehouse Address
                              </div>
                            </li>

                            <li>
                              <div
                                className='btn btn-default btn-sm  tab_lbl'
                                style={{
                                  marginBottom: -1,
                                  backgroundColor: emailPage && '#006EEB',
                                  color: emailPage && 'white'
                                }}
                                onClick={() => {
                                  setAddressPage(false)
                                  setEmailPage(true)
                                }}
                              >
                                Email
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {addressPage && (
                        <div>
                          <Button
                            style={{
                              marginRight: 30,
                              marginTop: 10,
                              float: 'right',
                              border: 'white',
                              color: 'white',
                              backgroundColor: '#2F4576'
                            }}
                            onClick={() => setAddModal(true)}
                          >
                            Add Warehouse Address
                          </Button>
                          <Pagination
                            ref={pagination}
                            columns={columns}
                            getDataCall={paginationCall}
                            noDataText='No Item found'
                            showPagination={true}
                          />
                        </div>
                      )}

                      {/* for email page  */}
                      {emailPage && (
                        <div>
                          <Button
                            style={{
                              marginRight: 30,
                              marginTop: 10,
                              float: 'right',
                              border: 'white',
                              color: 'white',
                              backgroundColor: '#2F4576'
                            }}
                            onClick={() => setAddEmailModal(true)}
                          >
                            Add Email
                          </Button>
                          <Pagination
                            ref={pagination}
                            columns={emailColumns}
                            getDataCall={emailPaginationCall}
                            noDataText='No Item found'
                            showPagination={true}
                          />
                        </div>
                      )}
                    </Grid>
                  </div>
                }
              />
              {/* address form */}
              {addModal && (
                <Modal
                  backdrop={'static'}
                  show={addModal}
                  onHide={() => setAddModal(false)}
                  aria-labelledby='ModalHeader'
                >
                  <Modal.Header closeButton>
                    <Modal.Title id='ModalHeader' className='headerTitle'>
                      Add Warehouse Address
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="modelAddress">
                    <div className='row'>
                      <div className='col-md-12'>
                        <Card
                          bsClass={['innerCard mb-none modelAddress1']}
                          content={
                            <Form 
                              setAdd={setAddModal}
                              type='add'
                              paginationReload={paginationReload}
                            />
                          }
                        />
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
              {updateModal && (
                <Modal
                  backdrop={'static'}
                  show={updateModal}
                  onHide={() => setUpdateModal(false)}
                  aria-labelledby='ModalHeader'
                >
                  <Modal.Header closeButton>
                    <Modal.Title id='ModalHeader' className='headerTitle'>
                      Update Warehouse Address
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='row'>
                      <div className='col-md-12'>
                        <Card
                          bsClass={['innerCard mb-none']}
                          content={
                            <Form
                              setUpdate={setUpdateModal}
                              type='update'
                              id={addressId}
                              paginationReload={paginationReload}
                            />
                          }
                        />
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
              {/* addrss form end */}

              {/* email form */}

              {addEmailModal && (
                <Modal
                  backdrop={'static'}
                  show={addEmailModal}
                  onHide={() => setAddEmailModal(false)}
                  aria-labelledby='ModalHeader'
                >
                  <Modal.Header closeButton>
                    <Modal.Title id='ModalHeader' className='headerTitle'>
                      Add Email
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='row'>
                      <div className='col-md-12'>
                        <Card
                          bsClass={['innerCard mb-none']}
                          content={
                            <Form
                              setAdd={setAddEmailModal}
                              type='addEmail'
                              paginationReload={paginationReload}
                            />
                          }
                        />
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              )}

              {updateEmailModal && (
                <Modal
                  backdrop={'static'}
                  show={updateEmailModal}
                  onHide={() => setUpdateEmailModal(false)}
                  aria-labelledby='ModalHeader'
                >
                  <Modal.Header closeButton>
                    <Modal.Title id='ModalHeader' className='headerTitle'>
                      Update Email
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='row'>
                      <div className='col-md-12'>
                        <Card
                          bsClass={['innerCard mb-none']}
                          content={
                            <Form
                              setUpdate={setUpdateEmailModal}
                              type='updateEmail'
                              id={emailId}
                              paginationReload={paginationReload}
                            />
                          }
                        />
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
            </Col>
          </Row>
        </Grid>
      </div>
    </>
  )
}

export default SettingsPage
