import { SC } from 'helper/ServerCall';

export const adminUserService = {
    login,
    logout,
    dashboard,
    dashboardMenu,
    verifyUser,
    resendCode,
}
function login(email, password) {
    return SC.postCallLoginAdmin('auth/login', { email, password });
}
function verifyUser(email, code) {
    return SC.postCallLoginAdmin('auth/otp', { email, code });
}
function resendCode(email) {
    return SC.postCall('resend/otp', { email});
}

function logout() {
    return SC.getCall('logout');
}

function dashboard() {
    return SC.getCall('dashboard');
}

function dashboardMenu() {
    return SC.getCall('getMenu');
}