import React, { Component } from "react";
import { s3StaticPath } from 'helper/helperFunctions';

import {
  OverlayTrigger,
  Popover,
} from "react-bootstrap";


class MyImage extends Component {
	constructor(props) {
		super(props);
        this.state = {
            tooltip : '',
            src:this.props.src,
        }

        // this.
	}

	// addDefaultSrc = (ev) => {
 //        this.setState({tooltip: 'Image Not Available'}, ()=>{
 //            ev.target.src = s3StaticPath('img/image-placeholder.png');
 //        })

 //    }

    onError(ev) {
        ev.persist();
        this.setState({tooltip: 'Image Not Available'}, ()=>{
            ev.target.src = s3StaticPath('img/arrow_down.png');
        })

      // this.setState({
      //   imageUrl: "img/default.png"
      // })
    }


	render() {
        const { src, className, style, height, width, alt } = this.props;
        // console.log('src', src);
        const { tooltip } = this.state;
		return (
            <React.Fragment>
                {
                    src 
                    ?
                        tooltip
                        ? 
                            <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{ tooltip } </Popover>}>
                                <img alt={alt} src={src} /*onError={this.onError.bind(this)}*/ onError={() => {
                            setTimeout(
                                this.setState({
                                    src: src + "?updated_at="+Math.floor((Math.random() * 100) + 1)
                                }), 1000
                            )
                        }} className={className} style={style} height={height} width={width}  />
                            </OverlayTrigger>
                        :
                            <img alt={alt} src={src} /*onError={this.onError.bind(this)}*/ onError={() => {
                            setTimeout(
                                this.setState({
                                    src: src + "?updated_at="+Math.floor((Math.random() * 100) + 1)
                                }), 1000
                            )
                        }}  className={className} style={style}  height={height} width={width} />
                    :
                        null
                }
            </React.Fragment>
        )
	}
}

export default MyImage;