export const generalActions = {
    success,
    failure
}

function success(constant, payload) {
    return {
        type: constant,
        payload: payload
    }
}

function failure(constant, payload) {
    return {
        type: constant,
        payload: payload
    }
}