import React from 'react'
import {
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock
} from 'react-bootstrap'

export default function FieldGroup ({ id, label, help, ...props }) {
  const value = props.value || ''
  return (
    <FormGroup controlId={id}>
      <ControlLabel style={{ fontSize: '12px' }}>{label}</ControlLabel>
      <FormControl {...props} value={value} />
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  )
}
