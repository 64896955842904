// import { adminConstants } from '../constant/admin-constant';
// import { objectsConstants as OC } from '../constant/objects-constant';

const initialState = {
	loadingSettings: false,
	
};

export function settingsReducer(state = initialState, action) {

	switch (action.type) {
			
		default:
			return { ...state };
	}
}