const styles = {
  searchContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  searchInput: {
    width: 600,
    padding: '12px 20px',
    margin: '8px 0',
    display: 'inline-block',
    border: '1px solid #ccc',
    borderRadius: 4,
    boxSizing: 'border-box',
  },

  emailSelectControl: {
    minheight:' 40px !important'
  },

  formContainer: { margin: 5, marginLeft: 40, marginRight: 40 },
  formInputsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 8,
  },
  inputContainer: { display: 'flex', flexDirection: 'column' },
  title: { fontWeight: 900, fontSize: '16px', color: '#5d5d5d' },
  input: {
    width: 180,
    // border: "none",
    // borderBottom: "2px solid blue",
    padding: 5,
    border: '1px solid #ccc',
  },
  smallInput: {
    width: 70,
    // border: "none",
    // borderBottom: "2px solid blue",
    padding: 5,
    border: '1px solid #ccc',
    paddingLeft: 14,
  },
  readonlyInput: {
    width: 130,
    //paddingLeft: 5,
    fontWeight: 900,
    border: 'none',
    fontSize: 'large',
  },
  select: {
    width: 220,
    // border: "none",
    // borderBottom: "2px solid blue",
    padding: 5,
    border: '1px solid #ccc',
    // padding: 2,
    outline: 'none',
    // backgroundColor: '#FFFFFF',
    backgroundColor: '#fafafa',
   
  },
  
  inputListContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  cancelBtn: {
    border: 'white',
    color: 'white',
    backgroundColor: '#EE2D20',
  },
  updateBtn: {
    border: 'white',
    color: 'white',
    backgroundColor: '#2F4576',
  },
  dollar: {
    position: 'absolute',
    marginTop: 23,
    //marginLeft: 2,
    marginRight:2,
  },
  imageUploaderContainer: {
    width: '100%',
    height: '220px',
    border: '1px dashed black',
    backgroundColor: '#d3d2d2',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  divTextAreaNote: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },

  textAreaNote: {
    width: '800px',
    height: '50px',
    marginLeft: '10px',
    border: '1px solid #d3d2d2',
  },
  fileUploaderDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  errorLogHeaders : {
    fontWeight: "bold",
    fontSize: "20px",
    color: "2f4576"
  }
}

export default styles