import React from 'react';
import Modal from 'react-bootstrap-modal';
import { Button } from 'react-bootstrap';
import styles from '../searchModalStyles';
import FieldGroup from "./FieldGroup";
import moment from "moment";

const AlertModel = (props) => {

	const { accountSchedulerDate, setAccountSchedulerDate } = props

    const handleConfirm = () => {
        props.onHide(true)
        props.onConfirm();
    }

	const today = moment().format("YYYY-MM-DD");

    return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             <h4> {props.heading} </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
				{
					props.buttonTextFlag ? <FieldGroup
						id='schedulerDate'
						type='date'
						label='Scheduler date'
						name='schedulerDate'
						value={ accountSchedulerDate.schedulerDate }
						className='fieldGroup'
						onChange={e => {
							const d = e.target.value
							if (!d.trim()) { setAccountSchedulerDate({
								...accountSchedulerDate,
								schedulerDate: accountSchedulerDate.schedulerDate
							})}

							else setAccountSchedulerDate({
								...accountSchedulerDate,
								schedulerDate: d
							})
						}}
						max={today}
					/>
						:
						<p>
							{props.alert}
						</p>
				}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide} style={styles.cancelBtn}> { props.buttonTextFlag ? "Cancel" : "No" } </Button>
            <Button onClick={handleConfirm} style={styles.updateBtn} >{ props.buttonTextFlag ? "Save" : "Yes" }</Button>
          </Modal.Footer>
        </Modal>
      );


}

export default AlertModel;