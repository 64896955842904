import React from 'react'
import Modal from 'react-bootstrap-modal'
import './styles.css'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader

const ImageViewerModal = ({
  open,
  setOpen,
  imageUploaderData,
  setImageUploaderData,
  stateData,
  invoiceImg,
  setStateData,
}) => {
  const idD = parseInt(imageUploaderData.DataId)
  const idI = parseInt(imageUploaderData.ItemId)
  const idS = parseInt(imageUploaderData.ShipmentId)

  return (
    <>
      <Modal
        backdrop={'static'}
        show={open}
        onHide={() => setOpen(false)}
        aria-labelledby='ModalHeader'
        dialogClassName='fullscreenmodal invoice-modal-content'
      >
        <Modal.Header closeButton>
          <Modal.Title
            id='ModalHeader'
            className='headerTitle'
            style={{ width: 600 }}
          ></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              width:  invoiceImg ? '': '600px',
              marginLeft:invoiceImg ? 0 : '20%',
            }}
          > {invoiceImg ?
            <Carousel showIndicators={false} showThumbs={false} autoPlay={false}>
              {
                <img
                  // style={{  width: 'fit-content' }}
                  src={stateData.source}
                  alt=''
                />
              }
            </Carousel>

            :
            <Carousel showIndicators={true} showThumbs={false} autoPlay={false}>
              {stateData[idD].shipments[idS].items[idI].images.map((image) => {
                return (
                  <img
                    style={{ heigth: '800px', width: '800px' }}
                    src={image.source}
                    alt=''
                  />
                )
              })}
            </Carousel>
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ImageViewerModal
