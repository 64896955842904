  const h1 = [
  'Trans ID',
  'Amount',
  'Card',
  'Date',
  'Merchant Name',
  'Transaction Type',
  'Transaction State',
  'Order Number',
  'Shipping Address',
  // 'Zip Code',
  // 'State ',
  // 'City',
  'Email address',
  // 'Tracking number',
  'Order Status',
  'Taxes',
  'Shipping',
  'Invoice Attached'
]

const h2 = ['item name', 'Qty', 'Price'];
const h3 = ['Courier', 'Tracking Number'];
const h4 =['Shipment Item Name','Expected Qty'];


let inventories;

const formatData = data => {
  let loi = [];
  let los ;
  // let sI;
  let fullData = [];
  // let fullShipmentData = [];
  let shipmentData ;
  data.forEach(n => {
    let temp = [];
    // let temp2 = [];
    let orderData = formatOrderData(n);
    if(n.shipments.length > 0){
      shipmentData  =  formatShipmentData(n.shipments);
     
    }
    
    temp = [...orderData];
    loi.push(n.items.length);
    los = n.shipments ? n.shipments.length : 0;
    if(n.items.length > 0){
      for(let i=0; i<7; i++){
        if(!n.items[i]){
          let emptyItems = ['','','',];
          temp = [...temp, ...emptyItems]
 
        }else{
         let inventoriesData = formatInventories(n.items[i]);
       temp = [...temp, ...inventoriesData]
        }
       
     }
    }
    
   
   if(shipmentData){
    temp = [...temp, ...shipmentData];
    shipmentData =null;
   } 
   
   fullData.push(temp);
    
    
  })
  return { data: fullData, length: loi, shipments:los,  shipmentData}
}

const formatOrderData = o => {
  let convertedAmount = parseFloat(o.amount)
  let address = o.order && o.order.address ? `${o.order.address.address},${o.order.address.city},${o.order.address.state},${o.order.address.zipcode}`.trim() : ''
   
  return [
    o.transaction_id,
    convertedAmount,
    o.account_name,
    o.date,
    o.name,
    o.transaction_type.name,
    o.transaction_state.name,
    o.order ? o.order.order_number : '',
    address,
    o.email ? o.email.email : '',
    // o.order ? o.order.tracking_number : '',
    o.order ? o.order.status : '',
    o.order ? o.order.taxes : '',
    o.order ? o.order.shippingNo : '',
    o.invoice ? 'Yes' : 'No'
    
  ]
}


const formatShipmentData = data =>{

let finalData=null;
    data.forEach((d)=>{
      let temp;
      let shipmentData  = getShipmentP(d);
      temp=[...shipmentData]
     
      for(let i=0 ;i<7; i++){
        let emptData = []
        if(!d.items[i]){
          emptData.push('');
          emptData.push('')
          temp = [...temp, ...emptData];
        }else{
          let inventoriesData = formatShipmenInventory(d.items[i]);
          temp = [...temp, ...inventoriesData];
        }
           
      }
    
      if(finalData){
        finalData=[...finalData, ...temp];
      }
       else{
        finalData=[ ...temp];
       }
    });
return finalData;
}
const getShipmentP = (s) => {
  return [
    
    s.courier ? s.courier : '',
    s.tracking_number? s.tracking_number: ''
  ]
}
const findInventory = (id) => {
  if(!!(id)){
  return inventories.find((e) => e.id === id).name;
  }
  return "";
};

const formatInventories = x => {
  let name = findInventory(x.inventory_id);
  
  return [name, x.qty_ordered, x.price]
}

const formatShipmenInventory = s => {
  let name = findInventory(s.inventory_id);
   return [name ? name:'' , s.qty_expected? s.qty_expected:0]
}


const createHeader = (h1, h2,h3,h4, il,sl) => {
  let header
  let a
  let b
  let c
  let d 
  let e
  if (il) {
    a = new Array(il).fill(h2).flat()
  }
  c= new Array(7).fill(h4).flat()
b= new Array(1).fill(h3).flat()
d = b.concat(c)
e= new Array(5).fill(d).flat()

  header = h1.concat(a).concat(e)
  return header
}

const createData = (formattedData, header) => {
  return [['Do not edit!'], header, ...formattedData]
}

const exportData = (data,items )=> {
  inventories = items;
  
  const data1 = formatData(data);
  const formattedData = data1.data
  let il = data1.length;
  if (il) il = Math.max(...il) + 7;
  let sl = data1.shipments;
  const header = createHeader(h1, h2,h3,h4,7,5);
  const res = createData(formattedData, header)

  
  return res
}

export { exportData }
