import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { dashboardMenu } from "redux/actions/admin-user-action";
import dashboardRoutes from "routes/dashboard.jsx";

class SidebarStaticPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      dashboardName: "GTV",
      iconHeight: "20px",
      iconWidth: "20px",
      menu: dashboardRoutes,
    };

    // this.OpenDropDown = this.OpenDropDown.bind(this);
    this.activeRoute = this.activeRoute.bind(this);
    this.createUl = this.createUl.bind(this);
    this.createLi = this.createLi.bind(this);
    this.closeSideBar = this.closeSideBar.bind(this);
  }

  createLi(menu, key) {
    return (
      <li id={"id-" + key} className={this.activeRoute(menu["path"])} key={key}>
        <span className="nav-link" onClick={this.closeSideBar}>
          <p>
            <Link to={menu["path"]}>
              {" "}
              <span style={{ marginRight: 3 }} className={`${menu["icon"]}`}>
                &nbsp;
              </span>
              {menu["name"]}
            </Link>
          </p>
        </span>
      </li>
    );
  }
  closeSideBar() {
    document.querySelector(".Sidebar").classList.toggle('active');
    document.querySelector("#main-panel").classList.toggle('active');
    document.querySelector(".header-menu-bars").classList.toggle('fa-times');
    document.querySelector(".header-menu-bars").classList.toggle('fa-bars');
  }

  createUl(menu, ulClass) {
    return (
      <ul className={ulClass}>
        {menu.map((m, key) => {
          return !("redirect" in m) ? this.createLi(m, key) : "";
        })}
      </ul>
    );
  }
  // OpenDropDown(id) {
  //   var sub_height;
  //   if ($("#" + id).hasClass("openSubMenu")) {
  //     $("#" + id).removeClass("openSubMenu");
  //     sub_height = $(".SidebarStatic").outerWidth();
  //     $(".main-panel").css({ width: "calc(100% - " + sub_height + "px)" });
  //   } else {
  //     $("#" + id).addClass("openSubMenu");
  //     sub_height = $(".SidebarStatic").outerWidth();
  //     $(".main-panel").css({ width: "calc(100% - " + sub_height + "px)" });
  //   }
  // }
  activeRoute(routeName) {
    // var dashboardMenuTemp  = this.props.menu;
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    var checkLogin = localStorage.getItem("user");
    if (checkLogin) {
      // this.props.getMenuCall();
      this.OnLoadIfOpenSubMenu();
    }
  }
  componentDidUpdate() {
    this.OnLoadIfOpenSubMenu();
  }
  OnLoadIfOpenSubMenu() {
    // $("li.active")
    //   .parents("li")
    //   .addClass("openSubMenu");
    // $(".Sidebar").toggleClass("active");
  }
  render() {
    const { menu } = this.state;
    return (
      <div
        id="Sidebar"
        className="Sidebar"
        data-color="#2c3356"
      // onMouseEnter={this.props.showSideNav}
      // onMouseLeave={this.props.hideSideNav}
      >
        <div className="Sidebar-background" />
        <div className="Sidebar-wrapper">
          {/* {this.state.width <= 991 ? <HeaderLinks /> : null} */}
          {typeof menu !== "undefined" &&
            Object.keys(menu).length > 0 &&
            this.createUl(menu, "nav")}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMenuCall: () => {
      dispatch(dashboardMenu());
    },
  };
};

function mapStateToProps(state) {
  const { userAdmin, loading } = state.adminReducer;
  return {
    userAdmin,
    loading,
  };
}

const SidebarStatic = connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarStaticPage);
export default SidebarStatic;
