import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import useValidator from 'components/ThemeComponents/useValidator'
import { addresServices } from '../../../services/address'
import { emailServices } from '../../../services/email'
import { useDispatch } from 'react-redux'
import { alertActions } from 'redux/actions/alert-actions'

const Form = ({ type, setAdd, setUpdate, id, paginationReload }) => {

  const [validator, showValidationMessage] = useValidator()

  const [addressObj, setAddressObj] = useState({
    address: '',
    zipcode: '',
    state: '',
    city: ''
  })
  const [email , setEmail] = useState('')
  const dispatch = useDispatch()


  const { address, zipcode, state, city } = addressObj

  const handleSubmit = () => {
    if (validator.allValid()) {
      if (type === 'add') {

        handleAddAddress()

      }
      if (type === 'update') {
        handleUpdateInventory()
        setUpdate(false)
      }
    } else {
      showValidationMessage(true)
    }
  }

  // addAddress
  const handleAddAddress = async () => {
    await addresServices.addAddress(addressObj).then(
      response => {
        dispatch(alertActions.success(response.message))
        setAdd(false)
      },
      error => {
        dispatch(alertActions.error(error))
      }
    )
    await paginationReload()
  }
  const handleUpdateInventory = async () => {
    await addresServices.updatedAddress(addressObj).then(
      response => {
        dispatch(alertActions.success(response.message))
        setUpdate(false)
      },
      error => {
        dispatch(alertActions.error(error))
      }
    )
    await paginationReload()
  }

  const getAddressData = async () => {
    const res = await addresServices.getSingleAddress(id)
    setAddressObj(res.data)

  }

  useEffect(() => {
    if (type === 'update') getAddressData()
  }, [type])

  //for emails
  const handleEmailSubmit = () => {
    if (validator.allValid()) {
      if (type === 'addEmail') {
        handleAddEmail()
      }
      if (type === 'updateEmail') {
        // setUpdate(false)
        handleUpdateEmail()
      }
    } else {
      showValidationMessage(true)
    }
  }

  const handleAddEmail = async () => {
    let emailObj= {
      email
    }
    await emailServices.addEamil(emailObj).then(
      response => {
        dispatch(alertActions.success(response.message))
        setAdd(false)
      },
      error => {
        dispatch(alertActions.error(error))
      }
    )
    await paginationReload()
  }
  const handleUpdateEmail = async () => {
    let emailObj= {
      email,
      id
    }
    await emailServices.updatedEmail(emailObj).then(
      response => {
        dispatch(alertActions.success(response.message))
        setUpdate(false)
      },
      error => {
        dispatch(alertActions.error(error))
      }
    )
    await paginationReload()
  }

  const getEmailData = async () => {
    const res = await emailServices.getSingleEmail(id)
		if(res && res.data) {
			setEmail(res.data.email)
		}
  }

  useEffect(() => {
    if (type === 'updateEmail') getEmailData()
  }, [type])

  const handleEnter = event => {
    if (event.key.toLowerCase() === 'enter') {
      const form = event.target.form
      const index = [...form].indexOf(event.target)
      form.elements[index + 1].focus()
      event.preventDefault()
    }
  }

  return (
    <div>
      {type === 'add' ||  type ==='update' ? (
        <form className='shadow-lg ' style={{minHeight: 180}}  >
          <div className="form" style={{display:"flex"}}>
          <div className='form-group col-lg-12 col-md-12 '>
            <label htmlFor='Address'>Address</label>
            <input
              type='text'
              id='Address'
              className='form-control'
              name='Address'
              value={address ? address : ''}
              onKeyDown={handleEnter}
              onChange={({ target: { value } }) => {
                let obj = {
                  ...addressObj,
                  address: value
                }
                setAddressObj(obj)
              }}
            />
            {validator.message('Address', address, 'required', {
              messages: {
                required: 'Address is required'
              }
            })}
          </div>

          <div className='form-group col-lg-8 col-md-12 '>
            <label htmlFor='City'>City</label>
            <input
              type='text'
              id='City'
              className='form-control'
              name='City'
              onKeyDown={handleEnter}
              value={city ? city : ''}
              onChange={e => {
                let obj = {
                  ...addressObj,
                  city: e.target.value
                }
                setAddressObj(obj)
              }}
            />
            {validator.message('City', city, 'required', {
              messages: {
                required: 'City is required'
              }
            })}
          </div>

          <div className='form-group col-lg-8 col-md-12 '>
            <label htmlFor='State'>State</label>
            <input
              type='text'
              id='State'
              className='form-control'
              name='State'
              value={state ? state : ''}
              onKeyDown={handleEnter}
              onChange={({ target: { value } }) => {
                let obj = {
                  ...addressObj,
                  state: value
                }
                setAddressObj(obj)
              }}
            />
            {validator.message('State', state, 'required', {
              messages: {
                required: 'State is required'
              }
            })}
          </div>

          <div className='form-group col-lg-8 col-md-12 '>
            <label htmlFor='Code'>Zip Code</label>
            <input
              type='text'
              id='Code'
              className='form-control'
              name='Code'
              value={zipcode ? zipcode : ''}
              onKeyDown={handleEnter}
              onChange={({ target: { value } }) => {
                let obj = {
                  ...addressObj,
                  zipcode: value
                }
                setAddressObj(obj)
              }}
            />
            {validator.message('Code', zipcode, 'required', {
              messages: {
                required: 'Zip Code is required'
              }
            })}
          </div>

          </div>

          <div
            className='form-group col-lg-12 col-md-12 '
            style={{display: 'flex',justifyContent: 'flex-end', marginBottom: -10 }}
          >

            <Button
              bsSize='small'
              style={{ border: 'white',  marginRight: 10, color: 'white', backgroundColor: '#2F4576' }}
              onClick={handleSubmit}
            >
              {type === 'add' ? 'Add' : type === 'update' ? 'Update' : null}
            </Button>
            <Button
              bsSize='small'
              style={{  border: 'white', color: 'white', backgroundColor: '#EE2D20' }}
              onClick={
                type === 'add'
                  ? () => setAdd(false)
                  : type === 'update'
                  ? () => setUpdate(false)
                  : null
              }
            >
              Close
            </Button>
          </div>
        </form>
      ) : (
        <form className='shadow-lg'  style={{minHeight: 150}}>
          <div className='form-group col-lg-12 col-md-12 '>
            <label htmlFor='Email'>Email</label>
            <input
              type='email'
              id='Email'
              className='form-control'
              name='Email'
              value={email ? email : ''}
              onChange={({ target: { value } }) =>  setEmail(value) }
            />
            {validator.message('Email', email, 'required|email', {
              messages: {
                required: 'Email is required'
              }
            })}
          </div>

          <div
            className='form-group col-lg-12 col-md-12 '
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              bsSize='small'
              style={{ marginRight: 10, border: 'white', color: 'white', backgroundColor: '#2F4576' }}
              onClick={handleEmailSubmit}
            >
              {type === 'addEmail' ? 'Add' : type === 'updateEmail' ? 'Update' : null}
            </Button>
            <Button
              bsSize='small'
              style={{ border: 'white', color: 'white', backgroundColor: '#EE2D20' }}
              onClick={
                type === 'addEmail'
                  ? () => setAdd(false)
                  : type === 'updateEmail'
                  ? () => setUpdate(false)
                  : null
              }
            >
              Close
            </Button>
          </div>
        </form>
      )}
    </div>
  )
}

export default Form
