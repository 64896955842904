import { SC } from '../helper/ServerCall'

export const transactionServices = {
         getTransactionTypes,
         changeTransactionType,
         storeAndImportItemsBadge,
         getInventoriesAsync,
         storeInventory,
         exportCSV,
         importCSV,
         editItem,
         saveManualTransaction,
         bulkUpdateItems,
         getTransactionStates,
         deleteItem,
         getTransactionType,
         searchByOrderOrTrackingNo,
         searchByOrderNoOrTrackingNo,
         importFromExcel,
         ExportAllDataCsv,
         updateSearchResults,
         getRefundTransaction,
         attachRefundTransaction,
         getRefundParent,
         sumOfTransactions,
         sumOfUnreceivedTransactions,
         getAbnormalTransactionsOpen,
         getAbnormalTransactionsRefund,
         deleteShipment,
         shipmentDetails,
         getTrackingDetails,
         createNewItems,
         markAsReceived,
         returnTransactions,
         allInvoice,
         mergeTransaction,
         unmergeTransaction,
         attachDetails

       }

function getTrackingDetails(id) {
  return SC.getCall(`getTrackingDetails/${id}`)
}

function shipmentDetails(data) {
  return SC.postCall(`shipmentDetails`, data)
}

// for attched new order no and details
function attachDetails(data) {
  return SC.postCall(`attachDetails`, data)
}


function createNewItems(data) {
  return SC.postCall(`createInventory`, data)
}

function saveManualTransaction(data) {
  return SC.postCall(`storeManulTransaction`, data)
}

function mergeTransaction(data) {
  return SC.postCall(`mergeTransaction`,data)
}

function unmergeTransaction(data) {
  return SC.postCall(`unmergeTransaction`,data)
}

function markAsReceived(data) {
  return SC.postCall(`markRecived`,data)
}

function deleteShipment(id) {
  return SC.deleteCall(`deleteShipment/${id}`)
}

function sumOfTransactions() {
  return SC.getCall(`sumOfTransactions`)
}

function allInvoice() {
  return SC.getCall(`allInvoice`)
}

function sumOfUnreceivedTransactions() {
  return SC.getCall(`sumOfUnreceivedTransactions`)
}

function getRefundParent(id) {
  return SC.getCall(`getRefundParent?transaction_id=${id}`)
}

function attachRefundTransaction(data) {
  return SC.postCall(`attachRefundTransaction`, data)
}

function getRefundTransaction(order_number) {
  return SC.getCall(`getRefundTransaction?order_number=${order_number}`)
}

function updateSearchResults(data) {
  return SC.postCall(`updateSearchResults`, data)
}

function getTransactionTypes() {
  return SC.getCall(`getTransactionTypes`)
}

function changeTransactionType(data) {
  return SC.postCall(`changeType`, data)
}

function storeAndImportItemsBadge(data) {
  return SC.postCall(`storeAndImportItemsBadge`, data)
}

function getInventoriesAsync(data) {
  return SC.postCall(`getInventoriesAsync`, data).then((response) => {
    return response.getInventories
  })
}

function storeInventory(data) {
  return SC.postCall(`storeInventory`, data)
}

function exportCSV(data) {
  return SC.postCall(`getExportCsvData`, data)
}

function importCSV(data) {
  return SC.postCall(`storeAndImportItemsBadge`, data)
}

function editItem(data, id) {
  return SC.postCall(`editItem/${id}`, data)
}

function getTransactionStates() {
  return SC.getCall(`getTransactionStates`)
}

function deleteItem(id) {
  return SC.deleteCall(`deleteItem/${id}`)
}

function getTransactionType(type = 'refund') {
  return SC.getCall(`getTransactionType?type=${type}`)
}

function searchByOrderOrTrackingNo(query) {
  return SC.getCall(`searchByOrderOrTrackingNo?query=${query}`)
}

function searchByOrderNoOrTrackingNo(query) {
  //console.log('query',query)
  //return SC.getCall(`searchByOrderNoOrTrackingNo?query=${query}`)
  const data=[query];
  return SC.postCall(`searchByOrderNoOrTrackingNo`, data)
}

function bulkUpdateItems(data) {
  return SC.postCall(`bulkUpdateItems`, data)
}

//to export all transaction
function ExportAllDataCsv(data) {
  return SC.postCall(`ExportAllDataCsv`, data)
}

function importFromExcel(data) {
  return SC.postCall(`importFromExcel`, data)
}

function getAbnormalTransactionsOpen(data) {
  return SC.postCall(`getAbnormalTransactionsOpen`, data)
}

function returnTransactions(data) {
  return SC.postCall(`returnTransactions`, data)
}

function getAbnormalTransactionsRefund(data) {
  return SC.postCall(`getAbnormalTransactionsRefund`, data)
}
