import React, { Component } from "react";
import { CSVLink } from "react-csv";
import _get from 'lodash.get'
import { returnDynamicURL } from "../../helper/helperFunctions";

class reactDataToCSV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataToDownload: []
        }
        this.download = this.download.bind(this);
    }

    download(event) {
        //OLD CODE
        const { currentRecordsRef, columnsData } = this.props;
        let currentRecordsData = currentRecordsRef.resolvedData;

        //NEW CODE
        const DataToDownload = [];
        const columnsToDownload = {};

        columnsData.forEach(column => {
            if (column.excExp === true) {
                let accessor = column.accessor.split('.')[0];
                accessor = accessor.replace('[0]', '');
                columnsToDownload[accessor] = column;
            }
        });

        currentRecordsData.forEach(record => {
            let currentRecord = {};
            Object.keys(record).forEach((item, idx) => {
                if (item in columnsToDownload) {
                    if (columnsToDownload[item].csvMod) {
                        if (record[item] instanceof Array) {
                            let arr = record[item];
                            let output = '';
                            arr.forEach(item => {
                                if (item.csv_value)
                                    output += item.csv_value + '\n';
                            })
                            currentRecord[columnsToDownload[item].Header] = output;
                        } else {
                            if (columnsToDownload[item].Header === 'Traveler Quick Login') {
                                let match, result;
                                match = document.domain.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\n]+)/im);
                                if (match) {
                                    result = match[1]
                                    let domainSlugs = ['gtv', 'brt', 'mci', 'eti', 'itk', 'act'];
                                    if (domainSlugs.includes(result.split('.')[0])) {
                                        result = result.split('.').slice(1).join('.');
                                    }
                                }
                                let link = returnDynamicURL(record.accounts[0].domain_slug, record.username, record.password)
                                currentRecord[columnsToDownload[item].Header] = link
                            } else {
                                currentRecord[columnsToDownload[item].Header] = record[item].csv_value;
                            }
                        }
                    } else {
                        let value = _get(record, columnsToDownload[item].accessor);
                        if (item === 'barcode') {
                            value = `=""${value}""`
                        }
                        currentRecord[columnsToDownload[item].Header] = value;
                    }
                }
            })
            DataToDownload.push(currentRecord);
        })
        console.log(DataToDownload);
        // currentRecordsData = currentRecordsRef.resolvedData;

        //OLD CODE
        // if ('contactLog' in this.props) {
        // 	currentRecordsData = currentRecordsRef.resolvedData;
        // } else {
        // 	currentRecordsData = currentRecordsRef.getResolvedState().sortedData;
        // }

        // currentRecordsData.forEach(record => {
        // 	console.log(record);
        // })

        //OLD CODE
        // var data_to_download = [];
        // // console.log(columnsData[2].accessor)
        // for (var index = 0; index < currentRecordsData.length; index++) {
        // 	let record_to_download = {}
        // 	var temp = currentRecordsData[index];
        // 	for (var colIndex = 0; colIndex < columnsData.length; colIndex++) {
        // 		if (columnsData[colIndex].excExp && columnsData[colIndex].csvMod) {
        // if ((temp[columnsData[colIndex].accessor] instanceof Array)) {
        // 	let arr = temp[columnsData[colIndex].accessor];
        // 	let output = '';
        // 	arr.forEach(item => {
        // 		if (item.csv_value)
        // 			output += item.csv_value + '\n';
        // 	})
        // 	record_to_download[columnsData[colIndex].Header] = output;
        // }
        // else if (temp[columnsData[colIndex].accessor].csv_value) {
        // 	record_to_download[columnsData[colIndex].Header] = temp[columnsData[colIndex].accessor].csv_value
        // }
        // 		} else if (columnsData[colIndex].excExp) {
        // 			record_to_download[columnsData[colIndex].Header] = temp[columnsData[colIndex].accessor]
        // 		}
        // 		if(temp[columnsData[colIndex].accessor]===undefined){
        // 			console.log(columnsData[colIndex].accessor);
        // 		}
        // 	}
        // 	data_to_download.push(record_to_download)
        // }

        this.setState({ dataToDownload: DataToDownload }, () => {
            this.csvLink.link.click()
        })
    }
    render() {
        const { fileName } = this.props;
        const { dataToDownload } = this.state;
        return (
            <React.Fragment>
                <button onClick={this.download}>
                    Download
                </button>
                <CSVLink
                    data={dataToDownload}
                    filename={fileName + ".csv"}
                    className="hidden"
                    ref={(r) => this.csvLink = r}
                    target="_blank"
                />
            </React.Fragment>
        );
    }
}

export default reactDataToCSV;
