import React, { useState, useEffect } from 'react'
import FieldGroup from './components/FieldGroup'
//import { Grid, Row, Col, Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { alertActions } from 'redux/actions/alert-actions'
import { ORDER_STATUSES, CARRIERS } from '../../../constants'
import { transactionServices } from '../../../services/transaction'

const ShowRefundItems = ({
  row,

  inventories,
  setExpended1,
}) => {
  const dispatch = useDispatch()
  const [refundtransactionDetail, setRefundTransactionDetail] = useState()
  const [refundOrdrStatus, setRefundOrdrStatus] = useState({
    id: null,
    status: null,
  })

  const [refundOrderNo, setRefundOrderNo] = useState('')
  const [alreadyLinked, setAlreadyLinked] = useState(false)

  useEffect(() => {
    transactionServices.getRefundParent(row.original.id).then(
      (response) => {
        console.log('response', response)
        setRefundTransactionDetail(response.data.parent)
        if (response.data.parent) {
          setRefundOrdrStatus({
            id: response.data.parent.order.id,
            status: response.data.parent.order.status,
          })
          setAlreadyLinked(true)
          setRefundOrderNo(response.data.parent.order.order_number)
        }
      },
      (error) => {
        console.log('error', error)
      }
    )
  }, [])

  const findInventory = (id) => {
    return inventories.find((e) => e.id === id).name
  }

  const setrefundOrderStatusValue = (e) => {
    setRefundOrdrStatus({ ...refundOrdrStatus, status: e.target.value })
  }

  const refundSubmitInventory = (e, rowId) => {
    e.preventDefault()

    if (!refundtransactionDetail) {
      dispatch(alertActions.error([['Link it first than you can store']]))
      return false
    }

    const data = {
      parent_id: refundtransactionDetail.id,
      child_id: rowId,
      order: refundOrdrStatus,
    }

    //console.log('--------data', data)

    transactionServices.attachRefundTransaction(data).then(
      (response) => {
        //console.log('response', response)
        dispatch(alertActions.success(response.message))
        setRefundTransactionDetail(null)
        setExpended1({ 0: false })
      },
      (error) => {
        dispatch(alertActions.error(error))
      }
    )
  }

  const linkRefundTransaction = async () => {
    // console.log('transactionDetails', transactionDetail)

    if (!refundOrderNo) {
      dispatch(alertActions.error('add order number to link'))
      return
    }

    let res = await transactionServices.getRefundTransaction(refundOrderNo)

    // console.log('========res', res)

    if (res.status === false) {
      dispatch(alertActions.error(res.message))
      setRefundTransactionDetail(null)
      //setTransactionDetail(null)
      return
    } else {
      setRefundTransactionDetail(res.data)
      setRefundOrdrStatus({
        id: res.data.order.id,
        status: res.data.order.status,
      })
      setRefundOrderNo(res.data.order.order_number)
      //setTransactionDetail(res.data.items)
    }
  }

  return (
    <form
      onSubmit={(e, id) => {
        refundSubmitInventory(e, row.original.id)
      }}
      style={{ overflowX: 'scroll' }}
      method='post'
    >
      <div style={{ width: '95%', margin: 'auto' }}>
        <div className='row'>
          <div className='col-sm-4'>
            <FieldGroup
              id='order_number'
              type='text'
              label='ORDER NUMBER'
              name='order_number'
              value={
                refundOrderNo
                // refundtransactionDetail
                //   ? refundtransactionDetail.order.order_number
                //   : refundOrderNo
              }
              onChange={(e) => {
                setRefundOrderNo(e.target.value)
                // setOrderValues(e)
              }}
            />
          </div>

          <div className='col-sm-4'>
            <FieldGroup
              id='email'
              type='email'
              label='EMAIL'
              name='email'
              value={
                refundtransactionDetail
                  ? refundtransactionDetail.vendor.email
                  : ''
              }
              readOnly
            />
          </div>
          <div className='col-sm-4'>
            <div className='form-group'>
              <label style={{ fontSize: '12px' }}>ORDER STATUS</label>
              <select
                className='form-control'
                // disabled={!("new" in product) ? true : false}
                name='status'
                onChange={setrefundOrderStatusValue}
              >
                <option value=''>-----</option>
                {ORDER_STATUSES.map(({ value, label }) => {
                  return (
                    <option
                      key={value}
                      value={value}
                      selected={
                        refundtransactionDetail &&
                        refundtransactionDetail.order.status === value
                      }
                    >
                      {label}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <FieldGroup
              id='shipping_address'
              type='text'
              label='SHIPPING ADDRESS'
              name='shipping_address'
              value={
                refundtransactionDetail
                  ? refundtransactionDetail.order.shipping_address
                  : ''
              }
              readOnly
            />
          </div>
          <div className='col-sm-2'>
            <FieldGroup
              id='zipcode'
              type='text'
              label='ZIP CODE'
              name='zipcode'
              value={
                refundtransactionDetail
                  ? refundtransactionDetail.order.zipcode
                  : ''
              }
              readOnly
            />
          </div>
          <div className='col-sm-2'>
            <FieldGroup
              id='state'
              type='text'
              label='STATE'
              name='state'
              value={
                refundtransactionDetail
                  ? refundtransactionDetail.order.state
                  : ''
              }
              readOnly
            />
          </div>
          <div className='col-sm-2'>
            <FieldGroup
              id='city'
              type='text'
              label='CITY'
              name='city'
              value={
                refundtransactionDetail
                  ? refundtransactionDetail.order.city
                  : ''
              }
              readOnly
            />
          </div>
        </div>
      </div>
      <table className='table'>
        <thead>
          <tr>
            <th></th>
            <th>Item</th>
            <th>Qty Ordered</th>
            <th>Cost</th>
            <th>Taxes</th>
            <th>Shipping</th>
          </tr>
        </thead>
        <tbody>
          {refundtransactionDetail &&
            refundtransactionDetail.items.map(function(item, ind) {
              return (
                <>
                  <tr key={ind}>
                    <td width='1px'></td>

                    <td width='250px'>
                      <input
                        value={
                          item.inventory_id
                            ? findInventory(item.inventory_id)
                            : null
                        }
                        name='qty_ordered'
                        readOnly
                        className='form-control leftPadding'
                        type='text'
                        min='0'
                      />
                    </td>
                    <td
                      className='bigFieldSec'
                      style={{ position: 'relative' }}
                    >
                      <input
                        value={item.qty_ordered}
                        name='qty_ordered'
                        readOnly
                        className='form-control leftPadding'
                        type='number'
                        min='0'
                      />
                    </td>
                    <td
                      className='bigFieldSec'
                      style={{ position: 'relative' }}
                    >
                      <span
                        className='dollarSign'
                        //  style={{ marginTop: '6px' }}
                      >
                        $
                      </span>
                      <input
                        value={item.price}
                        name='price'
                        readOnly
                        className='form-control leftPadding'
                        type='number'
                        min='0'
                        step='any'
                        style={{ paddingLeft: '30px' }}
                      />{' '}
                    </td>
                    <td
                      className='bigFieldSec'
                      style={{ position: 'relative' }}
                    >
                      <span className='dollarSign'>$</span>
                      <input
                        value={item.taxes}
                        name='taxes'
                        readOnly
                        className='form-control leftPadding'
                        type='number'
                        min='0'
                        step='any'
                        style={{ paddingLeft: '30px' }}
                      />{' '}
                    </td>
                    <td
                      className='bigFieldSec'
                      style={{ position: 'relative' }}
                    >
                      <span className='dollarSign'>$</span>
                      <input
                        value={item.shipping}
                        name='shipping'
                        readOnly
                        className='form-control leftPadding'
                        type='number'
                        min='0'
                        step='any'
                        style={{ paddingLeft: '30px' }}
                      />{' '}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan='6'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th></th>
                            <th>SHIPMENT</th>
                            <th>Item</th>
                            <th>Expected QTY</th>
                            <th>courier</th>
                            <th>tracking No.</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.shipments &&
                            item.shipments.map((shipment, idx) => {
                              return (
                                <tr style={{ justifyContent: 'space-around' }}>
                                  <td className='smallFieldSec'></td>
                                  <td className='smallFieldSec'>
                                    <input
                                      value={idx + 1}
                                      name='id'
                                      className='form-control leftPadding'
                                      min='1'
                                      style={{ paddingLeft: '25px' }}
                                      readOnly
                                    />{' '}
                                  </td>
                                  <td
                                    className='mediumFieldSec'
                                    style={{ marginLeft: 10 }}
                                  >
                                    <input
                                      value={
                                        item.inventory_id
                                          ? findInventory(item.inventory_id)
                                          : null
                                      }
                                      name={'item'}
                                      className='form-control leftPadding'
                                      style={{ paddingLeft: '15px' }}
                                      readOnly
                                    />
                                  </td>
                                  <td className='mediumFieldSec'>
                                    <input
                                      value={shipment.qty_expected}
                                      name='qty_expected'
                                      readOnly
                                      className='form-control leftPadding'
                                      type='number'
                                      min='0'
                                      step='any'
                                      style={{ paddingLeft: '15px' }}
                                    />{' '}
                                  </td>
                                  <td className='mediumFieldSec'>
                                    <select
                                      className='form-control'
                                      name='courier'
                                      disabled={true}
                                      readOnly
                                    >
                                      <option value=''>-----</option>
                                      {CARRIERS.map(({ value, label }) => {
                                        return (
                                          <option
                                            key={value}
                                            value={value}
                                            selected={
                                              shipment.courier === value
                                            }
                                          >
                                            {label}
                                          </option>
                                        )
                                      })}
                                    </select>{' '}
                                  </td>
                                  <td className='mediumFieldSec'>
                                    <input
                                      value={shipment.tracking_number}
                                      name='tracking_number'
                                      readOnly
                                      className='form-control leftPadding'
                                      type='text'
                                      min='0'
                                      step='any'
                                      style={{ paddingLeft: '15px' }}
                                    />{' '}
                                  </td>
                                  <td className='smallFieldSec'></td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </>
              )
            })}
        </tbody>

        <tfoot>
          <tr>
            <td colSpan='14'>
              <span
                className='pull-right'
                style={{ position: 'sticky', right: '5px' }}
              >
                <input
                  type='button'
                  value={'Link'}
                  onClick={() => linkRefundTransaction()}
                  style={{ marginRight: 10 }}
                  disabled={alreadyLinked}
                  className='btn btn-md btn-primary ml-sm'
                />

                <input
                  type='submit'
                  value={'Save'}
                  className='btn btn-md btn-info'
                />
                <input
                  type='button'
                  value={'Cancel'}
                  onClick={() => {
                    setExpended1({ 0: false })
                    setRefundTransactionDetail(null)
                  }}
                  className='btn btn-md btn-danger ml-sm'
                />
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </form>
  )
}

export default ShowRefundItems
