import { giftCardServices } from '../../services/giftcard';


export const getGiftCard = (data) => {
    return dispatch => {
        giftCardServices.getGiftCard(data)
            .then(
                response => {
                    dispatch({ type:'STORE_GIFT_CARD',payload: response});
    
                },
            );
    }
}