import Dashboard from "../layouts/Dashboard/Dashboard.jsx";
import AdminLogin from "../layouts/Dashboard/AdminAuth.jsx";
import UserVerify from "../components/Admin/userVerify.jsx";
//import VendorList from "components/Admin/VendorList";

var indexRoutes = [
  {
    path: "/userVerify",
    name: "UserVerify",
    component: UserVerify,
    exactPro: true,
  },
  {
    path: "/Admin-login",
    name: "AdminLogin",
    component: AdminLogin,
    exactPro: false,
  },
  { path: "/", name: "Home", component: Dashboard, exactPro: false },
 
];

export default indexRoutes;
