import React from "react";
import { FormGroup, ControlLabel, FormControl, Row } from "react-bootstrap";

export function FieldGroup({ label, ...props }) {
  return (
    <FormGroup>
      {
        label &&
        <ControlLabel>{label}</ControlLabel>
      }
      <FormControl {...props}  />
      
      
    </FormGroup>
  );
}

export const FormInputs = props => {

  var row = [];
  for (var i = 0; i < props.ncols.length; i++) {
    row.push(
      <div key={i} className={props.ncols[i]}>
        <FieldGroup {...props.proprieties[i]} />
      </div>
    );

    return <Row>{row}</Row>;
  }
}

export default FormInputs;
