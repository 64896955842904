import { s3StaticPath } from 'helper/helperFunctions'
// import Dashboard from "components/Admin/Dashboard";
// import Plaid from "components/Admin/Plaid/Plaid";
import Dashboard from '../components/Admin/Plaid/tm-dashboard'
import VendorList from 'components/Admin/VendorList'
import TransactionStatusList from 'components/Admin/TransactionStatus/TransactionStatusList'
import Inventory from 'components/Admin/Inventory'
import AbnormalTransactions from '../components/Admin/AbnormalTransactions/AbnormalTransactions'
import SettingsPage from '../components/Admin/SettingsPage/SettingsPage'
import Logs from '../components/Admin/Logs/Log'
import InventoryReport from '../components/Admin/Inventory/Reports/InventoryReport'
import ErrorPage from '../components/Admin/ErrorPage/ErrorPage'
import UPS from "../components/Admin/UPS/ups"
import GiftCard from '../components/Admin/GiftCard/giftCard'
import BankAndcards from '../components/Admin/TransactionStatus/bank&cards'



// import Transactions from "components/Admin/Plaid/Transactions";
var dashboardRoutes = [
  {
    path: '/dashboard',
    name: ' Dashboard',
    icon: 'fa fa-dashboard',
    component: Dashboard,
    iconImagePath: s3StaticPath('img/dashboard.svg'),
  },
  {
    path: '/banks&cards',
    name: ' Banks & Cards',
    icon: 'fa fa-bank',
    component: BankAndcards,
    iconImagePath: s3StaticPath('img/dashboard.svg'),
  },
  {
    path: '/vendor-list',
    name: ' Vendor List',
    icon: 'fa fa-list',
    component: VendorList,
    iconImagePath: s3StaticPath('img/dashboard.svg'),
  },
  {
    path: '/gift-card',
    name: 'Gift Card Vaults',
    icon: 'fa fa-credit-card',
    component: GiftCard,
    iconImagePath: s3StaticPath('img/dashboard.svg'),
  },
  {
    path: '/transaction-state',
    name: ' Transactions state',
    icon: 'fa fa-list',
    component: TransactionStatusList,
    iconImagePath: s3StaticPath('img/dashboard.svg'),
  },
  {
    path: '/abnormal-transactions',
    name: ' Abnormal Transactions',
    icon: 'fa fa-list',
    component: AbnormalTransactions,
    iconImagePath: s3StaticPath('img/dashboard.svg'),
  },
  {
    path: '/product-page',
    name: ' Product Page',
    icon: 'fa fa-product-hunt',
    component: Inventory,
    iconImagePath: s3StaticPath('img/dashboard.svg'),
  },
  {
    path: '/shipment-tracking-page',
    name: ' Shipment Tracking Page',
    icon: 'fa fa-list',
    component: UPS,
    iconImagePath: s3StaticPath('img/dashboard.svg'),
  },

  {
    path: '/setting-page',
    name: ' Setting Page',
    icon: 'fa fa-cog',
    component: SettingsPage,
    iconImagePath: s3StaticPath('img/dashboard.svg'),
  },

  {
    path: '/logs',
    name: 'Activity Logs',
    icon: 'fa fa-history',
    component: Logs,
    iconImagePath: s3StaticPath('img/dashboard.svg'),
  },

  {
    path: '/inevntory-report',
    name: 'Inventory Report',
    icon: 'fa fa-history',
    component: InventoryReport,
    iconImagePath: s3StaticPath('img/dashboard.svg'),
  },

  {
    path: '/error-page',
    name: ' Error Page',
    icon: 'fa fa-exclamation-circle',
    component: ErrorPage,
    iconImagePath: s3StaticPath('img/dashboard.svg'),
  },

  { redirect: true, path: '/', to: '/dashboard', name: 'Dashboard' },
]

export default dashboardRoutes

// {
//     path: "/plaid",
//     name: "plaid",
//     icon: "pe-7s-users",
//     iconImagePath: s3StaticPath('img/user.svg'),
//     component: Plaid,
//     permission: "user-list"
//   },
//   {
//     path: "/Transactions",
//     name: "Transactions",
//     icon: "pe-7s-users",
//     iconImagePath: s3StaticPath('img/user.svg'),
//     component: Transactions,
//     permission: "user-list"
//   },
