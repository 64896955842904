function formatData (chunked) {
  let data = chunked.slice(2);
  // let heading = chunked[1];
  let completData = []
  for (let i = 0; i < data.length; i++) {
    let giftCard = {};
    // let length = data[i].length;
    // let j = 0;
    // while(length > 0){
    //   giftCard[`${heading[j]}`] = data[i][j];
    //   j++;
    //   length--;

    // }
      giftCard["name"] = data[i][0]
      giftCard["card"] = data[i][1]
      giftCard["amount"] = data[i][2]
      giftCard["purchase"] = data[i][3] !== "" ? data[i][3] : 0
      if(giftCard["name"] !== '' && giftCard["card"] !== '' &&  giftCard["amount"] !== '') completData.push(giftCard)
  }
  return completData
}

export { formatData }
