import { SC } from '../helper/ServerCall'

export const plaidServices = {
  getLinkPlaid,
  exchangeToken,
  getBalance,
  getTransaction,
  storeBank,
  getBanks,
  getStoreBank,
  deteleBank,
  deteleBankAccount,
  getBankAccounts,
  getBankAccountsBal,
  addBankAccounts,
  addBankAccountDate,
  transactionDetails,
  sumOfTransactions,
  sumOfUnreceivedTransactions,
  getAbnormalTransactionsOpen,
  getAbnormalTransactionsRefund,
  fetchTransection,
  searchBank,
  allAbnormalTransactions,
  updateTransactionsSchedulerDate,
  getTransactionsSchedulerDate
}

function getLinkPlaid (type) {
  return SC.getCall(`createLink/${type}`)
}

function fetchTransection() {
  return SC.getCall(`fetchTransection`)
}

function exchangeToken (data) {
  return SC.postCall(`exchangeToken`, data)
}

function getBalance (data) {
  return SC.postCall(`getBalance`, data)
}

function getTransaction (data) {
  return SC.postCall(`getTransaction`, data)
}

function storeBank (data) {
  return SC.postCall(`bank`, data)
}

function getBanks (data) {
  return SC.getCall(`bank`)
}

function getStoreBank (data) {
  return SC.getCall(`getStoreBank`)
}

function searchBank (data){
  return SC.postCall('searchBank',data)
}

function deteleBank (id) {
  return SC.deleteCall('bank/' + id)
}

function deteleBankAccount (id) {
  return SC.getCall('account/' + id)
}

function getBankAccounts (bank_id) {
  return SC.getCall(`getAccounts/${bank_id}`)
}

function getBankAccountsBal (bank_id) {
  return SC.getCall(`getBankAccountsBal/${bank_id}`)
}

function addBankAccounts (data) {
  return SC.postCall(`storeAccounts`, data)
}

function addBankAccountDate (data) {
  return SC.postCall(`addAccountDate`, data)
}

function transactionDetails (data) {
  // console.log('data service', data);
  return SC.postCall(`transactionDetails`, data)
}


function sumOfTransactions (data) {
  // console.log('data service', data);
  return SC.postCall(`sumOfTransactions`, data)
}

function sumOfUnreceivedTransactions (data) {
  // console.log('data service', data);
  return SC.postCall(`sumOfUnreceivedTransactions`, data)
}


function getAbnormalTransactionsOpen(data) {
  return SC.postCall(`getAbnormalTransactionsOpen`, data)
}


function getAbnormalTransactionsRefund(data) {
  return SC.postCall(`getAbnormalTransactionsRefund`, data)
}

function allAbnormalTransactions(data) {
  return SC.postCall(`getAbnormalAllTransactions`, data)
}

function updateTransactionsSchedulerDate(data) {
	return SC.postCall(`updateTransactionsSchedulerDate`, data)
}

function getTransactionsSchedulerDate() {
	return SC.getCall('getTransactionsSchedulerDate')
}