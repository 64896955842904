import React from "react";
import {
  Accordion as AccordionComponent,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
// import "react-accessible-accordion/dist/fancy-example.css";
import "./styles.css";
import { Table, Grid, Row, Col } from "react-bootstrap";

const Accordion = ({ data }) => {
  {
    {
		console.log("aasa", data);
    }
  }
  return (
    <>
      {data && data.length > 0 ? (
        data.map((d, i) => (
          <AccordionComponent
            allowZeroExpanded={true}
            allowMultipleExpanded={true}
            key={i}
          >
            <AccordionItem key={i}>
              <AccordionItemHeading className="accordion__heading active">
                <AccordionItemButton>
                  {/* <i
                className="fa fa-industry"
                style={{ marginRight: 10 }}
                aria-hidden="true"
              /> */}
                  Row {i + 1}
                  {/* <span className="badge">Row</span> */}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="accordion__panel active">
                <div>
                  <Table responsive>
                    <tbody>
                      <tr>
                        <td>
                          <strong>Trans ID</strong>
                          <div>
                            {d.transaction_id ? d.transaction_id : "--"}
                          </div>
                        </td>
                        <td>
                          <strong>Amount</strong>
                          <div>{d.amount ? d.amount : "--"}</div>
                        </td>
                        <td>
                          <strong>Card</strong>
                          <div>{d.card_no ? d.card_no : d.account_name ? d.account_name : "--"}</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Date</strong>
                          <div>{d.date ? d.date : "--"}</div>
                        </td>
                        <td>
                          <strong>Merchant Name</strong>
                          <div>{d.name ? d.name : "--"}</div>
                        </td>
                        <td>
                          <strong>Transaction Type</strong>
                          <div>
                            {d.transaction_type.name
                              ? d.transaction_type.name
                              : "--"}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Transaction State</strong>
                          <div>
                            {d.transaction_state.name
                              ? d.transaction_state.name
                              : "--"}
                          </div>
                        </td>
                        <td>
                          <strong>Order Number</strong>
                          <div>
                            {d.order.order_number ? d.order.order_number : "--"}
                          </div>
                        </td>
                        <td>
                          <strong>Warehouse Address</strong>
                          <div>
                            {d.order.shipping_address
                              ? d.order.shipping_address
                              : "--"}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Email Address</strong>
                          <div>{d.email ? d.email : "--"}</div>
                        </td>

                        <td>
                          <strong>Order Status</strong>
                          <div>{d.order.status ? d.order.status : "--"}</div>
                        </td>
                        <td>
                          <strong>Taxes</strong>
                          <div>{d.order.taxes ? d.order.taxes : "--"}</div>
                        </td>
                        <td>
                          <strong>Shipping</strong>
                          <div>
                            {d.order.shippingNo ? d.order.shippingNo : "--"}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>


                  {d.giftCards && d.giftCards.length > 0 ?
                  <Table responsive size='sm'>
                    <thead>
                      <tr>
                        <th className="text-dark">
                          <strong>No.</strong>
                        </th>
                        <th className="text-dark">
                          <strong>Gift Card Nmber</strong>
                        </th>
                        <th className="text-dark">
                          <strong>Used Amont</strong>
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                      {d.giftCards.map((card, i) => (
                        <tr key={i}>
                          <td >{i + 1}</td>
                          <td style={{ width: 270 }}>{card.card_no}</td>
                          <td>{card.useAmount}</td>

                        </tr>
                      ))}
                    </tbody>
                  </Table>
                    : ''
                  }

                  <Table responsive>
                    <thead>
                      <tr>
                        <th className="text-dark">
                          <strong>ITEM NAME</strong>
                        </th>
                        <th className="text-dark">
                          <strong>QTY</strong>
                        </th>
                        <th className="text-dark">
                          <strong>PRICE</strong>
                        </th>
                        {/* <th className='text-dark'>
                          <strong>TAXES</strong>
                        </th>
                        <th className='text-dark'>
                          <strong>SHIPPING</strong>
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {d.items.map((item, i) => (
                        <tr key={i}>
                          <td>{item.name}</td>
                          <td>{item.qty_ordered}</td>
                          <td>{item.price}</td>
                          {/* <td>{item.taxes}</td>
                          <td>{item.shipping}</td> */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className="text-dark">
                          <strong>Courier</strong>
                        </th>
                        <th className="text-dark">
                          <strong>Tracking Number</strong>
                        </th>
                        <th className="text-dark">
                          <strong>Shipment Item Name</strong>
                        </th>
                        <th className="text-dark">
                          <strong>Expected Qty</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {d.shipments.map((shipment, i) =>
                        shipment.items.map((item, si) => (
                          <tr key={i}>
                            <td>{si === 0 ? shipment.courier : ""}</td>
                            <td>{si === 0 ? shipment.tracking_number : ""}</td>
                            <td>{item.name}</td>
                            <td>{item.qty_expected}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </AccordionComponent>
        ))
      ) : (
        <h4 style={{ display: "flex", justifyContent: "center" }}>
          No Vendors Found
        </h4>
      )}
    </>
  );
};

export default Accordion;
