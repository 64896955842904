import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap-modal'
import { Button ,Tooltip, OverlayTrigger} from 'react-bootstrap'
import { transactionServices } from '../../../services/transaction'
import { addresServices } from "../../../services/address";
import { ORDER_STATUSES } from '../../../constants'
import { alertActions } from '../../../redux/actions/alert-actions'
import { useDispatch } from 'react-redux'
import styles from './searchModalStyles'
// import { ITEM_STATUS } from '../../../constants'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import ImageUploaderModal from '../TransactionStatus/ImageUploaderModal'
import ImageViewerModal from './imageViewerModal'
import AlertModel from './components/AlertModel'


const SearchModal = ({ open, setOpen, searchData }) => {
  //console.log('-----------searchData', searchData)

  const dispatch = useDispatch()
  const [showUploader, setShowUploader] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [trans_Id, setTrans_Id] = useState();
  const [showImageViewer, setShowImageViewer] = useState(false)
  const [inventories, setInventories] = useState([])
  const [imageUploaderData, setImageUploaderData] = useState({
    DataId: '',
    ItemId: '',
    ShipmentId: '',
  })

  const [stateData, setStateData] = useState([])
  const [showStore,setShowStore]=useState(false)
  const [showMark,setShowMark]=useState(false)
  const [addresses, setAddresses] = useState([]);


  const getInventoriesAsyncFunction = (data = null) => {
    transactionServices.getInventoriesAsync(data).then((response) => {
      setInventories(response)
    })
  }
  const getAllAddressData = (data = null) => {
    addresServices.getAllAddress(data).then((response) => {
      if (response.data) {
        setAddresses(response.data.pagination);
      }
    });
  };


  const showButtons =()=>{
      let haveItems=0
      let haveShipments=0

      searchData.map((data)=>{
        if(data.items.length>0){
            haveItems+=1
        }
        if(data.shipments.length>0){
          haveShipments+=1
        }
      })

      if (haveShipments>0 && haveItems>0 )
      {
        if (haveShipments === searchData.length && haveItems===searchData.length) {
          setShowStore(true)
        }
      }

      if(haveItems>0 && haveShipments===0){
        if (haveItems===searchData.length) {
          if(searchData[0].items[0].inventory_id!==null){
            setShowMark(true)
          }
        }
      }

  }


  useEffect(() => {
    showButtons()
    setStateData(searchData)
    getInventoriesAsyncFunction()
    getAllAddressData()
  }, [])





  const openViewer = () => {
    setShowImageViewer(!showImageViewer)
  }
  const findInventory = (id) => {
    if (inventories.length > 0) return inventories.find((e) => e.id === id).name
  }

  const findAddress = (id) => {
    if( addresses.length > 0) return addresses.find((e) => e.id === id).address
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    let error = 0

    //this is to restrict the user is ther is no change in receiving
    let count=0
    let number_of_shipments=0
	  let total_transactions = stateData ? stateData.length : 0;
	  let transactions_with_no_change = [];

    stateData.map((transaction, index)=>{
      if(transaction.order.status === 'Received in full'){
        transactions_with_no_change.push(index);
      }

    //   transaction.shipments.map((shipment)=>{
    //     number_of_shipments++
    //     if(shipment.status!=="Shipped" &&   shipment.status !== "Shipped partially" &&
    //      shipment.status !=="Received partial" && shipment.status !=="Received damaged" && shipment.status !=="Received missing" ){
    //       count++
    //     }
    //   })

    })

    /**
     * If all transactions orders status is received in full
     * Need to show message that there is no change made
     */
    if(transactions_with_no_change.length === total_transactions) {
      dispatch(
        alertActions.error([
          [
          'There is no change in the receiving',
          ],
        ])
      )
      return false;
	  }
    // if (count === number_of_shipments){
    //   dispatch(
    //     alertActions.error([
    //       [
    //         'There is no change in the receiving',
    //       ],
    //     ])
    //   )
    //   return false
    // }



    let dataCopy = stateData
    for (let i = 0; i < stateData.length; i++) {
      for (let j = 0; j < stateData[i].shipments.length; j++) {
        for (let k = 0; k < stateData[i].shipments[j].items.length; k++) {
          if (stateData[i].shipments[j].items[k].qty_received === null) {
            dataCopy[i].shipments[j].items[k].qty_received =
              stateData[i].shipments[j].items[k].qty_expected
          } else {
            if (
              stateData[i].shipments[j].items[k].qty_received <
              stateData[i].shipments[j].items[k].qty_expected
            ) {
              if (stateData[i].shipments[j].items[k].qty_damaged !== null) {
                if (stateData[i].shipments[j].items[k].qty_damaged===0){
                  stateData[i].shipments[j].items[k].qty_damaged=null
                }
                else{
                  if (
                    stateData[i].shipments[j].items[k].qty_expected <
                    stateData[i].shipments[j].items[k].qty_received +
                      stateData[i].shipments[j].items[k].qty_damaged
                  ) {
                    error = error + 1
                  }
                }
              }
            } else {
              if (
                stateData[i].shipments[j].items[k].qty_received >
                stateData[i].shipments[j].items[k].qty_expected
              ) {
                error = error + 1
              }
            }
          }

          if (error > 0) {
            break
          }
        }
      }
    }

    if (error > 0) {
      dispatch(
        alertActions.error([
          [
            'Sum of Qty Received and Damaged should not exceed from the Qty Shipped',
          ],
        ])
      )
      return false
    }

    setStateData(dataCopy)

    transactionServices
      .updateSearchResults({ data: stateData, type: 'store' })
      .then(
        (response) => {
          dispatch(alertActions.success(response.message))
          getInventoriesAsyncFunction()
          setOpen(false)
        },
        (error) => {
          dispatch(alertActions.error(error))
        }
      )
  }

  const onUpdateShipment = (e, idD, ids, idi) => {
    const { name, value, type } = e.target

    const temp = [...stateData]

    temp[idD].shipments[ids].items[idi][name] =
      type === 'number' ? Number(value) : value

    setStateData(temp)
  }

  const handleConfirmApi = async () =>{
    console.log("idss--->",trans_Id)
    if(trans_Id){
      let res = await transactionServices.markAsReceived(trans_Id);
      if(res.error){
       dispatch(
         alertActions.error([
           [
             'This transaction is already received',
           ],
         ])
       )
      }else{
       dispatch(
         alertActions.success([
           [
             'The transaction is received',
           ],
         ])
       )
      }
      setTimeout(()=>
      setOpen(false)
      ,1000);

    }



  }

  const handleMarkAsReceived = async (id) => {
    let obj= {
      ids:[id]
    }


    setTrans_Id(obj);
    setShowAlert(true);
    }

  const handleKeyDown = (e) => {

    if (e.key === 'Enter') {
      if(stateData[0].items.length > 0 && stateData[0].shipments.length > 0){
        handleSubmit(e)
      }
      else if(stateData[0].items.length > 0 && stateData[0].shipments.length === 0 && !showAlert){
        handleMarkAsReceived(stateData[0].id)

      }else if(stateData[0].items.length > 0 && stateData[0].shipments.length === 0 && showAlert){
        setShowAlert(false);
        handleConfirmApi();
      }


    }
  }

  return (
    <>
      <div onKeyDown={handleKeyDown}>
        <Modal
          backdrop={'static'}
          show={open}
          onHide={() => setOpen(false)}
          aria-labelledby='ModalHeader'
          dialogClassName='fullscreenmodal'
        >
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title
                id='ModalHeader'
                className='headerTitle'
                style={{ width: 800 ,marginLeft:'360px',fontSize:'30px'}}
              >
              Order Receiving
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {stateData.map((data, idData) => {
                return (
                  <>
                    <div className='row' key={idData}>
                      <>
                        <div style={styles.formContainer}>
                          <div>
                            <p style={{ ...styles.title, fontSize: '20px' }}>
                              Order Details:
                            </p>
                          </div>
                          <div style={styles.formInputsContainer}>
                            <div style={styles.inputContainer}>
                              <label>Order Number</label>
                              <OverlayTrigger
                                placement='bottom'
                                trigger={['hover']}
                                overlay={<Tooltip id='orderNo'>{data.order.order_number}</Tooltip>}
                              >
                              <input
                                type='text'
                                step='.01'
                                min='0'
                                readOnly
                                style={{ ...styles.readonlyInput, textOverflow: 'ellipsis' }}
                                name='order_number'
                                value={data.order.order_number}
                              />
                              </OverlayTrigger>
                            </div>

                            <div style={styles.inputContainer}>
                              <label>Merchant Name</label>
                              <OverlayTrigger
                                placement='bottom'
                                trigger={['hover']}
                                overlay={
                                  <Tooltip id='MerchantName'>
                                   {data.merchant_name  ? data.merchant_name : data.name ? data.name : null}
                                 </Tooltip>
                                }
                              >
                              <input
                                style={{
                                  ...styles.readonlyInput,
                                  width: '200px', textOverflow: 'ellipsis'
                                }}
                                readOnly
                                //disabled
                                name='merchant_name'
                                value={
                                  data.merchant_name
                                    ? data.merchant_name
                                    : data.name
                                    ? data.name
                                    : null
                                }
                              />
                              </OverlayTrigger>
                            </div>

                            <div style={styles.inputContainer}>
                              <label>Amount</label>
                              <span
                                style={{
                                  ...styles.dollar,
                                  fontSize: 'large',
                                  fontWeight: '900',
                                  zIndex: 1,
                                }}
                              >
                                $
                              </span>
                              <input
                                style={{
                                  ...styles.readonlyInput,
                                  paddingLeft: 14,
                                  //position: 'absolute',
                                  top: 82,
                                }}
                                readOnly
                                //disabled
                                name='amount'
                                value={data.amount ? data.amount : null}
                              />
                            </div>

                            <div style={styles.inputContainer}>
                              <label>Order Status</label>
                              <select
                                style={styles.select}
                                name='status'
                                onChange={(e) => {
                                  const temp = [...stateData]
                                  temp[idData].order.status = e.target.value
                                  setStateData(temp)
                                }}
                              >
                                <option value=''>Select</option>
                                {ORDER_STATUSES.map(({ value, label }) => {
                                  return (
                                    <option
                                      key={value}
                                      value={value}
                                      selected={data.order.status === value}
                                    >
                                      {label}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          </div>

                          <div
                            style={{
                              ...styles.formInputsContainer,
                              marginTop: '20px',
                              justifyContent: 'left',
                            }}
                          >
                            <div style={styles.inputContainer}>
                              <label>Transaction Type</label>
                              <input
                                type='text'
                                step='.01'
                                min='0'
                                readOnly
                                style={styles.readonlyInput}
                                name='transaction_type'
                                value={data.transaction_type.name}
                              />
                            </div>

                            <div
                              style={{
                                ...styles.inputContainer,
                                marginLeft: '108px',
                              }}
                            >
                              <label>Warehouse Address</label>
                              <input
                                style={{
                                  ...styles.readonlyInput,
                                  width: '682px',
                                  fontSize: '14px',
                                }}
                                readOnly
                                //disabled
                                name='shipping_address'
                                value={data.order.address_id ? findAddress( data.order.address_id ) : ''}
                              />
                            </div>
                          </div>

                          <div style={{ marginTop: 20 }}>
                            <p style={{ ...styles.title, marginLeft: '18px' }}>
                              Receiving Items:
                            </p>
                          </div>
                          {data.items.length > 0 &&
                            data.items[0].inventory_id!==null&&
                            data.shipments.length === 0 && (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '50px',
                                    fontWeight: 900,
                                    fontSize: '18px',
                                    color: 'red',
                                  }}
                                >
                                  There is no shipment created for this
                                  transaction
                                </div>
                              </>
                            )}

                          {data.items.length === 1&&
                          data.items[0].inventory_id===null
                           && (
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  padding: '50px',
                                  fontWeight: 900,
                                  fontSize: '18px',
                                  color: 'red',
                                }}
                              >
                                There is no items in this order so you are not
                                able to store this order
                              </div>
                            </>
                          )}

                          {data.shipments.map((ship, sid) => {
                            return (
                              <>
                                <div key={sid}>
                                  {ship.items.map((item, idItems) => {
                                    return (
                                      <>
                                        <div key={idItems}>
                                          <div
                                            style={{
                                              ...styles.formInputsContainer,
                                              justifyContent: 'left',
                                              marginTop: idItems != 0 ? 20 : 0,
                                            }}
                                          >
                                            {idItems === 0 && (
                                              <div
                                                style={{
                                                  ...styles.inputContainer,
                                                  marginLeft: 20,
                                                }}
                                              >
                                                <label>Shipment</label>
                                                <input
                                                  type='number'
                                                  min='0'
                                                  readOnly
                                                  style={{
                                                    ...styles.readonlyInput,
                                                    width: '85px',
                                                  }}
                                                  name='item'
                                                  value={sid + 1}
                                                />
                                              </div>
                                            )}
                                            {idItems > 0 && (
                                              <div
                                                style={{
                                                  ...styles.inputContainer,
                                                  marginLeft: 20,
                                                }}
                                              >
                                                <label></label>
                                                <input
                                                  type='number'
                                                  min='0'
                                                  readOnly
                                                  style={{
                                                    ...styles.readonlyInput,
                                                    width: '85px',
                                                  }}
                                                  name='item'
                                                  value={null}
                                                />
                                              </div>
                                            )}

                                            {idItems === 0 && (
                                              <div
                                                style={{
                                                  ...styles.inputContainer,
                                                }}
                                              >
                                                <label>Tracking No</label>
                                                <OverlayTrigger
                                                  placement='bottom'
                                                  trigger={['hover']}
                                                  overlay={
                                                    <Tooltip id='TrackingNo'>{ship.tracking_number} </Tooltip>
                                                  }
                                                >
                                                <input
                                                  type='text'
                                                  min='0'
                                                  readOnly
                                                  style={{
                                                    ...styles.readonlyInput,
                                                    width: '175px',
                                                    fontSize: 15,
                                                    paddingTop: '3px',
                                                    textOverflow: 'ellipsis'
                                                  }}
                                                  name='item'
                                                  value={ship.tracking_number}
                                                />

                                                </OverlayTrigger>
                                              </div>
                                            )}
                                            {idItems > 0 && (
                                              <div
                                                style={{
                                                  ...styles.inputContainer,
                                                }}
                                              >
                                                <label></label>
                                                <input
                                                  type='number'
                                                  min='0'
                                                  readOnly
                                                  style={{
                                                    ...styles.readonlyInput,
                                                    width: '175px',
                                                    fontSize: 15,
                                                    paddingTop: '3px',
                                                  }}
                                                  name='item'
                                                  value={null}
                                                />
                                              </div>
                                            )}

                                            <div
                                              style={{
                                                ...styles.inputContainer,
                                                width: 175,
                                                marginLeft: 20,
                                              }}
                                            >
                                              <label>Item</label>
                                              <OverlayTrigger
                                                placement='bottom'
                                                trigger={['hover']}
                                                overlay={
                                                  <Tooltip id='TrackingNo'>{item.inventory_id ?
                                                     findInventory(item.inventory_id) : ''}
                                                  </Tooltip>
                                                }
                                              >

                                                <input
                                                  type='text'
                                                  step='.01'
                                                  min='0'
                                                  readOnly
                                                  style={{
                                                    ...styles.readonlyInput,
                                                    width: '175px',
                                                    fontSize: 15,
                                                    paddingTop: '3px',
                                                    textOverflow: 'ellipsis'
                                                  }}
                                                  value={
                                                    item.inventory_id
                                                      ? findInventory(
                                                        item.inventory_id
                                                      )
                                                      : null
                                                  }
                                                />
                                              </OverlayTrigger>

                                            </div>

                                            <div
                                              style={{
                                                ...styles.inputContainer,
                                                marginLeft: 20,
                                              }}
                                            >
                                              <label>Qty Shipped</label>
                                              <input
                                                type='text'
                                                step='.01'
                                                min='0'
                                                readOnly
                                                style={{
                                                  ...styles.readonlyInput,
                                                  width: '110px',
                                                }}
                                                name='qty_expected'
                                                value={item.qty_expected + 'X'}
                                              />
                                            </div>
                                            <div
                                              style={{
                                                ...styles.inputContainer,
                                              }}
                                            >
                                              <label>Qty Received</label>
                                              <input
                                                type='number'
                                                style={{
                                                  ...styles.smallInput,
                                                  paddingLeft: 5,
                                                  paddingBottom: 0,
                                                  border: 'none',
                                                  borderBottom:
                                                    '1px solid #ccc',
                                                }}
                                                min={0}
                                                disabled={
                                                  // ship.status !== 'Shipped'
                                                  // (item.status ==="Received in full")
                                                  (item.status ==='Received in full' || item.status ==='received in full'
                                                  || item.status ==="Received missing"|| item.status ==="Received damaged")


                                                }
                                                name='qty_received'
                                                value={
                                                  item.qty_received === null
                                                    ? item.qty_expected
                                                    : item.qty_received
                                                }
                                                onChange={(e) =>
                                                  onUpdateShipment(
                                                    e,
                                                    idData,
                                                    sid,
                                                    idItems
                                                  )
                                                }
                                              />
                                            </div>

                                            {item.qty_received !== null &&
                                              item.qty_received <
                                                item.qty_expected && (
                                                <>
                                                  <div
                                                    style={{
                                                      ...styles.inputContainer,
                                                      marginLeft: 50,
                                                    }}
                                                  >
                                                    <label>Damaged</label>
                                                    <input
                                                      type='number'
                                                      min='0'
                                                      style={{
                                                        ...styles.smallInput,
                                                        paddingLeft: 5,
                                                        border: 'none',
                                                        paddingBottom: 0,
                                                        borderBottom:
                                                          '1px solid #ccc',
                                                      }}
                                                      disabled={
                                                      // ship.status !=='Shipped'
                                                      // (item.status ==="Received in full")
                                                      // (item.status ==='Received in full' || item.status ==='received in full')
                                                      (item.status ==='Received in full' || item.status ==='received in full'
                                                      || item.status ==="Received missing"|| item.status ==="Received damaged")


                                                      }
                                                      name='qty_damaged'
                                                      value={item.qty_damaged}
                                                      onChange={(e) =>
                                                        onUpdateShipment(
                                                          e,
                                                          idData,
                                                          sid,
                                                          idItems
                                                        )
                                                      }
                                                    />
                                                  </div>

                                                  {item.images.length !== 0 ? (
                                                    <div
                                                      style={{
                                                        //marginTop: '22px',
                                                        marginLeft: '50px',
                                                      }}
                                                    >
                                                      <img
                                                        style={{
                                                          height: '50px',
                                                          width: '70px',
                                                          cursor: 'pointer',
                                                        }}
                                                        title={
                                                          item.images[0].note
                                                        }
                                                        src={
                                                          item.images[0].source
                                                        }
                                                        onClick={() => {
                                                          openViewer()
                                                          setImageUploaderData({
                                                            DataId: idData,
                                                            ShipmentId: sid,
                                                            ItemId: idItems,
                                                          })
                                                        }}
                                                      ></img>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      style={{
                                                        ...styles.inputContainer,
                                                        marginLeft: 49,
                                                      }}
                                                    >
                                                      <label>
                                                        Upload Image
                                                      </label>
                                                      <div>
                                                        <button
                                                          type='button'
                                                          disabled={
                                                            ship.status !==
                                                            'Shipped'
                                                          }
                                                          onClick={() => {
                                                            setShowUploader(
                                                              !showUploader
                                                            )
                                                            setImageUploaderData(
                                                              {
                                                                DataId: idData,
                                                                ShipmentId: sid,
                                                                ItemId: idItems,
                                                              }
                                                            )
                                                          }}
                                                          style={{
                                                            height: '28px',
                                                            width: '44px',
                                                            marginLeft: '15px',
                                                          }}
                                                        >
                                                          <i className='fa fa-upload'></i>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              )}
                                          </div>
                                        </div>
                                      </>
                                    )
                                  })}
                                </div>
                                {sid + 1 > 0 &&
                                  sid + 1 <
                                    stateData[idData].shipments.length && (
                                    <hr />
                                  )}
                              </>
                            )
                          })}
                        </div>
                        <>
                          {idData + 1 !== stateData.length && (
                            <hr style={{ borderTop: '2px solid #c5c3c3' }} />
                          )}
                        </>
                      </>
                    </div>
                  </>
                )
              })}
            </Modal.Body>
            <Modal.Footer>
              {showStore &&
              <Button
              type='submit'
              bsStyle='primary'
              style={styles.updateBtn}
              >
                Save
              </Button>
              }
              {showMark&&
              <Button
              //type='submit'
              bsStyle='primary'
              style={styles.updateBtn}
              onClick={() => handleMarkAsReceived(stateData[0].id)}

              >
                Mark As Received
              </Button>
              }
              <Button
                bsStyle='danger'
                onClick={() => setOpen(false)}
                style={styles.cancelBtn}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>

      {/* This modal is used to open image uploader */}

      {showUploader && (
        <ImageUploaderModal
          open={showUploader}
          setOpen={() => {
            setShowUploader(false)
          }}
          imageUploaderData={imageUploaderData}
          setImageUploaderData={() => {
            setImageUploaderData({ DataId: '', ItemId: '', ShipmentId: '' })
          }}
          stateData={stateData}
          setStateData={setStateData}
        />
      )}

       {/* alert Model */}

       <AlertModel
        show= {showAlert}
        onHide={()=>setShowAlert(false)}
        onKeyDown={handleKeyDown}
        onConfirm = {handleConfirmApi}
        heading="Mark As Received"
        alert= "Are you sure to store this transaction without any shipment"
       />


      {/* This modal is used to open image slider */}

      {showImageViewer && (
        <ImageViewerModal
          open={showImageViewer}
          setOpen={() => {
            setShowImageViewer(false)
          }}
          imageUploaderData={imageUploaderData}
          setImageUploaderData={() => {
            setImageUploaderData({ DataId: '', ItemId: '', ShipmentId: '' })
          }}
          stateData={stateData}
          setStateData={setStateData}
        />
      )}
    </>
  )
}

export default SearchModal

const asyncSelect = {
  control: (styles) => ({
    ...styles,
    minHeight: '22px !important',
    height: 33,
  }),
  option: (styles) => ({
    ...styles,
  }),
}