import { vendorConstants } from "../constant/vendor-constant";

export const getVendorReducer = (state = {}, action) => {
  switch (action.type) {
    case vendorConstants.VENDOR_LIST_REQUEST:
      return {
        loading: true,
      };
    case vendorConstants.VENDOR_LIST_SUCCESS:
      return {
        loading: false,
        vendor: action.payload,
      };
    case vendorConstants.VENDOR_LIST_FAIL:
      return {
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
