import React from 'react'
export const SheetJSFT = [
  'xlsx',
  'xlsb',
  'xlsm',
  'xls',
  'xml',
  'csv',
  'txt',
  'ods',
  'fods',
  'uos',
  'sylk',
  'dif',
  'dbf',
  'prn',
  'qpw',
  '123',
  'wb*',
  'wq*',
  'html',
  'htm'
]
  .map(x => `.${x}`)
  .join(',')

function ImportManual ({ file,btnName }) {
   
  const handleChange = e => {
    var input = document.getElementById('file')
    input.onclick = function () {
      this.value = null
    }
    const files = e.target.files
    if (files && files[0]) file(files[0])
  }

  return (
    <div>
      {/* <form className='form-inline'> */}
        <div >
         <span style={{color:'black'}}> {btnName} </span>
          {/* <br /> */}
          <input
            type='file'
            className='form-control'
            id='file2'
            accept={SheetJSFT}
            onChange={handleChange}
            style={{ display: 'none' }}
            onClick={(event) => {
              
              event.target.value = null
            }}
          />
        </div>
      {/* </form> */}
    </div>
  )
}

export default ImportManual
