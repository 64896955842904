import React, { useState, useEffect } from "react";
import { Row, Col, Grid, ListGroup, ListGroupItem , DropdownButton, MenuItem } from "react-bootstrap";
import { ErrorServices } from "../../../services/errorLog";
import AlertModel from "../././TransactionStatus/components/AlertModel";
import { useDispatch } from "react-redux";
import { alertActions } from "redux/actions/alert-actions";
import moment from "moment";
import styles from "../TransactionStatus/searchModalStyles";

const ErrorPage = () => {
  const [errorData, setErrorDate] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const dispatch = useDispatch();

  const getErrors = async () => {
    const res = await ErrorServices.getErrors();
    if (res.data) {
      setErrorDate(res.data);
    } else {
      setErrorDate([]);
    }
  };
  useEffect(() => {
    getErrors();
  }, []);

  const handleClearLog = async (id) => {
    const res = await ErrorServices.deleteErrors(id)
    if (res.status === 'success') {
      getErrors();
    } else {
      setErrorDate([]);
    }


  };

  const handleClearAllErrors = async () => {
    const res = await ErrorServices.deleteAllError();
    if (res && res.status === 'success') {
      dispatch(alertActions.success(res.message));
      getErrors()
    }
    else {
      dispatch(alertActions.error(res.message));
    }
  }

  return (
    <>
      <div>
        <Grid fluid>
          <Row>
            <Col>
              <div>
                <Grid fluid>
                  <div className="secHeading" style={{ marginTop: 5 }}>
                    <i
                      className="fa fa-exclamation-circle"
                      aria-hidden="true"
                      style={{ marginRight: 10, marginTop: 10 }}
                    ></i>
                    {"  "}
                    Error Logs
                  </div>
                </Grid>
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <ListGroup as="ol" numbered='true'>
                    <Row style={{display: 'flex' , paddingBottom: 10}}>
                      <Col sm={9} style={{ marginLeft: 50}}> <span style={styles.errorLogHeaders}> Description </span></Col>
                      <Col sm={2} style={{ marginRight: 50}}> <span style={styles.errorLogHeaders}> Time </span> </Col>
											{errorData && errorData.length > 0 ?
												<Col sm={1} style={{ marginRight: 20 }}>
													<DropdownButton
														title={'Action'}
														className='errorLogDropDown'
													>
														<MenuItem eventKey="1"
															onClick={() => setShowErrorAlert(true)}
														>Clear All</MenuItem>
													</DropdownButton>
												</Col> :
												<Col sm={1} style={{ marginRight: 20 }}> <span style={styles.errorLogHeaders}> Action </span> </Col>
											}
                    </Row>
                    {errorData && errorData.length > 0 ?
                      errorData.map((el) => (
                        <ListGroupItem
                          as="li"
                          key={el.id}
                          className="d-flex justify-content-between align-items-start mb-2"
                        >
                          <Row>
                            <Col sm={8}>
                              <div className="ms-2 me-auto">{el.description}</div>

                            </Col>
                            <Col sm={3}>
                              <span>
                                {moment(el.created_at).format("MMMM Do YYYY, h:mm:ss a")}
                              </span>
                            </Col>
                            <Col sm={1}>
                              <button
                                type="button"
                                value="remove"
                                className="ml-sm  btn btn-sm btn-danger"
                                onClick={() => handleClearLog(el.id)}
                              >
                                <i className="glyphicon glyphicon-remove"></i>&nbsp;
                              </button>

                            </Col>
                          </Row>
                        </ListGroupItem>
                      )) : <ListGroupItem
                        as="li"
                        className="d-flex justify-content-between align-items-start mb-2"
                      >
                        <div className="ms-2 me-auto" style={{ textAlign: "center" }}>No Errors logs found !</div>
                      </ListGroupItem>}
                  </ListGroup>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>

        <AlertModel
        show={showErrorAlert}
        onHide={() => setShowErrorAlert(false)}
        onConfirm={handleClearAllErrors}
        heading="Delete All Errors"
        alert="Are you sure to delete all errors"
      />
      </div>
    </>
  );
};

export default ErrorPage;
