export const adminConstants = {
	//Auth
	LOGIN_ADMIN_REQUEST: 'LOGIN_ADMIN_REQUEST',
	LOGIN_ADMIN_SUCCESS: 'LOGIN_ADMIN_SUCCESS',
	LOGIN_ADMIN_FAILURE: 'LOGIN_ADMIN_FAILURE',

	LOGOUT_ADMIN_REQUEST: 'LOGOUT_ADMIN_REQUEST',
	LOGOUT_ADMIN_SUCCESS: 'LOGOUT_ADMIN_SUCCESS',
	LOGOUT_ADMIN_FAILURE: 'LOGOUT_ADMIN_FAILURE',

	GET_MENU_DASHBOARD_REQUEST: 'GET_MENU_DASHBOARD_REQUEST',
	GET_MENU_DASHBOARD_SUCCESS: 'GET_MENU_DASHBOARD_SUCCESS',
	GET_MENU_DASHBOARD_FAILURE: 'GET_MENU_DASHBOARD_FAILURE',

	GET_MENU_REQUEST: 'GET_MENU_REQUEST',
	GET_MENU_SUCCESS: 'GET_MENU_SUCCESS',
	GET_MENU_FAILURE: 'GET_MENU_FAILURE',

	GET_DASHBOARD_REQUEST: 'GET_DASHBOARD_REQUEST',
	GET_DASHBOARD_SUCCESS: 'GET_DASHBOARD_SUCCESS',
	GET_DASHBOARD_FAILURE: 'GET_DASHBOARD_FAILURE',

	PAGINATION_USERS_REQUEST: 'PAGINATION_USERS_REQUEST',
	PAGINATION_USERS_SUCCESS: 'PAGINATION_USERS_SUCCESS',
	PAGINATION_USERS_FAILURE: 'PAGINATION_USERS_FAILURE',

	STORE_USER_REQUEST: 'STORE_USER_REQUEST',
	STORE_USER_SUCCESS: 'STORE_USER_SUCCESS',
	STORE_USER_FAILURE: 'STORE_USER_FAILURE',

	GET_USER_REQUEST: 'GET_USER_REQUEST',
	GET_USER_SUCCESS: 'GET_USER_SUCCESS',
	GET_USER_FAILURE: 'GET_USER_FAILURE',

	EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
	EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
	EDIT_USER_FAILURE: 'EDIT_USER_FAILURE',

	DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
	DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
	DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

	GET_USER_ROLES_REQUEST: 'GET_USER_ROLES_REQUEST',
	GET_USER_ROLES_SUCCESS: 'GET_USER_ROLES_SUCCESS',
	GET_USER_ROLES_FAILURE: 'GET_USER_ROLES_FAILURE',

	GET_AGENCY_ROLES_REQUEST: 'GET_AGENCY_ROLES_REQUEST',
	GET_AGENCY_ROLES_SUCCESS: 'GET_AGENCY_ROLES_SUCCESS',
	GET_AGENCY_ROLES_FAILURE: 'GET_AGENCY_ROLES_FAILURE',

	GET_ALL_PERMISSION_REQUEST: 'GET_ALL_PERMISSION_REQUEST',
	GET_ALL_PERMISSION_SUCCESS: 'GET_ALL_PERMISSION_SUCCESS',
	GET_ALL_PERMISSION_FAILURE: 'GET_ALL_PERMISSION_FAILURE',

	PAGINATION_ROLES_REQUEST: 'PAGINATION_ROLES_REQUEST',
	PAGINATION_ROLES_SUCCESS: 'PAGINATION_ROLES_SUCCESS',
	PAGINATION_ROLES_FAILURE: 'PAGINATION_ROLES_FAILURE',

	STORE_ROLE_REQUEST: 'STORE_ROLE_REQUEST',
	STORE_ROLE_SUCCESS: 'STORE_ROLE_SUCCESS',
	STORE_ROLE_FAILURE: 'STORE_ROLE_FAILURE',

	GET_ROLE_REQUEST: 'GET_ROLE_REQUEST',
	GET_ROLE_SUCCESS: 'GET_ROLE_SUCCESS',
	GET_ROLE_FAILURE: 'GET_ROLE_FAILURE',

	EDIT_ROLE_REQUEST: 'EDIT_ROLE_REQUEST',
	EDIT_ROLE_SUCCESS: 'EDIT_ROLE_SUCCESS',
	EDIT_ROLE_FAILURE: 'EDIT_ROLE_FAILURE',

	DELETE_ROLE_REQUEST: 'DELETE_ROLE_REQUEST',
	DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
	DELETE_ROLE_FAILURE: 'DELETE_ROLE_FAILURE',

	PAGINATION_PERMISSIONS_REQUEST: 'PAGINATION_PERMISSIONS_REQUEST',
	PAGINATION_PERMISSIONS_SUCCESS: 'PAGINATION_PERMISSIONS_SUCCESS',
	PAGINATION_PERMISSIONS_FAILURE: 'PAGINATION_PERMISSIONS_FAILURE',

	STORE_PERMISSION_REQUEST: 'STORE_PERMISSION_REQUEST',
	STORE_PERMISSION_SUCCESS: 'STORE_PERMISSION_SUCCESS',
	STORE_PERMISSION_FAILURE: 'STORE_PERMISSION_FAILURE',

	GET_PERMISSION_REQUEST: 'GET_PERMISSION_REQUEST',
	GET_PERMISSION_SUCCESS: 'GET_PERMISSION_SUCCESS',
	GET_PERMISSION_FAILURE: 'GET_PERMISSION_FAILURE',

	EDIT_PERMISSION_REQUEST: 'EDIT_PERMISSION_REQUEST',
	EDIT_PERMISSION_SUCCESS: 'EDIT_PERMISSION_SUCCESS',
	EDIT_PERMISSION_FAILURE: 'EDIT_PERMISSION_FAILURE',

	DELETE_PERMISSION_REQUEST: 'DELETE_PERMISSION_REQUEST',
	DELETE_PERMISSION_SUCCESS: 'DELETE_PERMISSION_SUCCESS',
	DELETE_PERMISSION_FAILURE: 'DELETE_PERMISSION_FAILURE',

	GET_ALL_PARENT_LABEL_REQUEST: 'GET_ALL_PARENT_LABEL_REQUEST',
	GET_ALL_PARENT_LABEL_SUCCESS: 'GET_ALL_PARENT_LABEL_SUCCESS',
	GET_ALL_PARENT_LABEL_FAILURE: 'GET_ALL_PARENT_LABEL_FAILURE',

	GET_ALREADY_EXISTING_ORDER_REQUEST: 'GET_ALREADY_EXISTING_ORDER_REQUEST',
	GET_ALREADY_EXISTING_ORDER_SUCCESS: 'GET_ALREADY_EXISTING_ORDER_SUCCESS',
	GET_ALREADY_EXISTING_ORDER_FAILURE: 'GET_ALREADY_EXISTING_ORDER_FAILURE',

	GET_ALL_MODULES: 'GET_ALL_MODULES',
	STORE_MODULE_REQUEST: 'STORE_MODULE_REQUEST',
	STORE_MODULE_SUCCESS: 'STORE_MODULE_SUCCESS',
	STORE_MODULE_FAILURE: 'STORE_MODULE_FAILURE',
	CHANGE_MODULE_PERMISSIONS: 'CHANGE_MODULE_PERMISSIONS',


	SET_GLOBAL_LOADER: 'SET_GLOBAL_LOADER',
	GET_ACCESS_TOKEN_SUCCESS : 'GET_ACCESS_TOKEN_SUCCESS',
};