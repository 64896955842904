import { combineReducers } from "redux";
import { adminReducer } from "./AdminReducer";
import { alert } from "./AlertReducer";
import { userReducer } from "./UserReducer";

import { settingsReducer } from "./SettingsReducer";
import { getVendorReducer } from "./VendorReducer";
import giftCardReducer from "./giftCardReducer";

const rootReducer = combineReducers({
  adminReducer,
  userReducer,
  alert,
  settingsReducer,
  getVendor: getVendorReducer,
  giftCard:giftCardReducer
});

export default rootReducer;
