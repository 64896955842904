import React, { useEffect, useRef } from 'react'
import { Button } from 'react-bootstrap'
import clonedeep from 'lodash.clonedeep'

var dateFormat = require('dateformat')
var moment = require('moment')

export const helperFunctions = {
  getRole,
  focusInput,
  returnLabelWithValue,
  returnLabelsWithValues,
  returnLabelsWithValueOtherThenLabel,
  isEmpty,
  returnOptionWithDisble,
  returnFormats,
  returnExtFromImage,
  returnKeyWithColumnMatch,
  returnColumnValueWithColumnMatch,
  returnDollars,
  dateFormatMy,
  dateTimeMy,
  leaf,
  updateStateVariableDynamic,
  hasChildren,
  TriggerHeader,
  returnKeys,
  intersectionOfObjectsWithNewValue,
  omitKeys,
  sortObjectWithDefineKeys,
  openModal,
  closeModal,
  closeModalAfterApi,
  showDate,
  overRideDuplicateImage,
  removeDuplicateImage,
  returnTimeStamp,
  hasRole,
  optionsHasRole,
  checkLoggedInUserById,
  toggleModal,
  inputChangeHandler,
  isLoggedIn,
  getInitials,
  colorForBardcodeWithDate,
  returnLabelWithValueGroup,
  clean,
  dateChangeHandler,
  updateState,
  contactChangeHandler,
  selectChangeHandler,
  appendCount,
  returnBoleanWithColumnMatch,
  appendValues,
  appendValuesWithMatchKeys,
  returnArrayOfMatchingKeyFromArray,
  returnColumnValueWithColumnMatchSA,
  changeText,
  returnLabelsWithValuesWithValueMAndLabelM,
  selectArrayChangeHandler,
  getFormattedDate,
  BytesToMb,
  createFormData,
  compareDates,
  isset,
  checkObjHasKeys,
  checkNested,
  lengthOfMultiArray,
  flatten,
  matchWithFlatten,
  getRandomInt,
  dateWithDayMy,
  returnDynamicURL,
  getSum,
  dateTimeWithAMPM,
  remove_duplicates,
  isEmptyCheck,
  boldStatment,
  dateContainInObject,
  dateContainInObjectStart,
  columnFilters,
  createSortString,
  convertMomentIntoDateObj,
  s3StaticPath,
  dateFormatMyReturnNone,
  scrollLogin,
  setAccessTokens,
  allCommonProperties
}
export function TriggerHeader (heading, status) {
  return (
    <h4 className='headerTitle'>
      {heading}
      <Button className='arrow_toggle' aria-controls='example-collapse-text'>
        {status ? (
          <img
            src={this.s3StaticPath('img/arrow_down.png')}
            alt='Arrow'
            className='arrow_up'
          />
        ) : (
          <img
            src={this.s3StaticPath('img/arrow_down.png')}
            alt='Arrow'
            className='arrow_down'
          />
        )}
      </Button>
    </h4>
  )
}
export function hasChildren (obj, parent) {
  for (var k in obj) {
    if (typeof obj[k] === 'object' && obj[k] !== null) {
      parent.push(k)
      this.hasChildren(obj[k], parent)
    }
  }
  return parent
}

//Path is the array of dynamic keys to update state
export function updateStateVariableDynamic (stateClone, value, path) {
  const nestedObject = path
    .slice(0, -1)
    .reduce(
      (object, part) => (object === undefined ? undefined : object[part]),
      stateClone
    )

  if (nestedObject !== undefined) {
    /* Obtain last key in path */
    const [pathTail] = path.slice(-1)

    /* Update value of last key on target object to new value */
    nestedObject[pathTail] = value
  }

  return stateClone
}

export function leaf (obj, path) {
  path = path.split('.')
  var res = obj
  for (var i = 0; i < path.length; i++) {
    res = res[path[i]]
  }
  return res
}

export function dateFormatMy (date) {
  return date ? dateFormat(date, 'mm/dd/yy') : '--'
}
export function dateFormatMyReturnNone (date) {
  return date ? dateFormat(date, 'mmm dd yyyy') : 'None'
}

export function dateTimeMy (date) {
  //var time = dateFormat(now, "hammerTime");
  return date ? dateFormat(date, 'mmm dd yyyy - h:MM:ss') : '--'
}

export function dateTimeWithAMPM (date) {
  //var time = dateFormat(now, "hammerTime");
  return date ? dateFormat(date, 'mmm dd yyyy h:MM TT') : '--'
}

export function dateWithDayMy (date) {
  //var time = dateFormat(now, "hammerTime");
  return date ? dateFormat(date, 'ddd') : '--'
}

export function returnDollars (num) {
  return num > 0 ? '$' + num : num
}

export function dateFormatWithDashes (date) {
  return date ? dateFormat(date, 'mm-dd-yyyy') : '--'
}

export function getRole () {
  var userAdmin = JSON.parse(localStorage.getItem('user'))
  if (userAdmin) {
    return userAdmin.user.roles
  }
}

export function focusInput (objectTemp) {
  let properties = Object.keys(objectTemp).reverse()
  // console.log('properties', properties);
  for (var key in properties) {
    if (objectTemp[properties[key]] !== null) {
      // var myEle = $("#" + properties[key]);
      // console.log('myEle', properties[key]);
      // console.log('myEle', myEle);
      // if (myEle) {
      // 	var scrollPos = $(myEle).offset().top;
      // 	$(window).scrollTop(scrollPos);
      // }
      // $("#"+properties[key]).focus();
    }
  }
}

export function returnExtFromImage (imageName = '') {
  var ext = imageName.substr(imageName.lastIndexOf('.') + 1)
  return ext
}

export function returnOptionWithDisble (value, ObjectArray) {
  var newObject = []
  for (var key in ObjectArray) {
    if (ObjectArray[key]['value'] === value) {
      newObject.push({
        value: ObjectArray[key]['value'],
        label: ObjectArray[key]['label'],
        disabled: true
      })
    } else {
      newObject.push({
        value: ObjectArray[key]['value'],
        label: ObjectArray[key]['label']
      })
    }
  }
  return newObject
}

export function returnFormats (ObjectArray, columnName) {
  var newObject = []
  for (var key in ObjectArray) {
    newObject.push(ObjectArray[key][columnName])
  }
  return newObject
}

export function returnLabelWithValue (value, ObjectArray, defaultLabel = null) {
  // console.log('ObjectArray 456', ObjectArray)
  if (value) {
    for (var key in ObjectArray) {
      if (ObjectArray[key]['value'] === value) {
        return {
          value: ObjectArray[key]['value'],
          label: ObjectArray[key]['label']
        }
      }
    }
  } else if (defaultLabel) {
    for (var keyN in ObjectArray) {
      if (ObjectArray[keyN]['label'] === defaultLabel) {
        return {
          value: ObjectArray[keyN]['value'],
          label: ObjectArray[keyN]['label']
        }
      }
    }
  }
  return null
}

export function returnLabelWithValueGroup (value, ObjectArray) {
  if (value) {
    for (var key in ObjectArray) {
      for (var k in ObjectArray[key]['options']) {
        if (ObjectArray[key]['options'][k]['value'] === value) {
          return {
            value: ObjectArray[key]['options'][k]['value'],
            label: ObjectArray[key]['options'][k]['label']
          }
        }
      }
    }
  }
  return null
}

export function returnLabelsWithValues (values, ObjectArray) {
  var temp = []

  if (values.length > 0) {
    for (var key in ObjectArray) {
      if (values.includes(ObjectArray[key]['value'])) {
        temp.push({
          value: ObjectArray[key]['value'],
          label: ObjectArray[key]['label']
        })
      }
    }
  } else {
    return null
  }
  return temp
}

/*return selected values with match value and label*/
export function returnLabelsWithValuesWithValueMAndLabelM (
  values,
  ObjectArray,
  valueMatch,
  labelMatch
) {
  var temp = []
  if (values.length > 0) {
    for (var key in ObjectArray) {
      if (values.includes(ObjectArray[key][valueMatch])) {
        temp.push({
          [valueMatch]: ObjectArray[key][valueMatch],
          [labelMatch]: ObjectArray[key][labelMatch]
        })
      }
    }
  } else {
    return null
  }
  return temp
}

export function returnLabelsWithValueOtherThenLabel (
  value,
  ObjectArray,
  keyValue,
  keyLabel
) {
  if (value) {
    for (var key in ObjectArray) {
      if (ObjectArray[key][keyValue] === value) {
        var data = {
          value: ObjectArray[key][keyValue],
          label: ObjectArray[key][keyLabel]
        }

        return data
      }
    }
  }

  return null
}

/*Return array key if key index match to define column otherwise null*/
export function returnKeyWithColumnMatch (columnName, ObjectArray, value) {
  for (var key in ObjectArray) {
    if (ObjectArray[key][columnName] === value) {
      return key
    }
  }
  return null
}
export function isEmpty (obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}
/*return value for nested array after key match with value */
export function returnColumnValueWithColumnMatch (
  ObjectArray,
  columnNameReturn,
  columnMatch,
  columnMatchName
) {
  // console.log('ObjectArray', ObjectArray);
  // console.log('columnNameReturn',columnNameReturn);
  // console.log('columnMatch', columnMatch);
  // console.log('columnMatchName', columnMatchName);
  if (ObjectArray.length > 0) {
    for (var key in ObjectArray) {
      if (columnMatch) {
        // console.log('test column', ObjectArray[key])
        if (columnMatch.includes(ObjectArray[key][columnMatchName])) {
          return ObjectArray[key][columnNameReturn]
        }
      }
    }
  }
  return null
}

/*return value for single array after key match */
export function returnColumnValueWithColumnMatchSA (
  ObjectArray,
  columnNameReturn
) {
  for (var key in ObjectArray) {
    if (columnNameReturn) {
      if (key === columnNameReturn) {
        return ObjectArray[columnNameReturn]
      }
    }
  }
  return null
}

export function returnKeys (Obj) {
  var keys = []
  for (var key in Obj) {
    if (typeof Obj[key] === 'object') {
      this.returnKeys(Obj[key])
    }
  }
  return keys
}
//b is the object from where to be copied and a is the object to be updated
export function intersectionOfObjectsWithNewValue (a, b) {
  var newObj = {}
  for (var key in a) {
    if (Array.isArray(a[key])) {
      newObj[key] = b[key]
    } else if (typeof a[key] === 'object') {
      if (typeof b[key] !== 'undefined') {
        var obj = intersectionOfObjectsWithNewValue(a[key], b[key])
        newObj[key] = obj
      } else {
        newObj[key] = a[key]
      }
    } else if (b.hasOwnProperty(key)) {
      newObj[key] = b[key] !== null ? b[key] : ''
    } else if (!b.hasOwnProperty(key)) {
      newObj[key] = a[key] ? a[key] : ''
    }
  }
  return newObj
}

export function omitKeys (obj, not) {
  var result = Object.assign({}, obj)
  for (let n of not) delete result[n]
  return result
}
export function sortObjectWithDefineKeys (object, keysObject) {
  let tempObject = {}
  for (var key in keysObject) {
    if (object.hasOwnProperty(keysObject[key])) {
      tempObject[keysObject[key]] = object[keysObject[key]]
    }
  }
  return tempObject
}

export function openModal (name) {
  this.setState({
    [name]: true
  })
}
export function closeModal (modalName) {
  this.setState({
    [modalName]: false
  })
}

export function closeModalAfterApi (modalName, afterApi) {
  this.setState({
    [modalName]: false,
    [afterApi]: true
  })
}

export function showDate (data) {
  const months = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC'
  ]
  const date = new Date(data)
  const d = date.getDate()
  let prefix
  if (d > 3 && d < 21) {
    prefix = 'th'
  } else {
    switch (d % 10) {
      case 1:
        prefix = 'st'
        return prefix
      case 2:
        prefix = 'nd'
        return prefix
      case 3:
        prefix = 'rd'
        return prefix
      default:
        break
    }
  }
  return `${
    months[date.getMonth()]
  } ${date.getDate()}${prefix} ,${date.getFullYear()}`
}

export function overRideDuplicateImage (currentImgs, AllImgs) {
  // for(var i in currentImgs ) {
  // 	for(var j in AllImgs) {
  // 	}
  // }
}

export function removeDuplicateImage (val, AllImgs) {
  for (var i in AllImgs) {
    if (AllImgs[i].name === val.name) {
      if (AllImgs[i].file === val.file)
        return {
          val: val,
          duplicate: true,
          name: val.name,
          index: i,
          message: val.name + ' Image name and file is same'
        }
      else
        return {
          val: val,
          duplicate: true,
          name: val.name,
          index: i,
          message: val.name + ' Image file name is same'
        }
    }
  }
  return { duplicate: false, val: val, name: val.name }
}

export function returnTimeStamp (dataString) {
  const dateTime = new Date(dataString).getTime()
  const timestamp = Math.floor(dateTime / 1000)
  return timestamp
}

export function hasRole (role) {
  let AdminUser = JSON.parse(localStorage.getItem('user'))
  if (AdminUser && AdminUser.token) {
    for (var i in AdminUser.user.roles) {
      var temp = AdminUser.user.roles[i]
      if (role.includes(temp.name)) {
        return true
      }
    }
    return false
  } else {
    return false
  }
}

export function optionsHasRole (options, roleName) {
  var temp = []
  for (var i in options) {
    for (var j in options[i].roles) {
      if (options[i].roles[j].name === roleName) {
        temp.push(options[i])
      }
    }
  }
  return temp
}

export function checkLoggedInUserById (id) {
  let AdminUser = JSON.parse(localStorage.getItem('user'))
  if (AdminUser && AdminUser.token) {
    return AdminUser.user.id === id ? true : false
  } else {
    return false
  }
}

export function toggleModal (name) {
  console.log('call toggle function 999')
  this.setState({
    [name]: !this.state[name]
  })
}

export function isLoggedIn () {
  let AdminUser = JSON.parse(localStorage.getItem('user'))
  return AdminUser && AdminUser.token ? true : false
}
export function getInitials (name, delimeter) {
  if (name) {
    var array = name.split(delimeter)
    switch (array.length) {
      case 1:
        return array[0].charAt(0).toUpperCase()
      //break;
      default:
        return (
          array[0].charAt(0).toUpperCase() +
          array[array.length - 1].charAt(0).toUpperCase()
        )
    }
  }

  return false
}

export function colorForBardcodeWithDate (date, dateName) {
  var color = ''
  if (date && dateName === 'videoSpecialNeedDate') {
    color = 'barcode-color-red'
  } else if (date && dateName === 'lastDayOfSchool') {
    var diff = moment().diff(date, 'days')
    if (diff > 0 && diff <= 5) {
      color = 'barcode-color-yellow'
    }
  }
  return color
}
//This function checks if the value is not null
export function clean (prop, value = null, date = null) {
  if (prop) {
    if (date && prop) {
      return new Date(prop)
    } else if (date && value !== null) {
      return value
    }
    return prop
  } else {
    if (value !== null) {
      return value
    } else {
      return ''
    }
  }
}

export function inputChangeHandler (e, pattern = null) {
  const checked = e.target.checked
  if (pattern) {
    if (e.target.value.match(pattern) === null) {
      return
    }
  }
  const container = e.target.name
  let value = e.target.value
  if (value === 'true') {
    value = true
  } else if (value === 'false') {
    value = false
  }
  const newState = updateState.bind(this)
  newState(container, value, checked)
}

export function dateChangeHandler (value, dateType, defaultValue) {
  if (defaultValue !== undefined && value === '') {
    value = defaultValue
  }
  const newState = updateState.bind(this)
  newState(dateType, value)
}

export function contactChangeHandler (e) {
  const { value } = e.target
  if (value.match(/^\d*$/) === null) {
    return
  }
  const container = e.target.name.split('.')
  let phone = ''.split('-')
  if (this.state[container[0]][container[1]][container[2]] !== null) {
    phone = this.state[container[0]][container[1]][container[2]].split('-')
  }
  phone[container[3]] = value
  const newContainer = container[0] + '.' + container[1] + '.' + container[2]
  const newState = updateState.bind(this)
  const phoneLength = phone.reduce((total, current) => {
    return (total = parseFloat(current.length) + parseFloat(total))
  }, 0)
  if (phoneLength > 0) {
    newState(newContainer, phone.join('-'))
  } else {
    newState(newContainer, '')
  }
}

export function selectChangeHandler (selected, action, type) {
  const newState = updateState.bind(this)
  newState(action.name, selected.value)
}

export function selectArrayChangeHandler (selected, action) {
  const container = action.name.split('.')
  let targetArray = []
  if (container.length > 1) {
    targetArray = this.state[container[0]][container[1]]
  } else {
    targetArray = this.state[action.name]
  }
  if (action.action === 'select-option') {
    selected = [...targetArray, action.option.value]
  }
  if (action.action === 'remove-value') {
    selected = targetArray.filter(item => {
      return item.value === action.removedValue.value
    })
  }
  const newState = updateState.bind(this)
  newState(action.name, selected)
}

export function updateState (containerState, value, checked = null) {
  const container = containerState.split('.')
  let currentState
  currentState = clonedeep(this.state[container[0]])
  switch (container.length) {
    case 1:
      currentState = value
      break
    case 2:
      const isArray = container[1].match(/(\[\])/g)
      if (isArray) {
        container[1] = container[1].replace('[]', '')
        if (currentState[container[1]]) {
          if (checked) {
            currentState[container[1]] = [
              ...currentState[container[1]],
              parseInt(value, 10)
            ]
          } else {
            currentState[container[1]] = currentState[container[1]].filter(
              item => {
                return item !== parseInt(value, 10)
              }
            )
          }
        } else {
          currentState[container[1]] = [value]
        }
      } else {
        currentState[container[1]] = value
      }
      break
    case 3:
      currentState[container[1]][container[2]] = value
      break
    case 4:
      currentState[container[1]][container[2]][container[3]] = value
      break
    default:
      break
  }
  this.setState({
    [container[0]]: currentState
  })
}
/*Append count in an array*/
export function appendCount (options) {
  for (var i in options) {
    options[i]['count'] = parseInt(i, 10)
  }
  return options
}

/*Append values in an array*/
export function appendValues (options, values) {
  // console.log('values', values);
  for (var i in options) {
    for (var j in values) {
      options[i][values[j]['column']] = values[j]['value']
    }
  }
  return options
}
/*Return true if key index match to define column otherwise false*/
export function returnBoleanWithColumnMatch (options, index, value) {
  for (var i in options) {
    if (index in options[i]) {
      if (options[i][index] === value) {
        return true
      }
    }
  }
  return false
}
/*return array after adding new key (setcolumn) in object, if keysArray Array have matching key (matchColumn) value */
export function appendValuesWithMatchKeys (
  options,
  keysArray,
  matchColumn,
  setColumn,
  setValue
) {
  for (var i in options) {
    if (keysArray.includes(options[i][matchColumn])) {
      options[i][setColumn] = setValue
    }
  }

  return options
}

/*Return an array of matching key from array(options)*/
export function returnArrayOfMatchingKeyFromArray (options, matchColumn) {
  // console.log('optiosn', options);
  // console.log('matchColumn', matchColumn);
  var temp = []
  for (var i in options) {
    if (matchColumn in options[i]) {
      if (options[i][matchColumn]) {
        temp.push(options[i][matchColumn])
      }
    }
  }
  return temp
}

export function changeText (attribute) {
  if (attribute.includes('item')) return 'item'
  else if (attribute.includes('inventory')) return 'inventory'
  // return 'hahaha';
}

export function getFormattedDate (date) {
  if (date !== '' && date !== null && date !== undefined) {
    const formattedDate = new Date(date)
    return (
      formattedDate.getMonth() +
      1 +
      '/' +
      formattedDate.getDate() +
      '/' +
      formattedDate.getFullYear()
    )
  } else {
    return ''
  }
}

export function compareDates (date1, date2, operator) {
  date1 = new Date(getFormattedDate(date1))
  date2 = new Date(getFormattedDate(date2))
  if (operator === '>') {
    if (date1 > date2) {
      return true
    } else {
      return false
    }
  } else if (operator === '<') {
    if (date1 < date2) {
      return true
    } else {
      return false
    }
  } else if (operator === '>=') {
    if (date1 >= date2) {
      return true
    } else {
      return false
    }
  } else if (operator === '<=') {
    if (date1 <= date2) {
      return true
    } else {
      return false
    }
  }
}

export function BytesToMb (bytes) {
  var kb = 1024
  // var ndx = Math.floor(Math.log(bytes) / Math.log(kb));

  return +(bytes / kb / kb).toFixed(2)
}

export function createFormData (dataArray) {
  const fd = new FormData()
  for (var i in dataArray) {
    if (Array.isArray(dataArray[i])) {
      console.log('length of array', dataArray[i].length)
      if (dataArray[i].length > 0) {
        for (var j in dataArray[i]) {
          fd.append(i, dataArray[i][j])
        }
      } else {
        fd.append(i, dataArray[i])
      }
    } else {
      fd.append(i, dataArray[i])
    }
  }
  return fd
}

export function isset (object, props) {
  var dump
  try {
    console.log('x props', props)
    for (var x in props) {
      console.log('x props 1', props)
      if (x === 0) {
        console.log('x props test')
        dump = object[props[x]]
        return
      }
      console.log('x data', x)
      dump = dump[props[x]]
    }
  } catch (e) {
    return false
  }

  return true
}

export function checkObjHasKeys (obj, keys) {
  var success = true
  keys.forEach(function (key) {
    if (!obj.hasOwnProperty(key)) {
      success = false
    }
    obj = obj[key]
  })
  console.log(success ? 'yes 321' : 'no 321')
  return success
}

export function checkNested (obj, keys) {
  var args = Array.prototype.slice.call(keys)
  obj = args.shift()
  for (var i = 0; i < args.length; i++) {
    if (obj == null || !obj.hasOwnProperty(args[i])) {
      return false
    }
    obj = obj[args[i]]
  }
  return true
}

export function lengthOfMultiArray (array) {
  var total = 0
  for (var i in array) {
    if (Array.isArray(array[i])) {
      total += lengthOfMultiArray(array[i])
    } else {
      total++
    }
  }

  return total
}

export function flatten (obj, prefix = '', res = {}) {
  return Object.entries(obj).reduce((r, [key, val]) => {
    const k = `${prefix}${key}`
    if (typeof val === 'object') {
      flatten(val, `${k}.`, r)
    } else {
      res[k] = val
    }
    return r
  }, res)
}
// function flatten(data){
//   return data.reduce(function(result,next){
//     result.push(next);
//     if(next.items){
//       result = result.concat(flatten(next.items));
//       next.items = [];
//     }
//     return result;
//   },[]);
// }

export function matchWithFlatten (match, data) {
  for (var i in data) {
    if (match === data) {
      return i
    }
  }

  return null
}
export function getRandomInt (max) {
  return Math.floor(Math.random() * Math.floor(max))
}

export function returnDynamicURL (domainSlug, email, password) {
  // const host = window.location.hostname.replace(/www./).split('.');
  let match, result
  match = document.domain.match(
    /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\n]+)/im
  )
  if (match) {
    result = match[1]
    let domainSlugs = ['gtv', 'brt', 'mci', 'eti', 'itk', 'act']
    if (domainSlugs.includes(result.split('.')[0])) {
      result = result
        .split('.')
        .slice(1)
        .join('.')
    }
  }
  if (process.env.NODE_ENV === 'development') {
    domainSlug = window.location.host
    return `http://${domainSlug}/login/${email}/${password}`
  } else {
    return `http://${domainSlug}.${result}/login/${email}/${password}`
  }
}

export function getSum (val1, val2) {
  return parseInt(val1, 10) + parseInt(val2, 10)
}

export function remove_duplicates (arr) {
  let s = new Set(arr)
  let it = s.values()
  return Array.from(it)
}

export function isEmptyCheck (data) {
  if (typeof data === 'object') {
    if (JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]') {
      return true
    } else if (!data) {
      return true
    }
    return false
  } else if (typeof data === 'string') {
    if (!data.trim()) {
      return true
    }
    return false
  } else if (typeof data === 'undefined') {
    return true
  } else {
    return false
  }
}

export function boldStatment (text, regexStatement) {
  var temp = text
  var regex = new RegExp(/^[^:-]*[:-]\s*/g, 'g')
  var result = temp.replace(regex, '<b>' + text + '</b>')
  return result
}

export function dateContainInObject (object, slot) {
  // console.log('event 123', object);
  //    console.log('info', slot);

  for (var i in object) {
    if (
      +object[i]['start'] === +slot['start'] &&
      +object[i]['end'] === +slot['end']
    ) {
      return true
    }
  }

  return false
}

export function dateContainInObjectStart (object, date) {
  // console.log('event 123', object);
  //    console.log('info', slot);

  for (var i in object) {
    if (+object[i]['start'] === +date) {
      return true
    }
  }

  return false
}

export function columnFilters (columns) {
  let matchFilterWith = []
  columns.forEach(column => {
    if (
      column.id !== undefined ||
      (column.accessor !== undefined && column.filterable !== false)
    ) {
      if (column.id) {
        matchFilterWith.push(column.id)
      } else {
        matchFilterWith.push(column.accessor)
      }
    }
  })
  return matchFilterWith
}

export function createSortString (sorted) {
  let sort = ''
  let orderType = 'asc'
  if (sorted.length > 0 && sorted[0] !== '') {
    sort = sorted[0]
    let tempOrder = sorted[1].split(':')
    if (tempOrder[1] === 'true') {
      orderType = 'desc'
    }
  }
  return {
    sort,
    orderType
  }
}

export function convertMomentIntoDateObj (value) {
  return moment.isMoment(value) ? value.toDate() : value
}

export function s3StaticPath (url) {
  return `https://gtvoriginalimages.s3.amazonaws.com/static/${url}`
}

export function scrollLogin () {
  // $(document).ready(function () {
  // 	$("html, body").animate({scrollTop: '0'}, 500, function (e) {
  // 		console.log($(".loginIcon > a").length)
  // 		$(".loginIcon > a")[0].click();
  // 	});
  // })
  //   $('html, body').animate({ scrollTop: '0' }, 500, function (e) {
  //     !$('.loginIcon').hasClass('open') && $('.loginIcon').addClass('open')
  //   })
}

export function isEmptyStr (str) {
  console.log('strrrr', str)
  return !str || 0 === str.length
}

export function usePrevious (value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function setAccessTokens (object) {
  let accessTokens = JSON.parse(localStorage.getItem('accessTokens')) || []
  accessTokens.push({
    accessToken: object.token.access_token,
    institution: object.institution
  })
  localStorage.setItem('accessTokens', JSON.stringify(accessTokens))
}

export function currencyFormat (num) {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function allCommonProperties (objects) {
  console.log('objects', objects)
  if (!objects.length) return []
  var first = objects[0]
  return Object.keys(first).filter(function (p) {
    return objects.every(function (o) {
      return first[p] === o[p]
    })
  })
}
