import React from "react";
import { withRouter } from "react-router-dom";

function Scroller(props) {
    return (
        <div>
            {props.children}
        </div>
    );
}

export default withRouter(Scroller)