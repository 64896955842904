import { SC } from "../helper/ServerCall";

export const emailServices = {
  getSingleEmail,
  getEmails,
  addEamil,
  updatedEmail,
  getAllEmails
}

function getSingleEmail(id) {
  return SC.getCall(`getSingleEmail/${id}`);
}
function getAllEmails() {
  return SC.getCall(`getAllEmails`);
}

function getEmails(data) {
  return SC.postCall(`getEmails`, data);
}
function addEamil(data) {
  return SC.postCall(`addEmail`, data);
}

function updatedEmail(data) {
  return SC.postCall(`addEmail`, data);
}