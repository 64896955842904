import React from "react";
import {
  Accordion as AccordionComponent,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
// import "react-accessible-accordion/dist/fancy-example.css";
import "./styles.css";
import Table from "../Table";

const Accordion = ({ data }) => {
  if (data && !data.length) {
    return (
      <h4 style={{ display: "flex", justifyContent: "center" }}>
        No Vendors Found
      </h4>
    );
  }

  return data.map(({ name, transactions, transactions_count }, i) => {
    return (
      <AccordionComponent
        allowZeroExpanded={true}
        allowMultipleExpanded={false}
        key={i}
      >
        <AccordionItem key={i}>
          <AccordionItemHeading className="accordion__heading active">
            <AccordionItemButton>
              <i
                className="fa fa-industry"
                style={{ marginRight: 10 }}
                aria-hidden="true"
              />
              {name}
              <span className="badge">{transactions_count}</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion__panel active">
            <Table body={transactions} />
          </AccordionItemPanel>
        </AccordionItem>
      </AccordionComponent>
    );
  });
};

export default Accordion;
