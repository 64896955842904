
const initialState = {
    giftCards: [],
}

const giftCardReducer = (state = initialState, action) => {
    switch(action.type) {
        
        case "STORE_GIFT_CARD": 
          return {giftCards:action.payload}

        default:
            return{ ...state}


    }



};
export default giftCardReducer