import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Grid, Row, Col, Button, DropdownButton } from "react-bootstrap";
import XLSX from "sheetjs-style";
import Modal from "react-bootstrap-modal";
// const cloneDeep = require("lodash.clonedeep");
// import cloneDeep from "lodash.clonedeep";
import { Card } from "components/Card/Card.jsx";
import Pagination from "components/ThemeComponents/Pagination";
import { formatData } from "./formatData";
import { alertActions } from "redux/actions/alert-actions";
import Accordion from "./Accordion";
import { FieldGroup as FieldGroupTwo } from "../../FormInputs/FormInputs";
import DataInput from "./components/DataInput";
import { giftCardServices } from "../../../services/giftcard";
import { transactionServices } from "../../../services/transaction";
import Select from "react-select";


function GiftCard(props) {
  const dispatch = useDispatch();

  const [cardValue, setCardValue] = useState({
    name: "",
    card: "",
    purchase: "",
    balance: "",
    amount: "",
  });
  const [error, setError] = useState({
    name: "",
    card: "",
    purchase: "",
    balance: "",
    amount: "",
  });
  const [data, setData] = useState([]);
  const [dataModal, setDataModal] = useState(false);
  const [openToogle, setOpenToogle] = useState(false);
  const [total, setTotal] = useState(null);
  const [grandTotal, setGrandTotal] = useState(null);
  const [giftCard, setGiftCard] = useState(null);
  const [usedGiftCard, setUsedGiftCard] = useState(null);
  const [addCard, setAddCard] = useState(false);
  const [searchValue, setSearch] = useState();
  const [vendorName, setVendorName] = useState();
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [availableCards, setAvailableCards] = useState(true)
  const [usedCards, setUsedCards] = useState(false)
	const [cardType, setCardType] = useState('available');

  //pagination refs
  const pagination = useRef();

  useEffect(() => {
    getGiftCards();
    getUsedCards();
    getGiftCardVendor();
  }, [selectedVendor, cardType]);

  const getGiftCards = async (filter) => {
    const filterObj = {
      ...filter,
	    cardType: cardType,
      vendorName: selectedVendor && selectedVendor.name
    }

    let res = await giftCardServices.getGiftCard({ ...filterObj });
    if (res) {
      setGiftCard(res);
      setTotal(res.sum);
      setGrandTotal(res.grand_total);
    }
    return res;

  };

  const getUsedCards = async (filter ) => {
    const filterObj = {
      ...filter,
	   cardType:  cardType,
      vendorName: selectedVendor && selectedVendor.name
    }
    let res = await giftCardServices.getGiftCard({ ...filterObj });
    if (res) {
      setUsedGiftCard(res);
      setTotal(res.sum);
      setGrandTotal(res.grand_total);
      return res
    }

  }


  // Get vendors list
  const getGiftCardVendor = async () => {
		const filterObj = {
			cardType
		}
    let res = await giftCardServices.getGiftCardVendor( filterObj)

    if (res) {
      setVendorName(res.data.giftCardVendor)
    }
  }

  // cards value
  const handleCardValue = (e) => {
    setCardValue({ ...cardValue, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  // submit cards value
  const handleSubmit = () => {
    let validate = validateDate(cardValue);
    if (!validate) {
      return;
    } else {
      let arr = [cardValue];
      saveData(arr);
    }
  };

  // Save card data

  const saveData = async (data) => {
    let response = await giftCardServices.storeGiftCard(data);
    if (response.error) {
      dispatch(alertActions.error(response.message));
    } else {
      dispatch(alertActions.success(response.message));
      setCardValue({
        name: "",
        amount: "",
        purchase: "",
        card: "",
      });
      getGiftCards();
    }
  };

  // Validate Feilds

  const validateDate = (data) => {
    let obj = { error };
    let validate = true;
    if (data.name == "") {
      obj["name"] = "* Name is required";
      validate = false;
    }
    if (data.card == "") {
      obj["card"] = "* Card is required";
      validate = false;
    }
    if (data.amount == "") {
      obj["amount"] = "* Amount is required";
      validate = false;
    }
    if (data.purchase == "") {
      obj["purchase"] = "* Price is required";
      validate = false;
    }

    setError(obj);
    return validate;
  };

  const downloadTemplate = async () => {
    try {
      let data = [];
      let h1 = ["   "];
      let heading = ["Vendor Name", "Card Number", "Value", "Purchase Price"];
      data.length = heading.length;

      let result = [h1, heading, data];
      let ws = XLSX.utils.aoa_to_sheet(result);
      ws["!merges"] = [XLSX.utils.decode_range("A1:D1")];
      ws["!rows"] = [{ hpt: 20 }];
      ws["A1"].s = {
        font: {
          sz: 16,
          color: {
            rgb: "FF0000",
          },
        },
        fill: {
          fgColor: { rgb: "FFFF00" },
        },
        alignment: {
          horizontal: "center",
          vertical: "center",
        },
      };
      const get_header_row = (sheet) => {
        let range = XLSX.utils.decode_range(sheet["!ref"]);
        let C,
          R = 1;
        let headers = [];
        for (C = range.s.c; C <= range.e.c; ++C) {
          let cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
          var cell_address = { c: C, r: R };
          var cell_ref = XLSX.utils.encode_cell(cell_address);
          if (cell && cell.t) headers.push(cell_ref);
        }
        return headers;
      };
      const h = get_header_row(ws);
      h.map(
        (a) =>
        (ws[a].s = {
          font: {
            bold: true,
          },
          alignment: {
            horizontal: "center",
            vertical: "center",
          },
        })
      );

      ws["!cols"] = fitToColumn(result);
      function fitToColumn(result) {
        let a = result[1].map((a, i) => ({
          wch: Math.max(
            ...result.map((a2) => (a2[i] ? a2[i].toString().length + 3 : 0))
          ),
        }));
        return a;
      }
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Gift_Card Detail");
      XLSX.writeFile(wb, "Gift_card.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const handleFile = async (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = async (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data1 = XLSX.utils.sheet_to_json(ws, { header: 1, defval: "" });
      const formattedData = formatData(data1);
      // check valid file or not
      if (data1[1][0] !== "Vendor Name") {
        dispatch(alertActions.error("please select a valid file."));
        return;
      }
      //check file is empty or not
      if (data1.length < 3) {
        dispatch(alertActions.error("You've uploaded an empty file."));
        return;
      }

      if (formattedData.length <= 0) {
        dispatch(alertActions.error("Please fill all fields in gift card csv."));
        return;
      }

      let validate = validateFormattedData(formattedData);
      if (validate) {
        saveData(formattedData);
      }
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };


  const validateFormattedData = (data) => {
    try {
      let errors = [];
      data &&
        data.forEach((d, index) => {
          // required feilds validations
          if (d.name === "") {
            let err = `Vendor name is required, Please check your row ${index}`;
            errors.push(err);
          }
          if (d.card === "") {
            let err = [
              `Card information is required, Please check your row ${index}`,
            ];
            errors.push(err);
          }
          if (d.amount === "") {
            let err = [`Value is required, Please check your row ${index}`];
            errors.push(err);
          }
          if (d.purchase === "") {
            let err = [
              `Purchase amount is required, Please check your row ${index}`,
            ];
            errors.push(err);
          }
          let isNumber = !isNaN(parseFloat(d.amount)) && isFinite(d.amount);
          if (isNumber === false && d.amount !== "") {
            errors.push([
              ` Value must be in number. Please check your value ${d.amount} at row ${index}`,
            ]);
          }
          isNumber = !isNaN(parseFloat(d.purchase)) && isFinite(d.purchase);
          if (isNumber === false && d.purchase !== "") {
            errors.push([
              ` Purchase amount must be in number. Please check your value ${d.purchase} at row ${index}`,
            ]);
          }
        });

      if (errors.length > 0) {
        dispatch(alertActions.error(errors));
        return false;
      } else {
        return true;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const importDataSubmit = async (data) => {
    await transactionServices.importFromExcel(data);
    setDataModal(false);
    window.location.reload();
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleChangeDropdown = (selectedOptionType, info) => {
    if (info["action"] === "select-option" && info["name"] === "selectedVendor") {
      setSelectedVendor({ ...selectedOptionType });
    }

    // to clear the field
    if (info["action"] === "clear" && info["name"] === "selectedVendor") {
      setSelectedVendor();
    }
  };

  const lowerContent = () => {

    return (
      <Row>
        <Col md={12} lg={12} sm={12} xs={12}>
          <div
            className="gc_blnce"
          >
            <span style={{ fontWeight: "bolder" }}>
              {" "}
              Total:{" "}
            </span>
            <span style={{ fontWeight: "bolder" }}>
              {" "}
              ${total}{" "}
            </span>
            <br></br>
            <span style={{ fontWeight: "bolder" }}>
              {" "}
              Grand Total:{" "}
            </span>
            <span style={{ fontWeight: "bolder" }}>
              {" "}
              ${grandTotal}{" "}
            </span>
          </div>
        </Col>
        <Col md={12} lg={12} sm={12} xs={12}>
          <div
            className="gc_total_blnce"
          >

          </div>
        </Col>
      </Row>)
  }

  const upperContent = () => {
    return (
      addCard ? (
        <>
          <Col>
            <Col xs={12} md={2} lg={2}>
              <FieldGroupTwo
                id="name"
                type="text"
                label="Vendor Name"
                name="name"
                required={true}
                className="fieldGroup"
                value={cardValue.name}
                placeholder="Enter Vendor Name"
                onChange={handleCardValue}
              />
              <span
                className="gc_err"
              >
                {error.name}
              </span>

            </Col>

            <Col xs={12} md={2} sm={12} lg={2}>
              <FieldGroupTwo
                id="cardNumber"
                type="text"
                label="Card Number"
                name="card"
                value={cardValue.card}
                className="fieldGroup"
                placeholder="Enter Card Number"
                onChange={handleCardValue}
              />
              <span
                className="gc_err"
              >
                {error.card}
              </span>

            </Col>

            <Col xs={12} sm={12} md={2} lg={2}>
              <FieldGroupTwo
                id="value"
                type="text"
                label="Value"
                name="amount"
                className="fieldGroup"
                value={cardValue.amount}
                placeholder="Enter Value"
                onChange={handleCardValue}
              />
              <span
                className="gc_err"
              >
                {error.amount}
              </span>
            </Col>
            <Col xs={12} sm={12} lg={2} md={2}>
              <FieldGroupTwo
                id="price"
                type="text"
                label="Purchase Price"
                name="purchase"
                className="fieldGroup"
                value={cardValue.purchase}
                placeholder="Enter Purchase Price"
                onChange={handleCardValue}
              />
              <span
                className="gc_err"
              >
                {error.purchase}
              </span>
            </Col>


            <Col md={1} lg={1} sm={12} xs={12}>
              <div>
                <button
                  type="submit"
                  className="btn btn-lg srchBtn add_gc_btn"
                  onClick={handleSubmit}
                >
                  Add
                </button>
              </div>
            </Col>

          </Col>
        </>
      ) :
        <>
          <Col xs={16} sm={14} md={4} lg={4}>
            <div className='form-group'>
              <label htmlFor='vendor'>Select Vendor</label>
              <Select
                getOptionLabel={option =>
                  `${option.name ? option.name : ''}`
                }
                getOptionValue={option => option.name}
                className='new-font-size'
                style={{ borderRadius: '5px' }}
                options={vendorName}
                onChange={handleChangeDropdown}
                value={selectedVendor ? selectedVendor : null}
                placeholder='Select Vendor name'
                name='selectedVendor'
                isClearable={true}
              />
            </div>
          </Col>
        </>
    )
  }
  //pagination columns
  const columns = [
    {
      Header: "Transaction Status Listing",
      headerClassName: "headerPagination",
      className: "test123",
      columns: [
        {
          Header: "Name",
          className: "action-center",
          maxWidth: 300,
          minWidth: 300,
          width: 300,
          Header: () => (
            <div
              style={{
                textAlign: "left",
                alignItems: "left",
                alignContent: "left",
                paddingLeft: 20,
                width: "35%",
              }}
            >
              Vendor Name
            </div>
          ),
          accessor: "name",
          sortable: false,
          Cell: ({ row, value }) => (
            <div style={{ textAlign: "left", width: "100%", paddingLeft: 20 }}>
              {value}
            </div>
          ),
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: "left",
                alignItems: "left",
                alignContent: "left",
                paddingLeft: 20,
                width: "35%",
              }}
            >
              Card Number
            </div>
          ),
          className: "action-center",
          maxWidth: 300,
          width: 300,
          accessor: "card_no",
          // className: "action-center",
          Cell: ({ row, value }) => (
            <div
              style={{ textAlign: "left", width: "100%", paddingLeft: 20 }}
            >{`${value}`}</div>
          ),
          sortable: false,
          maxWidth: 120,
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: "left",
                alignItems: "left",
                alignContent: "left",
                paddingLeft: 20,
              }}
            >
              Value
            </div>
          ),
          accessor: "amount",
          className: "action-center",
          // maxWidth: 200,
          // width: 200,
          Cell: ({ value }) => {
            return (
              <div
                style={{ textAlign: "left", width: "100%", paddingLeft: 20 }}
              >
                {value}
              </div>
            );
          },
          sortable: false,
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: "left",
                alignItems: "left",
                alignContent: "left",
                paddingLeft: 20,
              }}
            >
              Purchase Price
            </div>
          ),
          accessor: "purchaseAmount",
          className: "action-center",
          Cell: ({ value }) => {
            return (
              <div
                style={{ textAlign: "left", width: "100%", paddingLeft: 20 }}
              >
                {value}
              </div>
            );
          },
          sortable: false,
          // maxWidth: 150,
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: "left",
                alignItems: "left",
                alignContent: "left",
                paddingLeft: 20,
              }}
            >
              Balance
            </div>
          ),
          accessor: "balance",
          className: "action-center",
          Cell: ({ value }) => {
            return (
              <div
                style={{ textAlign: "left", width: "100%", paddingLeft: 20 }}
              >
                {value}
              </div>
            );
          },
          sortable: false,
          maxWidth: 150,
        },
      ],
    },
  ];

  return (
    <div>
      <Grid fluid>
        <Row style={{ border: "" }}>
          <Card
            // ctTableResponsive
            content={
              <div>
                <Grid fluid>
                  <Row>
                    <Col md={12}>
                      <div className="secHeading">
                        <i className="fa fa-credit-card" aria-hidden="true"></i>
                        <span className="gc_icon">
                          Gift Card Vaults
                        </span>
                      </div>
                    </Col>
                    <Col>
                      <Row
                        style={{
                          width: "100%",
                          margin: "auto",
                          marginBottom: "20px",
                        }}
                      ></Row>
                    </Col>

                    {dataModal && (
                      <Modal
                        backdrop={"static"}
                        show={dataModal}
                        onHide={() => setDataModal(false)}
                        aria-labelledby="ModalHeader"
                        dialogClassName="fullscreenmodal"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title
                            id="ModalHeader"
                            className="headerTitle"
                            style={{ width: 800 }}
                          >
                            Import Data Preview
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card
                                bsClass={["innerCard mb-none"]}
                                content={<Accordion data={data} />}
                              />
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            bsStyle="danger"
                            onClick={() => setDataModal(false)}
                            style={{
                              border: "white",
                              color: "white",
                              backgroundColor: "#EE2D20",
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            bsStyle="primary"
                            style={{
                              border: "white",
                              color: "white",
                              backgroundColor: "#2F4576",
                            }}
                            onClick={() => importDataSubmit(data)}
                          >
                            Upload
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    )}
                    <Col>
                      <div className="panel panel-default">
                        <div
                          className="row"
                          style={{ margin: "auto", background: "#F7F8FA" }}
                        >
                          <div className="col-md-8">
                            <div
                              className='row'
                              style={{ margin: 'auto', background: '#F7F8FA' }}
                            >
                              <div className='col-md-8'>
                                <ul className='tabsLst'>
                                  <li>
                                    <div
                                      className='btn btn-default btn-sm  tab_lbl'
                                      style={{
                                        marginBottom: -1,
                                        backgroundColor: availableCards && '#006EEB',
                                        color: availableCards && 'white'
                                      }}
                                      onClick={() => {
																				setCardType('available')
                                        setUsedCards(false)
                                        setAvailableCards(true)
                                        getGiftCards()
                                      }}
                                    >
                                      Available
                                    </div>
                                  </li>

                                  <li>
                                    <div
                                      className='btn btn-default btn-sm  tab_lbl'
                                      style={{
                                        marginBottom: -1,
                                        backgroundColor: usedCards && '#006EEB',
                                        color: usedCards && 'white'
                                      }}
                                      onClick={() => {
																				setCardType('used')
                                        setUsedCards(true)
                                        setAvailableCards(false)
                                        getUsedCards()
                                      }}
                                    >
                                      Used
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>

                          </div>
                          <div className="col-md-4">
                            <ul
                              style={{
                                justifyContent: "right",
                                listStyle: "none",
                                display: "flex",
                              }}
                            >
                              <li style={{ marginRight: "10px" }}>
                                <button
                                  className="btn headingBtn tab_btn"
                                  title="Add gift card"
                                  onClick={() => {
                                    setAddCard(!addCard);
                                    if (error) {
                                      setError({
                                        name: "",
                                        card: "",
                                        purchase: "",
                                        balance: "",
                                        amount: "",
                                      });
                                    }
                                  }}
                                >
                                  <span className="fa fa-plus"></span>
                                </button>
                              </li>
                              <li>
                                <DropdownButton
                                  bsStyle="default"
                                  noCaret
                                  onToggle={() => setOpenToogle(!openToogle)}
                                  open={openToogle}
                                  pullRight
                                  role="menu"
                                  className="btn headingBtn tab_btn"
                                  id="dropdown-no-caret"
                                  title={
                                    <span className="fa fa-ellipsis-v"></span>
                                  }
                                >
                                  <label
                                    htmlFor="file"
                                    className="btn menu"
                                    onClick={() => setOpenToogle(false)}
                                  >
                                    {" "}
                                    <DataInput handleFile={handleFile} />
                                  </label>

                                  <label
                                    className="btn menu"
                                    onClick={() => {
                                      downloadTemplate();
                                      setOpenToogle(false);
                                    }}
                                  >
                                    {" "}
                                    <span className="dropdown_text">
                                      {" "}
                                      Download Template{" "}
                                    </span>
                                  </label>
                                </DropdownButton>
                              </li>
                            </ul>
                          </div>
                        </div>


                        <div className="panel-body">
                          { availableCards &&
                            <Pagination
                              style={{
                                fontSize: "14px",
                                textAlign: "left",
                                alignItems: "left",
                                alignContent: "left",
                                display: "flex",
                              }}
                              ref={pagination}
                              columns={columns}
                              searchValue={searchValue}
                              getDataCall={getGiftCards}
                              updatedData={giftCard}
                              noDataText="No Item found"
                              showPagination={true}
                              filterPlaceHolder="Search Gift Card ..."
                              filterViewForFilter={true}
                              page={0}
                              lowerContent={lowerContent()}
                              upperContent={upperContent()}
                            />
                          }
                          { usedCards &&
                            <Pagination
                              style={{
                                fontSize: "14px",
                                textAlign: "left",
                                alignItems: "left",
                                alignContent: "left",
                                display: "flex",
                              }}
                              ref={pagination}
                              columns={columns}
                              searchValue={searchValue}
                              getDataCall={getUsedCards}
                              updatedData={usedGiftCard}
                              noDataText="No Item found"
                              showPagination={true}
                              filterPlaceHolder="Search Gift Card ..."
                              filterViewForFilter={true}
                              page={0}
                              lowerContent={lowerContent()}
                              upperContent={upperContent()}
                            />
                          }

                        </div>

                      </div>
                    </Col>
                    <Col md={12}></Col>
                  </Row>
                </Grid>
              </div>
            }
          />
        </Row>
      </Grid>
    </div>
  );
}

export default GiftCard;
