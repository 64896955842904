import React, { useState, useEffect } from 'react'
import {  Table, Row, Col, Badge } from 'react-bootstrap'
import './ups.css'
import moment from 'moment'
import { transactionServices } from '../../../services/transaction'

var week = 1

const dummy = {
  name: null,
  service: null,
  shipments: [
    { check: false },
    { check: false },
    { check: false },
    { check: false },
    { check: false }, 
    { check: false },
    { check: false },
  ],
  count: null,
}

function UPS() {
  const [oneName,setOneName]=useState(null)
  const [oneDate,setOneDate]=useState(null)
  const [twoName, setTwoName] = useState(null)
  const [twoDate, setTwoDate] = useState(null)
  const [threeName, setThreeName] = useState(null)
  const [threeDate, setThreeDate] = useState(null)
  const [fourName, setFourName] = useState(null)
  const [fourDate, setFourDate] = useState(null)
  const [fiveName, setFiveName] = useState(null)
  const [fiveDate, setFiveDate] = useState(null)
  const [sixName, setSixName] = useState(null)
  const [sixDate, setSixDate] = useState(null)
  const [sevenName, setSevenName] = useState(null)
  const [sevenDate, setSevenDate] = useState(null)
  const [finalData,setFinalData]=useState([])
  const [done,setDone]=useState(false)
  var count=0;
  const  sortedData=[]

  const Arrangedata=(data)=>{
    if(data){
     data.map((item)=>{
       if (item.shipment_items.length > 0) 
       sortedData.push(JSON.parse(JSON.stringify(dummy)))
     })

      let i = 0
      data.map((item,iid)=>{
        
      if (item.shipment_items.length>0)
      {
        sortedData[i].shipCount= item.shipment_items.length
        sortedData[i].name=item.name
        sortedData[i].service = item.shipment_items[0].trackingdetail.type
        let finalcount=0
        item.shipment_items.map((ship)=>{
          finalcount = finalcount+ship.qty_expected
          if(ship.trackingdetail.delivery_date===oneDate){
            sortedData[i].shipments[0].check=true
          }
          if (ship.trackingdetail.delivery_date === twoDate) {
            sortedData[i].shipments[1].check = true
          }
          if (ship.trackingdetail.delivery_date === threeDate) {
            sortedData[i].shipments[2].check = true
          }
          if (ship.trackingdetail.delivery_date === fourDate) {
            sortedData[i].shipments[3].check = true
          }
          if (ship.trackingdetail.delivery_date === fiveDate) {
            sortedData[i].shipments[4].check = true
          }
          if (ship.trackingdetail.delivery_date === sixDate) {
            sortedData[i].shipments[5].check = true
          }
          if (ship.trackingdetail.delivery_date === sevenDate) {
            sortedData[i].shipments[6].check = true
          }

        })
        sortedData[i].count=finalcount
        i++
      }
    })
    return sortedData
   }
    
  }
  

  useEffect(()=>{
    if(oneDate===null){
      setOneName(moment().add(0,'days').format('dddd'))
      setOneDate(moment().add(0,'days').format('MM/DD/YYYY'))
      setTwoName(moment().add(1,'days').format('dddd'))
      setTwoDate(moment().add(1,'days').format('MM/DD/YYYY'))
      setThreeName(moment().add(2,'days').format('dddd'))
      setThreeDate(moment().add(2,'days').format('MM/DD/YYYY'))
      setFourName(moment().add(3,'days').format('dddd'))
      setFourDate(moment().add(3,'days').format('MM/DD/YYYY'))
      setFiveName(moment().add(4,'days').format('dddd'))
      setFiveDate(moment().add(4,'days').format('MM/DD/YYYY'))
      setSixName(moment().add(5,'days').format('dddd'))
      setSixDate(moment().add(5,'days').format('MM/DD/YYYY'))
      setSevenName(moment().add(6,'days').format('dddd'))
      setSevenDate(moment().add(6,'days').format('MM/DD/YYYY'))
    }
   
  },[])

  useEffect(()=>{
    
    if(sevenDate){
      let temp=null
    const fetch={start_date:oneDate,
                  end_date:sevenDate}
                  
    transactionServices.shipmentDetails(fetch)
    .then(
        (response) => {
          if(response.data)
            temp=Arrangedata(response.data)
            setFinalData(sortedData) 
        },
        (error) => {
         console.log('error',error)
        }
      )
    
      }
  },[sevenDate])

  useEffect(() => {
    setFinalData(sortedData)
  }, [done])


  const NextWeek=()=>{
    setOneDate(moment().add(7*week, 'days').format('MM/DD/YYYY'))
    setTwoDate(moment().add((7*week)+1, 'days').format('MM/DD/YYYY'))
    setThreeDate(moment().add((7*week)+2, 'days').format('MM/DD/YYYY'))
    setFourDate(moment().add((7*week)+3, 'days').format('MM/DD/YYYY'))
    setFiveDate(moment().add((7*week)+4, 'days').format('MM/DD/YYYY'))
    setSixDate(moment().add((7*week)+5, 'days').format('MM/DD/YYYY'))
    setSevenDate(moment().add((7*week)+6, 'days').format('MM/DD/YYYY'))
    week++
  }

  const PreviousWeek=()=>{
    week--
    setOneDate(moment().add((7*week)-7, 'days').format('MM/DD/YYYY'))
    setTwoDate(moment().add((7*week)-6, 'days').format('MM/DD/YYYY'))
    setThreeDate(moment().add((7*week)-5, 'days').format('MM/DD/YYYY'))
    setFourDate(moment().add((7*week)-4, 'days').format('MM/DD/YYYY'))
    setFiveDate(moment().add((7*week)-3, 'days').format('MM/DD/YYYY'))
    setSixDate(moment().add((7*week)-2, 'days').format('MM/DD/YYYY'))
    setSevenDate(moment().add((7*week)-1, 'days').format('MM/DD/YYYY'))
  }

  
  
  return (
    <div className='colorDiv'>
      <Row className='mainDiv'>
        <Col xs={8} className='expectedDeliveryText'>
          <div className='secHeading' style={{ marginLeft: 10, marginTop: 30 }}>
            Expected Shipment Delivery Page
          </div>
        </Col>
      </Row>

      <div className='MainContent' style={{ height: finalData.length<5 ? window.innerHeight-100:'auto' }}>
        <Row style={{ paddingTop: '16px' }}>
          <Col xs={4}>
            <div style={{ dispaly: 'flex', justifyContent: 'center' }}>
              <div>
                {' '}
                {week===1?
                <h4 style={{ paddingLeft: '30px', fontWeight: 'bold' }}>
                  {' '}
                  This Week's Deliveries{' '}
                </h4>
                :week<1?
              <h4 style={{ paddingLeft: '30px', fontWeight: 'bold' }}>
                  {' '}
                  Previous Week's Deliveries{' '}
                </h4>
                :
                <h4 style={{ paddingLeft: '30px', fontWeight: 'bold' }}>
                  {' '}
                  Next Week's Deliveries{' '}
                </h4>}           
              </div>
              <div style={{ marginTop: 28, marginLeft: '90%' }}>
                <i
                  className='fa fa-arrow-circle-o-left'
                  style={{ fontSize: 36, cursor: 'pointer' }}
                  onClick={PreviousWeek}
                ></i>
              </div>
            </div>
          </Col>
          <Col className='alignCenter' xs={4}>
            <div style={{ dispaly: 'flex', justifyContent: 'center' }}>
              <div>
                {' '}
                <h4 className='bold'>Displaying Shipments From </h4>
              </div>
              <div>
                <h4 className='bold'>
                  {oneDate} - {sevenDate}
                </h4>
              </div>
            </div>
          </Col>
          <Col className='alignEnd' xs={4}>
            <div style={{ dispaly: 'flex', justifyContent: 'center' }}>
              <div>
                {' '}
                <h4
                  style={{
                    paddingLeft: '30px',
                    fontWeight: 'bold',
                    color: 'white',
                  }}
                >
                  {' '}
                  1
                </h4>
              </div>
              <div style={{ marginTop: 28, marginRight: '90%' }}>
                <i
                  className='fa fa-arrow-circle-o-right'
                  style={{ fontSize: 36, cursor: 'pointer' }}
                  onClick={NextWeek}
                ></i>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ width: '100%' }} className='padding'>
          {finalData.length === 0 ? (
            <div className='noData'>No data for this week</div>
          ) : (
            <Table responsive bordered={false}>
              <thead>
                <tr style={{ color: '#c1c3c7' }}>
                  <th colSpan={1} className='textAlign'>
                    Item's Name
                  </th>
                  <th colSpan={1} className='textAlign'>
                    Service
                  </th>
                  <th className='textAlign'>
                    <div>{oneName}</div>
                    <div>{oneDate}</div>
                  </th>
                  <th className='textAlign'>
                    <div>{twoName}</div>
                    <div>{twoDate}</div>
                  </th>
                  <th className='textAlign'>
                    <div>{threeName}</div>
                    <div>{threeDate}</div>
                  </th>
                  <th className='textAlign'>
                    <div>{fourName}</div>
                    <div>{fourDate}</div>
                  </th>
                  <th className='textAlign'>
                    <div>{fiveName}</div>
                    <div>{fiveDate}</div>
                  </th>
                  <th className='textAlign'>
                    <div>{sixName}</div>
                    <div>{sixDate}</div>
                  </th>
                  <th className='textAlign'>
                    <div>{sevenName}</div>
                    <div>{sevenDate}</div>
                  </th>
                  <th colSpan={1} className='textAlign'>
                   Item Count
                  </th>
                  <th colSpan={1} className='textAlign'>
                    Boxes Count
                  </th>
                </tr>
              </thead>
              <tbody>
                {finalData &&
                  finalData.map((item, index) => {
                    count = 0
                    return (
                      <tr className='border' key={item}>
                        {' '}
                        <td
                          className='textAlign'
                          style={{ textDecoration: 'underline' }}
                          key={index}
                        >
                          {item.name.toUpperCase()}
                        </td>
                        <td className='textAlign' key={index}>
                          {item.service.toUpperCase()}
                        </td>
                        {item.shipments.map((shipment, sindex) => {
                          return shipment.check === true ? (
                            <td className='textAlign'>
                              <Badge
                                pill
                                style={{ backgroundColor: '#2acc97' }}
                              >
                                Scheduled
                              </Badge>
                            </td>
                          ) : (
                            <td className='textAlign'>
                              <Badge
                                pill
                                style={{ backgroundColor: '#2acc97' }}
                              ></Badge>
                            </td>
                          )
                        })}
                        <td className='textAlign' key={index}>
                          {item.count}
                        </td>
                        <td className='textAlign' key={index}>
                          {item.shipCount}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </Table>
          )}
        </Row>
      </div>
    </div>
  )
}

export default UPS
