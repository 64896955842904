import React, { useState, useEffect } from "react";
// import Tabs from 'react-bootstrap/Tabs'
// import Tab from 'react-bootstrap/Tab'
import Select from "react-select";
import { useDispatch } from "react-redux";
import { PlaidLink } from "react-plaid-link";
import moment from "moment";
import {
  Grid,
  Row,
  Col,
  DropdownButton,
  Button
  // Dropdown,
  // DropdownButton,
  // MenuItem,
} from "react-bootstrap";
// import XLSX from "sheetjs-style";
// import Modal from "react-bootstrap-modal";
// const cloneDeep = require("lodash.clonedeep");
// import cloneDeep from "lodash.clonedeep";

import { Card } from "components/Card/Card.jsx";

import { plaidServices } from "services/plaid";
import {
  objectsConstants as OC,
  // shipmentObj,
  // shipmentObject,
  // shipmentObjectItems,
} from "redux/constant/objects-constant";


import { alertActions } from "redux/actions/alert-actions";
import AlertModel from "./components/AlertModel";

import { Table } from "react-bootstrap";
import { element } from "prop-types";



const schedulerDateDefault = moment()
  .subtract(7, "day")
  .format("YYYY-MM-DD");


function BankAndcards(props) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [banknames, setBankNames] = useState();
  const [bankId, setBankId] = useState();
  const [AccountId, setAccountId] = useState();
  const [defaultOption, setDefaultOption]= useState({access_token:"1",bank_name:"all"});
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertaccount, setaccountShowAlert] = useState(false);
  const [showAlertEditAccount, setEditAccountShowAlert] = useState(false);
  const [accountSchedulerDate, setAccountSchedulerDate] = useState({
    schedulerDate: schedulerDateDefault
  });
  const [state, setState] = useState({
    cardLink: null,
    publicTokenObject: null,
    accessToken: localStorage.getItem("accessToken"),
    banks: {},
    types:[{
        id:'credit',
        label:'Credit Card'
    },{
        id:"debit",
        label:"Debit Card"
    }
],
    bankCollapse: true,
    newBankAccounts: [],
    accountModal: { show: false, bank_id: null },
    accountDateModal: { ...OC.ACCOUNTDATE },
    selectedNewBankAccounts: [],
    publicTokenObjectUpdated: false,
  });
  const [publicTokenObject, setPublicTokenObject] = useState(null);
  const [openToogle, setOpenToogle] = useState();


useEffect(() => {
    plaidServices.getLinkPlaid("credit_card").then((response) => {
      getAccountsStore(response.link.link_token);
    });
  }, []);

  function getAccountsStore(cardLink, publicTokenObjectUpdated = false) {
    plaidServices.getStoreBank(data).then((response) => {
        setBankNames(response.banks);
        setState({
        ...state,
        accountModal: {
          ...state.accountModal,
          show: false,
          bank_id: null,
        },
        banks: response.banks,
        cardLink: cardLink ? cardLink : state.cardLink,
        publicTokenObjectUpdated: publicTokenObjectUpdated,
      });
    });
  }


  useEffect(() => {
    if (state.publicTokenObjectUpdated) {
      setPublicTokenObject(null);
      plaidServices
        .exchangeToken({ publicTokenObject: state.publicTokenObject })
        .then((res) => {
          getAccountsStore(null, false);
        });
    }
  }, [state]);

  useEffect(() => {
    plaidServices.getBankAccounts();
  }, []);



  const deleteBank = (id) => {
    setShowAlert(true);
    setBankId(id);

  };

  const deleteAccount = (id) => {
    setaccountShowAlert(true);
    setAccountId(id);


  };

  const toggleMenu = (accountId)=>{
	if(openToogle != accountId) {
		setOpenToogle(accountId);
	} else{
		setOpenToogle(null);
	}
  }

  const editAccount = async () => {
	let scheduler_date = schedulerDateDefault;
	const response = await plaidServices.getTransactionsSchedulerDate()
	if(response && response.status === 'success' && response.data !== null) {
		scheduler_date = response.data.scheduler_date;
	}

    setAccountSchedulerDate({
      schedulerDate: scheduler_date
    })
    setEditAccountShowAlert(true);
  }

  const handleConfirm = async () => {
	try {
		const response = await plaidServices.deteleBank(bankId)
		if(response && response.status === 'success') {
			dispatch(alertActions.success(response.message));
			getAccountsStore();
		}

		if(response.status == 400) {
			dispatch(alertActions.error(response.message));
			return;
		}
	} catch (error) {
		dispatch(alertActions.error('Something went wrong!'));
	}
  }

  const handleConfirmAccount = async () => {
	try {
		const response = await plaidServices.deteleBankAccount(AccountId)
		if(response && response.status === 'success') {
			dispatch(alertActions.success(response.message));
			getAccountsStore();
		}

		if(response.status == 400) {
			dispatch(alertActions.error(response.message));
			return;
		}
	} catch (error) {
		dispatch(alertActions.error('Something went wrong!'));
	}
  }

  const handleAccountSchedulerDate = async () => {
    try {
      const response = await plaidServices.updateTransactionsSchedulerDate(accountSchedulerDate)
      if(response && response.status === 'success') {
        setEditAccountShowAlert(false);
        dispatch(alertActions.success(response.message));
      }

      if(response.status == 400) {
        dispatch(alertActions.error(response.message));
        return;
      }
    } catch (error) {
      dispatch(alertActions.error('Something went wrong!'));
    }
  }

  const { cardLink, banks, bankCollapse } = state;

  const onSuccess = (token, metadata) => {
    setPublicTokenObject(state.publicTokenObject);
    setState({
      ...state,
      publicTokenObject: metadata,
      publicTokenObjectUpdated: true,
    });
  };

  const handleChange = (selectedOptionType, info) => {
   let found  = banknames.find((f)=> f.bank_name === 'all');
   if(!found){
    let obj ={bank_name:"all", access_token:"1"}

    setBankNames([obj,...banknames])
   }

    let payload = {
        name:selectedOptionType.bank_name
    }

    plaidServices.searchBank(payload).then((response) => {
        setState({
        ...state,
        accountModal: {
          ...state.accountModal,
          show: false,
          bank_id: null,
        },
        banks: response.banks,
        cardLink: cardLink ? cardLink : state.cardLink,
        publicTokenObjectUpdated: false,
      });
    });

  };

  // const handleSubmit = (publicTokenObjectUpdated = false) => {
  //     let payload = {
  //         // name: bank,
  //         // type
  //     };

  //     plaidServices.searchBank(payload).then((response) => {

  //       setState({
  //       ...state,
  //       accountModal: {
  //         ...state.accountModal,
  //         show: false,
  //         bank_id: null,
  //       },
  //       banks: response.banks,
  //       cardLink: cardLink ? cardLink : state.cardLink,
  //       publicTokenObjectUpdated: publicTokenObjectUpdated,
  //     });
  //   });


  // }


  return (
    <div>
      <Grid fluid>
        <Row>
          <Col>
            <Card
              ctTableResponsive
               bsClass={["innerCard relativeCard"]}
               collapsHandler={() =>
                 setState({ ...state, bankCollapse: !state.bankCollapse })
               }
               collapseTrigger={bankCollapse}
              //  collapsable={true}
              content={
                <div >
                  <Grid fluid>
                    <Row>
						<Col>
							<div className="banksLi">
								<div className="secHeading">
									Banks & Cards &nbsp;&nbsp;
									<span className="fa fa-bank"></span>
									<Button
										className="btn-sm mt-md ml-md bankBtn"
										style={{ float: "right", fontSize: "15px", fontWeight: "300" }}
										size="sm"
										onClick={() => {editAccount ()}}
									>
										<span className="fa fa-calendar" style={{ marginRight: "5px" }}>&nbsp;</span>
										Add Scheduler Date{" "}
									</Button>
									{cardLink && (
									<PlaidLink
										token={cardLink}
										onSuccess={onSuccess}
										className="btn-sm mt-md ml-md bankBtn"
										style={{ float: "right", fontSize: "15px", fontWeight: "300" }}
									>
										<span className="fa fa-plus">&nbsp;</span>
										Add Bank{" "}
									</PlaidLink>
									)}
								</div>
							</div>
						</Col>
						<Col>
							<ul className="bankLst">
								<li>
									<div className="form-group">
									<label htmlFor="email">Select Bank</label>
									<Select
										getOptionLabel={(option) => option.bank_name}
										getOptionValue={(option) => option.access_token}
										className="new-font-size"
										style={{ borderRadius: "5px" }}
										options={banknames}
										onChange={handleChange}
										// placeholder="Select Bank Account"
										name="selectedBank"
										// isClearable={true}
									/>
									</div>
								</li>
							</ul>
						</Col>

						<Table striped bordered hover >
                            <thead>
								<tr style={{backgroundColor:"c8d5df"}}>
								<th>Bank Name</th>
								<th>Account Name</th>
								<th>Account Type</th>
								<th>Available Balance</th>
								<th>Current Balance</th>
								<th>Payment Date</th>
								<th>Closing Date</th>
								<th>Action</th>
								</tr>
                            </thead>
                            <tbody>
                                { banks.length > 0 && banks.map((bank, id) => ( <>
									{bank.accounts && bank.accounts.map((account, c) => {
                              			return (
                                			<React.Fragment key={account.id}>
                                    			<tr>
                                      				{c === 0 ? (
                                        				<td>{bank.bank_name} </td>
                                      				) : (
														<td></td>
													)}
													<td>{account.name}</td>
													<td>{account.type} </td>
													<td>
														{`${
															account.available
															? account.available
															: 0
														}`}
													</td>
                                      				<td>
														{`${
															account.current ? account.current : 0
														}`}
													</td>
													<td>
														{`${
															account.payment_date
															? moment(account.payment_date).format(
																"MM/DD/YY"
																)
															: "--"
														}`}
													</td>
													<td>
														{`${
															account.closing_date
															? moment(account.closing_date).format(
																"MM/DD/YY"
																)
															: "--"
														}`}
													</td>
													<td>
														<ul
															style={{
																listStyle: "none",
																display: "flex",
															}}
														>
															<li >
																<DropdownButton

																	noCaret
																	onToggle={() => toggleMenu(account.id)}
																	open={account.id === openToogle}
																	pullRight
																	role="menu"
																	className="btn headingBtn tab_btn"
																	id="dropdown-no-caret"
																	title={
																		<span
																			className="fa fa-ellipsis-v"
																			style={{ width: '45px' }}
																		>
																			&nbsp;&nbsp; Menu
																		</span>
																	}
																	style={{
																		width: '90px',

																	}}
																>
																	<label
																		className="btn menu"
																		id="dropdown-autoclose-inside"
																		onClick={() => deleteAccount(account.id)}
																	>
																		{" "}
																		<span style={{ color: "black" }}>
																			Delete Account
																		</span>
																	</label>

																	{c === 0 && account.name != 'Manual' ? (
																		<label
																			className="btn menu"
																			id="dropdown-autoclose-inside"
																			onClick={() => deleteBank(bank.id)}
																		>
																			{" "}
																			<span style={{ color: "black" }}>
																			{" "}
																				Delete Bank
																			</span>
																		</label>
																	) : (
																		<label></label>
																	)}
																</DropdownButton>
															</li>
														</ul>
													</td>
                                    			</tr>
                                			</React.Fragment>
                             			);
                            		})}
                            	</>
                            ))}
                            </tbody>
                        </Table>
                    </Row>
                  </Grid>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>

      <AlertModel
        show={showAlert}
        onHide={() => setShowAlert(false)}
        onConfirm={handleConfirm}
        heading="Delete Bank"
        alert="Are you sure to delete this bank"
      />
      <AlertModel
        show={showAlertaccount}
        onHide={() => setaccountShowAlert(false)}
        onConfirm={handleConfirmAccount}
        heading="Delete Account"
        alert="Are you sure to delete this account"
      />
      <AlertModel
        show={showAlertEditAccount}
        onHide={() => setEditAccountShowAlert(false)}
        onConfirm={handleAccountSchedulerDate}
        heading="Transactions scheduler date"
        buttonTextFlag = {true}
        accountSchedulerDate = { accountSchedulerDate }
        setAccountSchedulerDate = { setAccountSchedulerDate }
      />
    </div>
  );

}


export default BankAndcards;





