import React, { useState } from 'react'
import { Document, Page, } from 'react-pdf/dist/esm/entry.webpack';
import Modal from 'react-bootstrap-modal'
import { ThreeDots } from 'react-loader-spinner'
import "./styles.css"
// import { Viewer } from '@react-pdf-viewer/core';

const PdfViwer = ({ pdfFile,
  open,
  setOpen }) => {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(
      pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
    );

  const loadingContent = () => {
    return (
      <>
        <div className='pdf-modal'>
          <span> Please wait....</span>
          <ThreeDots
            height="50"
            width="100"
            radius="10"
            color='rgb(52 132 224 / 59%)'
            ariaLabel='three-dots-loading'
            wrapperStyle
            wrapperClass
          />
        </div>
      </>
    )
  }


  return (

    <Modal
      backdrop={'static'}
      show={open}
      onHide={() => setOpen(false)}
      aria-labelledby='ModalHeader'
      dialogClassName='fullscreenmodal invoice-modal-content'
    >
      <Modal.Header closeButton>
        <Modal.Title
          id='ModalHeader'
          className='headerTitle'
        // style={{ width: 'fit-content' }}
        ></Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div style={{ padding: '0px 100px 0px 120px' }}>
        
          {numPages && numPages > 1 &&
            <nav>
              <button onClick={goToPrevPage} className='prev-btn' >Prev</button>
              <button onClick={goToNextPage} className='next-btn' >Next</button>
              <p className='pdf-pages-text'>
                Page {pageNumber} of {numPages}
              </p>
            </nav>
          }

          <Document
            file={pdfFile}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={loadingContent}
          >
            <Page pageNumber={pageNumber}
            />
          </Document>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PdfViwer;