import React, { Component } from "react";
import ReactTable from "react-table";
import { Col,Row,InputGroup } from "react-bootstrap";

import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import ReactDataToCSV from "components/ThemeComponents/reactDataToCSV";
// import classNames from "classnames";

import "react-table/react-table.css";
import { CloudWatchEvents } from "aws-sdk";

var cloneDeep = require("lodash.clonedeep");

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sorted: "",
      filtered:  "",
      pageSize: this.props.pageSize || 10,
      updateData:[],
      total: 0,
      page: 0,
      data: [],
      pages: 0,
      showAll: false,
      selectAll: false,
      checkedIds: [],
      shiftKey: false,
      lastIndex:null
    };
  }
  dataCall = (
    page,
    filtered = this.state.filtered,
    sorted = this.state.sorted,
    pageSize = this.state.pageSize
  ) => {

    page = page || this.state.page;
    var data = {
      filter: filtered,
      pageSize: this.state.showAll ? this.state.total : pageSize,
      sort: sorted,
      page: this.state.showAll ? 0 : page,
    };
if( this.props.getDataCall){
  this.props.getDataCall(data).then((response) => {
    // console.log(`response is, `, response.data)
    this.setState({
      data: response.data.pagination ? response.data.pagination.data : response.data.data,
      pages: response.data.pagination ? response.data.pagination.last_page : response.data.last_page,
      page: response.data.pagination ? response.data.pagination.current_page : response.data.current_page,
      total: response.data.pagination ? response.data.pagination.total : response.data.total,
      selectAll: false,
    });

  });
}


  };

  paginationStateData = () => {
    return { ...this.state };
  };
  filterSearchHandle = (e) => {
    this.setState({
      filtered: e.target.value,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showAll !== this.state.showAll) {
      this.dataCall();
    }
    if (this.state.filtered !== prevState.filtered) {
      this.dataCall();
    }
    if(this.state.checkedIds !== prevState.checkedIds){
      this.props.selectedIds && this.props.selectedIds(this.state.checkedIds)

    }
    if(this.props.searchValue !== undefined && this.props.searchValue !== prevProps.searchValue){
     this.setState({
        filtered: this.props.searchValue ,
      });
    }
    if(this.props.ids  && this.props.ids !== prevProps.ids){
      this.setState({
        checkedIds: this.props.ids
      })
    }
	if( this.props.updatedData && this.props.updatedData !== prevProps.updatedData){
		this.setState({
			data: this.props.updatedData.data.data,
			pages:  this.props.updatedData.data.last_page,
			page: this.props.updatedData.data.current_page,
			total: this.props.updatedData.data.total,
		})
	}
    if (
      this.state.checkedIds !== prevState.checkedIds &&
      this.props.detectChanges
    ) {
      this.props.detectChanges(
        this.state[this.props.detectChangesAttr],
        this.props.detectChangesFlag
      );
    }
  }
  unique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  handleChange = () => {
    const checkedIdsCopy = cloneDeep(this.state.checkedIds);
    const dataCopy = cloneDeep(this.state.data);
    // const oldIdsLength = checkedIdsCopy.length - dataCopy.length;
    const currentDataIds = dataCopy.map((x) => x[this.props.multiSelector]);
    var difference = checkedIdsCopy.filter(
      (x) => currentDataIds.indexOf(x) === -1
    );

    this.setState({
      checked: new Array(dataCopy.length).fill(!this.state.selectAll),
      selectAll: !this.state.selectAll,
      checkedIds: this.state.selectAll
        ? difference
        : [
            ...checkedIdsCopy,
            ...dataCopy.map((x) => x[this.props.multiSelector]),
          ].filter(this.unique),
    });

  };
  handleSingleCheckboxChange = (index, id) => {
    var idsCopy = cloneDeep(this.state.checkedIds);
    if(index>=0){
      this.setState({lastIndex: index})
    }

  // if(this.state.shiftKey === false){
    if (Object.values(this.state.checkedIds).indexOf(id) >= 0) {
      this.setState({ selectAll: false });
      var removeIndex = idsCopy.indexOf(id);
      idsCopy.splice(removeIndex, 1);
    } else {
      idsCopy = [...idsCopy, id];
    }
    this.setState(
      {
        checkedIds: idsCopy,
      },
      () => {
        // console.log("checkedIds single", this.state.checkedIds);
      }
    );

  // }






  };



  handleClick=(index)=>{

        const dataCopy = cloneDeep(this.state.data);
        let selectedData  = dataCopy.slice(this.state.lastIndex,index+1);
        const currentDataIds = selectedData.map((x) => x[this.props.multiSelector]);
        this.setState({
          checked: new Array(selectedData.length).fill(!this.state.selectAll),
          checkedIds: [
                ...selectedData.map((x) => x[this.props.multiSelector]),
              ].filter(this.unique),
        });



    //  }

  }


  render() {
    const { showAll, data, pages, filtered, pageSize } = this.state;
    const {
      showAllToggle,
      columns,
      loadingOrder,
      filterView,
      filterViewForFilter,
      upperContent,
      lowerContent,
      filterContent,
      filterPlaceHolder,
      downloadData,
      minRows,
      downloadFileName,
      style,
      showTotal,
      selectMulti,
      SubComponent,
      onExpandedChange,
      expanded,
      //checkIfRefund,
      // expandableRowDisabled,
    } = this.props
    // console.log('columns', columns);


    const multicolumns = [
      {
        Header: (state) => (
          <div style={{ fontSize: "14px" }}>
            <input
              type="checkbox"
              onChange={() => this.handleChange(state.sortedData)}
              checked={this.state.selectAll}
            />
          </div>
        ),
        Cell: (row) => (
          <div style={{ fontSize: "14px" }}>
            <input
              type="checkbox"
              checked={
                Object.values(this.state.checkedIds).indexOf(
                  row.original[this.props.multiSelector]
                ) >= 0
                  ? true
                  : false
              }
              onChange={

                (e) =>
                {
                  if(e.shiftKey){
                    this.handleClick(row.index)
                  }else{
                    this.handleSingleCheckboxChange(
                      row.index,
                      row.original[this.props.multiSelector]
                    )
                  }

                }

              }



            />
          </div>
        ),
        sortable: false,
        filterable: false,
        maxWidth: 50,
      },
    ];
    return (
      <React.Fragment>
        <Col
          md={12}
          className="mt-md"
          style={{ fontSize: "14px", justifyContent: "start" }}
        >
          {/* {filterContent && filterContent} */}
          {showTotal && (
            <p>Your query resulted in a total of {this.state.total}.</p>
          )}
          {/* {upperContent && upperContent} */}
          {filterViewForFilter && (
            <>
              <Col md={2} sm={12} xs={12} lg={2}>
                <label htmlFor="email">Search</label>
                {/* <InputGroup> */}
                <FormInputs
                  ncols={["col-md-12"]}
                  onChange={this.filterSearchHandle}

                  className="fieldGroup"
                  proprieties={[
                    {
                      type: "text",
                      bsClass: "form-control fieldGroup ",
                      placeholder: `${
                        filterPlaceHolder ? filterPlaceHolder : ""
                      }`,


                      onChange: this.filterSearchHandle,
                      name: "filter",
                    },
                  ]}

                />
                {/* <span className="fa fa-search srchIcon" ></span> */}
                {/* </InputGroup> */}



              </Col>
              {/* <Col md={3} sm={6} xs={12}>
                {filterContent && filterContent}
              </Col> */}


            </>
          )}
          {upperContent && upperContent}


          {selectMulti && (
                 <Col>
                 {/* <Col md={2} style={{ fontSize: "14px" }}> */}
                   {/* <ul className="lsRight">
                     <li style={{marginRight:'10px'}}>
                     <input
                    type="checkbox"
                    onChange={() => this.handleChange(this.state.sortedData)}
                    checked={this.state.selectAll}
                  />
                     </li>
                     <li>
                      Select All
                       </li>
                       <li>
                      </li>
                     </ul> */}



                 {/* </Col>   */}

                 {/* <Col md={2} style={{ fontSize: "14px",  }}>


                 </Col>    */}
                 {/* <Col md={4} style={{ fontSize: "14px",  }}>

                   &nbsp;
                </Col>    */}
                {/* <Col md={4} lg={4}  style={{ fontSize: "14px", textAlign:'right' }}>
                  <ul className="statusLst">
                    <li style={{marginRight:'5px',marginTop:'4px'}}>
                    <div style={{ background: "black",borderRadius:'3px',display:'inline-block',height:'12px',width:'12px'}}>&nbsp;</div>
                    </li>
                    <li style={{marginRight:'15px'}}>
                      Posted
                      </li>
                      <li style={{marginRight:'5px',marginTop:'4px'}}>
                    <div style={{ background: "blue",borderRadius:'3px',display:'inline-block',height:'12px',width:'12px'}} >&nbsp;</div>
                    </li>
                    <li>
                      Pending
                      </li>
                    </ul>


               </Col>  */}

                 </Col>

          )}




          {filterView && (
            <Col md={4} sm={6} xs={12}>
              <label htmlFor="email">Search:</label>
              <FormInputs
                ncols={["col-md-12"]}
                onChange={this.filterSearchHandle}
                proprieties={[
                  {
                    type: "text",
                    bsClass: "form-control",
                    placeholder: `Search ${
                      filterPlaceHolder ? filterPlaceHolder : ""
                    }`,
                    onChange: this.filterSearchHandle,
                    name: "filter",
                  },
                ]}
              />
            </Col>
          )}
           {/* {upperContent && upperContent} */}
          {lowerContent && lowerContent}

          {showAllToggle && (
            <React.Fragment>
              <Col md={1} className="pull-right text-right pl-xs">
                <label
                  className="container-check mb-none"
                  style={{ marginTop: "10px", paddingLeft: "28px" }}
                >
                  {" "}
                  Show All{" "}
                  <input
                    type="checkbox"
                    name="is_default"
                    className="checkbox"
                    onChange={() => {
                      this.setState({ showAll: !showAll });
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
              </Col>
            </React.Fragment>
          )}
          {downloadData && (
            <React.Fragment>
              <Col md={2} className="mt-sm downloadCsv text-right pull-right">
                <ReactDataToCSV
                  className="downloadbtn"
                  fileName={downloadFileName}
                  currentRecordsRef={this.reactTable}
                  columnsData={columns[0].columns}
                />
              </Col>
            </React.Fragment>
          )}
          {/* <Col>
            &nbsp;
          </Col> */}
          <Col md={12} className="mt-md">
            <ReactTable
              minRows={minRows ? minRows : 5}
              showPagination={showAll ? false : true}
              ref={(r) => (this.reactTable = r)}
              trProps={this.props.getRowProps}
              noDataText={this.props.noDataText || "No records found"}
              data={data }
              pages={pages}
              columns={selectMulti ? [...multicolumns, ...columns] : columns}
              filtered={filtered}
              pageSizeOptions={this.props.pageSizeOptions}
              defaultPageSize={pageSize}
              pageSize={pageSize}
              loading={loadingOrder}
              defaultSorted={this.props.defaultSorted || []}
              onPageSizeChange={(pageSize) => {
                this.setState({
                  pageSize: pageSize,
                });
              }}
              // style={{
              //   height: window.innerHeight,
              // }}
              className='-striped -highlight'
              // className="-striped listing"
              loadingText={"Loading..."}
              pageData={this.dataCall}
              manual
              // page={pages}a
              // autoResetPage={true}
              onFetchData={(state, instance) => {
                var sort =
                  state.sorted.length === 0
                    ? ""
                    : state.sorted[0].id + ",desc:" + state.sorted[0].desc;
                state.pageData(
                  state.page + 1,
                  state.filtered,
                  sort,
                  state.pageSize
                );
              }}
              getTrProps={(state, rowInfo, columns, instance) => {

                let data = {};
                if (instance.props.trProps) {
                  // console.log('instance', instance.props.trProps);
                  if (rowInfo !== undefined) {
                    const rowData = rowInfo.original;
                    // console.log('rowData', rowData);
                    data = instance.props.trProps(rowData);
                  }
                }
                return  {
					data,
					style: {
						color: rowInfo && rowInfo.original && rowInfo.original.is_latest_updated === true ? 'rgb(0, 110, 235)' : ''
					}
				}
              }}
              SubComponent={SubComponent}
              onExpandedChange={onExpandedChange}
              expanded={expanded}
              isExpanded={false}
            />
            {selectMulti ?  <div className='pagination-bottom' style={{marginTop:'10px',display:'flex',justifyContent:'flex-end'}}>
              <span className='selected_rows'>
                Selected Rows:{' '}
                {this.state.checkedIds.length}
              </span>
            </div> : null}

          </Col>
        </Col>
      </React.Fragment>
    );
  }
}

export default Pagination;
