export const shipmentObject = {
         id: '',
         new: true,
         transactions_items_id: '',
         courier: '',
         tracking_number: '',
         items: [],
         status: null,
       }
export const shipmentObjectItems = {
         id: '',
         transactions_items_id: '',
         courier: '',
         tracking_number: '',
         qty_expected: null,
         qty_received: null,
         qty_damaged: null,
         status: null,
         qty_missing: null,
         inventory_id:null
       }
export const shipmentObj = {
  id: '',
  transactions_items_id: '',
  courier: '',
  tracking_number: '',
  qty_expected: null,
  qty_received: null,
  qty_damaged: null,
  status: null,
  qty_missing: null,
}
export const objectsConstants = {
  ACCOUNTDATE: {
    show: false,
    account_id: null,
    closing_date: '',
    payment_date: '',
  },
  INVENTORY: {
    name: '',
    // qty: "",
    in_bound: '0',
    on_hand: '0',
  },
  TRANSACTIONDETAIL: {
    qty: '',
    price: '',
    taxes: '',
    shipping: '',
    row_status: '',
    image: '',
    qty_ordered: null,
    qty_missing: null,
    qty_damaged: null,
    order: {
      status: '',
      sub_status:'',
      order_number: '',
      tracking_number: '',
      shipping_address: '',
      zipcode: '',
      city: '',
      state: '',
    },
    vendor: {
      email: '',
    },
    inventory_id: '',
    new: true,
    shipments: [{ ...shipmentObj }],
  },
  VALIDATIONORDER: {
    status: '',
    order_number: '',
    tracking_number: '',
    shipping_address: '',
    zipcode: '',
    city: '',
    state: '',
    email: '',
  },
}
