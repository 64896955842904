
import moment from "moment";

const transformLog = (log) => {
  return [

    moment(log.timestamp).format("MMMM Do YYYY, h:mm:ss"),
    `${log.action}`,
    `${log.module}`,
    log.status ? log.status : '------',
    `${log.changes[0]}`,
    log.userName

  ]
}

const transaformData = (data) => {

  let resultantArr = []
  data.forEach(item => {
    let logsArray = transformLog(item)
    resultantArr.push(logsArray)
  });
  return resultantArr
}

const headings = [
  'Time Stamp',
  'Action',
  'Module',
  'Status',
  'Description',
  'User Name'
];


const createData = (formattedData, header) => {
  return [['Logs Details!'], header, ...formattedData]
}



const formattedLogData = (data) => {
  const formatingdata = transaformData(data)

  const res = createData(formatingdata, headings)

  return res
}

export { formattedLogData }