import React from "react";
import Modal from 'react-bootstrap-modal'

const AdminSupportModal = ({openModal, handleClose}) => {
return (
		<Modal
			className='info-message-support'
			backdrop={'static'}
			show={openModal}
			onHide={handleClose}
			aria-labelledby='ModalHeader'
			dialogClassName='fullscreenmodal'
      	>
			<Modal.Header closeButton></Modal.Header>
			<h2>Please contact support</h2>
		</Modal>
	)
}

export default AdminSupportModal;