import React from "react";
import { Table as Tablee } from "react-bootstrap";

const Table = ({ body }) => {
  return (
    <>
      <Tablee responsive>
        <thead>
          <tr>
            <th className="text-dark">
              <strong>DATE</strong>
            </th>
            <th className="text-dark">
              <strong>CARD</strong>
            </th>
            <th className="text-dark">
              <strong>NAME</strong>
            </th>
            <th className="text-dark">
              <strong>AMOUNT</strong>
            </th>
            <th className="text-dark">
              <strong>Transaction STATUS</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          {body.map((item, i) => (
            <tr key={i}>
              <td>{item.date}</td>
              <td>{item.account.official_name}</td>
              <td>{item.name}</td>
              <td>${item.amount}</td>
              <td>{item.status}</td>
            </tr>
          ))}
        </tbody>
      </Tablee>
    </>
  );
};

export default Table;
