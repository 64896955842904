
import moment from "moment";

const transformLog = (log) => {
  return [
    log.order_number ? `${log.order_number}` : '',
    log.tracking_number ? `${log.tracking_number}` : '',
    `${log.createdAt}`,
	log.received_date ? `${log.received_date}` : '',
    `${log.inventory_name}`,
    log.total_price ? `${log.total_price}` : '',
    `${log.total_quantity}`,
    `${log.total_amount}`,
    `${log.vendor_name}`,
	log.card_no ? `${log.card_no}` : 'Manual0000',
  ]
}


const transaformData = (data) => {

  let resultantArr = []
  data.forEach(item => {
    let logsArray = transformLog(item)
    resultantArr.push(logsArray)
  });
  return resultantArr
}

const headings = [
  'Order Number',
  'Tracking Number',
  'Creation Date',
  'Receiving Date',
  'Item Name',
  'Total Price',
  'Total Quantity',
  'Total Amount',
  'Vendor Name',
  'Card Number',
];


const createData = (formattedData, header) => {
  return [['Inventory Report Details!'], header, ...formattedData]
}



const formatInventoryReport = (data) => {
  const formatingdata = transaformData(data)

  const res = createData(formatingdata, headings)

  return res
}

export { formatInventoryReport }