import { SC } from "../helper/ServerCall";

export const alertService = {
  errorNotify,
};

function errorNotify(error) {
  // console.log('error', error);
  return SC.postCall("errorNotify", error);
}
