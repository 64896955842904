import moment from "moment";

const transactionFormat = (chunked) => {
  let data = chunked.slice(2);
  let headings = chunked[1];

  // get start and end points of headings
  let orderHeadEnd = headings.findIndex((i) => i === 'Gift_Card_1');
  let giftHeadStart = orderHeadEnd;
  let giftHeadEnd =  headings.findIndex((i) => i === 'item name');
  let itmHeadStrt =  giftHeadEnd;
  let itmHeadEnd =  headings.findIndex((i) => i === 'Courier');
  let shpHeadStrt =  itmHeadEnd;

  // separate the headings
  /* let orderHeading = headings.slice(0, orderHeadEnd - 1);
  let itemHeading = headings.slice(itmHeadStrt, itmHeadEnd - 1);
  let shipmentHeading = headings.slice(shpHeadStrt,data.length); */


let a = []
  for (let i = 0; i < data.length; i++) {
    let order = {}
    let giftCards=[]
    let items = []
    let shipments = []
    let vendor = {}
    let email ={}
    let transaction_type = {}
    let transaction_state = {}
    let o = {new:true, order, giftCards, vendor,email, items, shipments,transaction_type, transaction_state }
    let row = data[i]
    if (row.length > 0) {
      for (let j = 0; j < orderHeadEnd -1 ; j++) {
        o.amount = row[0]
        o.name = row[1]
        o.date = row[2]? moment( new Date(row[2])).add(1,'hour').format('YYYY-MM-DD'):''
		o.card_no = row[3]
        o.order.order_number = row[4]
        o.order.shipping_address = row[5]
        o.email = row[6]
        o.order.status = row[7]
        o.order.taxes= row[8]
        o.order.shippingNo= row[9]

      }

      // giftCards
      for(let g = giftHeadStart; g <= giftHeadEnd-1; g++){
         let giftcard = {}
         giftcard["card_no"]= row[g]
         giftcard["useAmount"]= row[g+1]
         g=g+1;
         if(giftcard.card_no !== '') giftCards.push(giftcard)
      }


      let v = itmHeadStrt;
        for (let k = 0; k < 7; k++) {
          let b = { new: true }
          // b.name = row[v++].replaceAll(' ','').toLowerCase()
          b.name = row[v++].trim()
          b.qty_ordered = row[v++]
          b.price = row[v++]
          // b.taxes = row[v++]
          // b.shipping = row[v++]
          if (b.qty_ordered === '') b.qty_ordered = 0
          if (b.price === '') b.price = 0
          // if (b.taxes === '') b.taxes = 0
          // if (b.shipping === '') b.shipping = 0
          if (b.name !== '') o.items.push(b)
        }

      let shipmentIndex = shpHeadStrt;
       for(let i = shipmentIndex; i < row.length;  i){
         let c = {new :true};

         c.courier = row[i++];
         c.tracking_number = row[i++]
         c.items = [];
         for(let j=0; j< 7; j++){
             let obj= {};
            //  obj["name"]= row[i] ? row[i].replaceAll(' ','').toLowerCase() : '';
             obj["name"]= row[i] ? row[i].trim() : '';
             i++;
             obj["qty_expected"] = row[i] ? parseInt(row[i]) : ''
             i++;
             if(obj.name !== '') c.items.push(obj)
            }
         if(c.courier !== '' && c.tracking_number !== '' ) o.shipments.push(c)
       }
       if(o.name != '' && o.amount !== '' ) a.push(o)


    }
  }
  return a


};

export { transactionFormat}