import { SC } from "../helper/ServerCall";

export const inventoryServices = {
  getInventory,
  getInventoryPagination,
  getSingleInventory,
  addInventory,
  updateInventory,
  getInventoryReport,
};

function getInventory(data) {
  return SC.postCall(`getInventories`, data);
}

function getInventoryPagination(page, filter, sort, pageSize) {
  return SC.getCall(
    "inventories?page=" +
      page +
      "&filter=" +
      filter +
      "&sort=" +
      sort +
      "&pageSize=" +
      pageSize
  );
}
function getSingleInventory(id) {
  return SC.getCall(`inventories/${id}`);
}

function addInventory(data) {
  return SC.postCall(`inventories`, data);
}

function updateInventory(data, id) {
  return SC.putCall(`inventories/${id}`, data);
}

function getInventoryReport(data) {
	return SC.postCall(`get-inventory-received`, data);
  }
