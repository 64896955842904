import React from "react";
import { Button, Collapse } from "react-bootstrap";

import { s3StaticPath } from 'helper/helperFunctions'

export const Card = (props) => {

  return (
    <div className={"card" + (props.plain ? " card-plain" : "") + " " + (props.bsClass ? props.bsClass.join(' ') : "")}>
      {
        props.collapsable ?

          <React.Fragment>
            {
              ((props.title && props.title !== '') || (props.category && props.category !== '')) ?
                <div className={"header" + (props.hCenter ? " text-center" : "")}>
                  <h4 className={"title headerTitle " + (props.collapseTrigger ? "" : "collapsClosed")}>{props.title}
                    <Button
                      className="arrow_toggle"
                      onClick={props.collapsHandler}
                      aria-controls="example-collapse-text"
                      aria-expanded={props.collapseTrigger}
                    >
                      {props.collapseTrigger ? <img src={s3StaticPath('img/arrow_down.png')} alt="Arrow" className="arrow_down" /> : <img src={s3StaticPath('img/arrow_down.png')} alt="Arrow" className="arrow_up" />}
                    </Button>
                  </h4>
                  <p className="category">{props.category}</p>
                </div>
                :
                <div className={"header" + (props.hCenter ? " text-center" : "")}>
                  <h4 className={"title headerTitle " + (props.collapseTrigger ? "" : "collapsClosed")}>
                    &nbsp;
                    <Button
                      className="arrow_toggle"
                      onClick={props.collapsHandler}
                      aria-controls="example-collapse-text"
                      aria-expanded={props.collapseTrigger}
                    >
                      {props.collapseTrigger ? <img src={s3StaticPath('img/arrow_down.png')} alt="Arrow" className="arrow_down" /> : <img src={s3StaticPath('img/arrow_down.png')} alt="Arrow" className="arrow_up" />}
                    </Button>
                  </h4>
                  <p className="category">{props.category}</p>
                </div>
            }

            <Collapse in={props.collapseTrigger}>
              <div
                className={
                  "content" +
                  (props.ctAllIcons ? " all-icons" : "") +
                  (props.ctTableFullWidth ? " table-full-width" : "") +
                  (props.ctTableResponsive ? " table-responsive" : "") +
                  (props.ctTableUpgrade ? " table-upgrade" : "")
                }
              >
                {props.content}

                <div className="footer">
                  {props.legend}
                  {props.stats != null ? <hr /> : ""}
                  <div className="stats">
                    <i className={props.statsIcon} /> {props.stats}
                  </div>
                </div>
              </div>
            </Collapse>
          </React.Fragment>
          :
          <React.Fragment>
            {
              ((props.title && props.title !== '') || (props.category && props.category !== '')) &&
              <div className={"header" + (props.hCenter ? " text-center" : "")}>
                <h4 className="title headerTitle">{props.title}</h4>
                <p className="category">{props.category}</p>
              </div>
            }

            <div
              className={
                "content" +
                (props.ctAllIcons ? " all-icons" : "") +
                (props.ctTableFullWidth ? " table-full-width" : "") +
                (props.ctTableResponsive ? " table-responsive" : "") +
                (props.ctTableUpgrade ? " table-upgrade" : "")
              }
            >
              {props.content}

              <div className="footer">
                {props.legend}
                {props.stats != null ? <hr /> : ""}
                <div className="stats">
                  <i className={props.statsIcon} /> {props.stats}
                </div>
              </div>
            </div>
          </React.Fragment>
      }
    </div>
  );
}

export default Card;
