export const ORDER_STATUSES = [
  { value: 'Pending order', label: 'Pending order' },
  { value: 'Pending Replacement', label: 'Pending Replacement' },
   { value: 'Cancel', label: 'Cancel' },
  { value: 'Shipped', label: 'Shipped' },
  { value: 'Shipped partially', label: 'Shipped partially' },
  { value: 'Delivered', label: 'Delivered' },
  {
    value: 'Received in full',
    label: 'Received in full',
  },
  { value: 'Received partial', label: 'Received partial' },
  { value: 'Received missing', label: 'Received missing' },
  { value: 'Received damaged', label: 'Received damaged' },

  //{ value: 'Refunded', label: 'Refunded' },
  { value: 'Received/Refunded', label: 'Received/Refunded' },
  {
    value: 'canceled need a refund',
    label: 'Canceled need a refund',
  },
  // { value: 'Partial refund', label: 'Partial refund' },
]

export const ORDER_SUB_STATUSES = [
         { value: 'need attention', label: 'Need Attention' },
         { value: 'awaiting response', label: 'Awaiting Response' },
         { value: 're-shipping', label: 'Re-Shipping' },
         { value: 'pending refund', label: 'Pending Refund' },
         { value: 'need to chargeback', label: 'Need to Chargeback' },
         { value: 'declined', label: 'Declined' },
       ]

export const TRANSACTION_TYPE = [
  { value: 'inventory', label: 'Inventory' },
  { value: 'refund', label: 'Refund' },
  { value: 'business', label: 'Business' },
  { value: 'non business', label: 'Non Business' }
]

export const CARRIERS = [
  { value: 'ups', label: 'UPS' },
  { value: 'usps', label: 'USPS' },
  { value: 'dhl', label: 'DHL' },
  { value: 'cdl', label: 'CDL' },
  { value: 'fedex', label: 'FEDEX' },
  { value: 'laser ship', label: 'LASER SHIP' },
  { value: 'other', label: 'OTHER' },
]

export const ITEM_STATUS=[
  {value: 'waiting for refund', label: 'Waiting For Refund'},
  {value: 'shipping replacement', label: 'Shipping Replacement'},
  {value: 'awaiting response', label: 'Awaiting Response'},
  {value: 'chargeback', label: 'Chargeback'},
  {value: 'received missing', label: 'Received Missing'},
]
