import React, { useState, useRef } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Pagination from "components/ThemeComponents/Pagination";
import { inventoryServices } from "services/inventory";
import { dateFormatMy } from "../../../helper/helperFunctions";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap-modal";
import Form from "./form";

const Inventory = () => {
  const [addModal, setAddModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [productId, setProductId] = useState();

  const pagination = useRef();

  const columns = [
    {
      Header: "Item Name",
      accessor: "name",
      sortable: false,
    },
    // {
    //   Header: "Item Qty",
    //   accessor: "qty",
    // },
    {
      Header: "Qty Inbound",
      accessor: "in_bound",
      sortable: false,
    },
    {
      Header: "Qty on Hand",
      accessor: "on_hand",
      sortable: false,
    },
    {
      Header: "Date last Updated",
      accessor: "updated_at",
      sortable: false,
      Cell: ({ value }) => <div>{dateFormatMy(value)}</div>,
    },
    {
      Header: "Update",
      accessor: "id",
      sortable: false,
      Cell: ({ value }) => (
        <div
          onClick={() => {
            setProductId(value);
            setUpdateModal(true);
          }}
        >
          <Button bsStyle="primary" bsSize="small" style={{ border: "none" }}>
            <span>
              <i
                className="fa fa-pencil-square-o"
                style={{ fontSize: 20 }}
                aria-hidden="true"
              ></i>
            </span>
          </Button>
        </div>
      ),
    },
  ];

  const paginationCall = (data) => {
    return inventoryServices.getInventory(data);
  };

  const paginationReload = () => {
    pagination.current.dataCall();
  };

  return (
    <>
      <div>
        <Grid fluid>
          <Row>
            <Col>
              <Card
                ctTableResponsive
                content={
                  <div>
                    <Grid fluid>
                      <div className="secHeading" style={{ marginLeft: 20 }}>
                        <i
                          className="fa fa-product-hunt"
                          aria-hidden="true"
                          style={{ marginRight: 10 }}
                        ></i>
                        {"  "}
                        Product Page
                      </div>
                      <Pagination
                        ref={pagination}
                        columns={columns}
                        getDataCall={paginationCall}
                        noDataText="No Item found"
                        showPagination={true}
                        pageSize={100}
                        filterViewForFilter={true}
                        filterPlaceHolder="Search Product"
                        lowerContent={
                          <>
                            <Button
                                style={{
                                  marginTop: "26px",
                                  marginRight: "30px",
                                  float: "right",
                                  border: "white",
                                  color: "white",
                                  backgroundColor: "#2F4576",
                                }}
                                onClick={() => setAddModal(true)}
                              > Add Product
                            </Button>
                          </>
                        }
                      />
                    </Grid>
                  </div>
                }
              />
              {addModal && (
                <Modal
                  backdrop={"static"}
                  show={addModal}
                  onHide={() => setAddModal(false)}
                  aria-labelledby="ModalHeader"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="ModalHeader" className="headerTitle">
                      Add Product
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="row">
                      <div className="col-md-12">
                        <Card
                          bsClass={["innerCard mb-none"]}
                          content={
                            <Form
                              setAdd={setAddModal}
                              type="add"
                              paginationReload={paginationReload}
                            />
                          }
                        />
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
              {updateModal && (
                <Modal
                  backdrop={"static"}
                  show={updateModal}
                  onHide={() => setUpdateModal(false)}
                  aria-labelledby="ModalHeader"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="ModalHeader" className="headerTitle">
                      Update Product
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="row">
                      <div className="col-md-12">
                        <Card
                          bsClass={["innerCard mb-none"]}
                          content={
                            <Form
                              setUpdate={setUpdateModal}
                              type="update"
                              id={productId}
                              paginationReload={paginationReload}
                            />
                          }
                        />
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
            </Col>
          </Row>
        </Grid>
      </div>
    </>
  );
};

export default Inventory;
