import { adminConstants } from '../constant/admin-constant';

const initialState = {loading: false, users:{}, editUser:{}};
export function userReducer(state = initialState, action){

	switch(action.type){
		
		case adminConstants.PAGINATION_USERS_REQUEST:
			return { ...state, loading:true, users: {}, editUser:'', storedUser:'', updatedUser:'', deleteUser:false };
		case adminConstants.PAGINATION_USERS_SUCCESS:
			return { ...state, usersData:action.payload.data.pagination.data, pages:action.payload.data.pagination.last_page, loading: false, current_page:action.payload.data.pagination.current_page };
		case adminConstants.PAGINATION_USERS_FAILURE:
			return { ...state, loading:false, error : action.payload };

		case adminConstants.STORE_USER_REQUEST:
			return { ...state, loading:true, editUser:'', storedUser: '' }
		case adminConstants.STORE_USER_SUCCESS:
			return { ...state, loading:false, storedUser: action.payload.user};
		case adminConstants.STORE_USER_FAILURE:
			return { ...state, loading:false, error:action.payload };

		case adminConstants.GET_USER_REQUEST:
			return { ...state, loading:true };
		case adminConstants.GET_USER_SUCCESS:
			return { ...state, loading:false, editUser : action.payload.user };
		case adminConstants.GET_USER_FAILURE:
			return { ...state, loading:false, error : action.payload };

		case adminConstants.EDIT_USER_REQUEST:
			return { ...state, loading:true, updatedUser:'' };
		case adminConstants.EDIT_USER_SUCCESS:
			return { ...state, loading:false , updatedUser:action.payload.user};
		case adminConstants.EDIT_USER_FAILURE:
			return { ...state, loading:false, error : action.payload };

		case adminConstants.DELETE_USER_REQUEST:
			return { ...state, loading:true, deleteUser: false }
		case adminConstants.DELETE_USER_SUCCESS:
			return { ...state, loading:false, deleteUser: true};
		case adminConstants.DELETE_USER_FAILURE:
			return { ...state, loading:false, error:action.payload };

		case adminConstants.GET_USERS_ORDER_REQUEST:
			return { ...state, loading:true };
		case adminConstants.GET_USERS_ORDER_SUCCESS:
			return { ...state, loading:false, orderUsers : action.payload.users };
		case adminConstants.GET_USERS_ORDER_FAILURE:
			return { ...state, loading:false, error : action.payload };

		case adminConstants.UPDATE_USER_STATUS_REQUEST:
			return { ...state, loading:true};
		case adminConstants.UPDATE_USER_STATUS_SUCCESS:
			return { ...state, loading:false , updatedUserStatus:action.payload.user};
		case adminConstants.UPDATE_USER_STATUS_FAILURE:
			return { ...state, loading:false, error : action.payload };
			
		default:
			return { ...state };
	}
}