import React, { useState, useEffect } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Accordion from "components/ThemeComponents/Accordion";
import { useDispatch } from "react-redux";
import { vendorServices } from "services/vendor";

const VenderListing = () => {
  const dispatch = useDispatch();
  const [vendorList, setVendorList] = useState([]);

  function getVendors(data) {
    return (dispatch) => {
      return vendorServices.getVendor(data).then(
        (response) => {
          return setVendorList(response.data.vendors);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    };
  }

  useEffect(() => {
    dispatch(getVendors());
  }, []);

  return (
    <>
      <div>
        <Grid fluid>
          <Row>
            <Col>
              <Card
                ctTableResponsive
                content={
                  <div>
                    <Grid fluid>
                      <div className="secHeading">
                        <i
                          className="fa fa-list"
                          aria-hidden="true"
                          style={{ marginRight: 10 }}
                        ></i>
                        {"  "}
                        Vendor List
                      </div>
                    </Grid>
                    <div style={{ marginLeft: 18 }}>
                      <Accordion data={vendorList} />
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    </>
  );
};

export default VenderListing;
