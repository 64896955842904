import React, { useState, useEffect, useRef, useCallback } from "react";
// import Tabs from 'react-bootstrap/Tabs'
// import Tab from 'react-bootstrap/Tab'
import Select from "react-select";
import "./styles.css"
import { useDispatch, useSelector } from "react-redux";
import { PlaidLink } from "react-plaid-link";
import moment from "moment";
import {
  Grid,
  Row,
  Col,
  Button,
  Dropdown,
  DropdownButton,
  MenuItem,
} from "react-bootstrap";
import XLSX from "sheetjs-style";
import Modal from "react-bootstrap-modal";
import JSZip from "jszip";
import { saveAs } from "file-saver";
// const cloneDeep = require("lodash.clonedeep");
import cloneDeep from "lodash.clonedeep";

import { Card } from "components/Card/Card.jsx";
import Pagination from "components/ThemeComponents/Pagination";
import { plaidServices } from "services/plaid";
import { transactionServices } from "../../../services/transaction";
import { emailServices } from "../../../services/email";
import { addresServices } from "../../../services/address";

import {
  objectsConstants as OC,
  shipmentObj,
  shipmentObject,
  shipmentObjectItems,
} from "redux/constant/objects-constant";
import { exportData } from "./exportData";
import { formatData } from "./formatData";

import { alertActions } from "redux/actions/alert-actions";
import { returnColumnValueWithColumnMatch } from "helper/helperFunctions";
import AsyncCreatableSelect from "react-select/async-creatable";
import Accordion from "./Accordion";

import { ORDER_STATUSES, CARRIERS } from "../../../constants";
import { FieldGroup as FieldGroupTwo } from "../../FormInputs/FormInputs";

import Import from "./components/DataInput";
import FieldGroup from "./components/FieldGroup";
import ImageUploaderModal from "../TransactionStatus/ImageUploaderModal";
import ImageViewerModal from "./imageViewerModal";
import PdfViwer from "./Pdfviwer";

import { FiPaperclip } from "react-icons/fi";
// import Transaction from "../Plaid/Transactions";

import ShowRefundItems from "./ShowRefundItems";
import { transactionFormat } from "./helpers/manualtransFormat";
import ImportManual from "./components/importManual";
import { giftCardServices } from "../../../services/giftcard";
import TrasactionTable from "../TrasactionTable";
import { $CombinedState } from "redux";
import { useLocation } from "react-router-dom";
import { compact } from "lodash";
// import ImportManual from "./components/DataInput2";

const startDateDefault = moment()
  .subtract(10, "day")
  .format("YYYY-MM-DD");

function TransactionStatusList(props) {
  const dispatch = useDispatch();
  const [options, setOptions] = useState();
  const [transactionDetail, setTransactionDetail] = useState();
  const [state, setState] = useState({
    cardLink: null,
    publicTokenObject: null,
    accessToken: localStorage.getItem("accessToken"),
    banks: {},
    bankCollapse: true,
    newBankAccounts: [],
    accountModal: { show: false, bank_id: null },
    accountDateModal: { ...OC.ACCOUNTDATE },
    selectedNewBankAccounts: [],
    publicTokenObjectUpdated: false,
  });
  const [publicTokenObject, setPublicTokenObject] = useState(null);
  const [openToogle, setOpenToogle] = useState(false);
  // const [title,setTitle] = useState('Select Type');
  const [title2, setTitle2] = useState("Actions");
  const [ids, setSelectedIds] = useState([]);
  const [transactionAccount, setTransactionAccount] = useState();
  const [transactionDetailOpen, setTransactionDetailOpen] = useState();
  // const [giftCardRes, setGiftCardRes] = useState([]);
  const [expanded, setExpended] = useState({ 0: false });
  const [expanded1, setExpended1] = useState({ 0: false });
  const [showUnmarked, setShowUnmarked] = useState(true);
  // const [showEverythingElse, setShowEverythingElse] = useState(true);
  const [paginationBulkAction, setPaginationBulkAction] = useState(false);
  const [paginationOpenBulkAction, setPaginationOpenBulkAction] = useState(
    false
  );
  const [bankAccounts, setBankAccounts] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const [bankAccountsTwo, setBankAccountsTwo] = useState([]);
  const [selectedBankTwo, setSelectedBankTwo] = useState({});
  const [selectedAccountTwo, setSelectedAccountTwo] = useState({});

  const [emails, setEmails] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [emailId, setEmailId] = useState(null);

  const [inventories, setInventories] = useState([]);
  const [vendor, setVendor] = useState(null);
  const [order, setOrder] = useState(null);
  const [currentTran, setCurrentTran] = useState({});
  const [data, setData] = useState([]);
  const [hasNew, setHasNew] = useState(false);
  const [dataModal, setDataModal] = useState(false);
  const today = moment().format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(startDateDefault);
  const [endDate, setEndDate] = useState(today);
  const [startDateTwo, setStartDateTwo] = useState(startDateDefault);
  const [endDateTwo, setEndDateTwo] = useState(today);
  const [filterBy, setFilterBy] = useState();
  const [tranStates, setTranStates] = useState([]);
  const [refreshEverythingElseData, setRefreshEverythingElseData] = useState(
    false
  );

  const [showReturnSec, setShowReturnSec] = useState(false);
  const [returnItemsDetail, setReturnItemsDetail] = useState();
  const [returnItemObj, setReturnItemObj] = useState({
    returnRmaNumber: 0,
    returnTrackingNumber: "",
    courierReturn: "",
  });

  const [invoiceImageData, setIvoiceImageData] = useState([]);
  const [showUploader, setShowUploader] = useState(false);
  const [imageUploaderData, setImageUploaderData] = useState({
    DataId: "",
  });
  const [showImageViewer, setShowImageViewer] = useState(false);
  const [showInvoiceImg, setShowInvoiceImg] = useState([]);
  const [exportAllTransactionObj, setExportAllTransactionObj] = useState();
  const [initailRetunQty, setInitailRetunQty] = useState(0);
  const [showPdf, setShowPdf] = useState(false);
  const [pdfFileData, setPdfFileData] = useState(null);

  const [checkOrderStatus, setCheckOrderStatus] = useState(false);
  const [shipment, setShipment] = useState([]);
  const [cardData, setCardData] = useState();
  const [all, setAll] = useState(false);
  const [open, setOpen] = useState(false);
  const [terms, setTerms] = useState(false);
  const [close, setClose] = useState(false);
  const [unmark, setUnmark] = useState(true);
  // const [updatedData, setUpdatedData] = useState();
  const [submitted, setSubmitted] = useState(false);
  const pathName = window.location.pathname;
  //pagination refs
  const pagination = useRef();
  const paginationOpen = useRef();

  // getGiftCard data
  useEffect(() => {
    getCardData();
  }, []);
  let gitfCardlatest = [];
  const getCardData = async () => {
    let res = await giftCardServices.getCsvGiftCard();
    gitfCardlatest=res.data;
    setCardData(res.data);
  };

  useEffect(() => {
    const getTransStates = async () => {
      try {
        const response = await transactionServices.getTransactionStates();
        const transactionStates = response.data.filter((transactionState) => {
          return transactionState.name.toLowerCase() !== "unmarked";
        });
        setTranStates([...transactionStates]);
      } catch (error) {
        setTranStates([]);
      }
    };
    getTransStates();
  }, []);

  useEffect(() => {
    dispatch(getTransactionTypes());
    getInventoriesAsyncFunction();
    getAllEmails();
    getAllAddressData();
  }, []);

  useEffect(() => {
    if (showUnmarked) {
      function collapseCordian() {
        setExpended({ 0: false });
      }
      document
        .querySelector(".-previous")
        .addEventListener("click", collapseCordian);
      document
        .querySelector(".-next")
        .addEventListener("click", collapseCordian);
      return () => {
        const previous = document.querySelector(".-previous");
        if (previous) {
          previous.removeEventListener("click", collapseCordian);
        }
        const next = document.querySelector(".-next");
        if (next) {
          next.removeEventListener("click", collapseCordian);
        }
      };
    }
  }, [showUnmarked]);

  const paginationCall = async (data) => {
    const payload = {
      ...data,
      startDate: startDate,
      endDate: endDate,
      // type: "inventory",
      type: transactionAccount,
      state: ["unmarked"],
      bank_id: selectedBank && selectedBank.id,
      account_id: selectedAccount && selectedAccount.id,
    };
    if (filterBy === "show_all") {
      payload.state = ["open", "close", "Unmarked"];
    } else if (filterBy) {
      payload.state = [filterBy];
    }
    setExportAllTransactionObj(payload);
    const response = await plaidServices.transactionDetails({ ...payload });

    return response;
  };

  useEffect(() => {
    // if (showUnmarked) { paginationOpen.current.dataCall(1); }
    pagination.current.dataCall(1);
  }, [filterBy, transactionAccount, selectedBank, selectedAccount]);

  const paginationCallOpen = async (data) => {
    const payload = {
      ...data,
      startDate: startDateTwo,
      endDate: endDateTwo,
      type: "",
      state: ["open"],
      bank_id: selectedBankTwo && selectedBankTwo.id,
      account_id: selectedAccountTwo && selectedAccountTwo.id,
    };
    if (filterBy === "show_all") {
      payload.state = ["open", "close"];
    } else if (filterBy) {
      payload.state = [filterBy];
    }

    const response = await plaidServices.transactionDetails({ ...payload });
    setTransactionDetailOpen([...response.data.pagination.data]);
    return response;
  };

  function getTransactionTypes(data) {
    return (dispatch) => {
      return transactionServices.getTransactionTypes(data).then(
        (response) => {
          return setOptions(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    };
  }
  const onChangeSingle = async (e, id) => {
    let data = {
      transaction_type_id: Number(e.target.value),
      ids: [id],
    };
    await transactionServices.changeTransactionType(data).then((response) => {
      dispatch(alertActions.success(response.message));
      pagination.current.dataCall();
      // paginationOpen.current.dataCall();
    });
  };

  const onChangeMultipleUnmark = async (e) => {
    let data = {
      transaction_type_id: Number(e),
      ids: pagination.current.state.checkedIds,
    };
    await transactionServices.changeTransactionType(data).then((response) => {
      dispatch(alertActions.success(response.message));
      setSelectedIds([]);
      pagination.current.dataCall();

      // paginationOpen.current.dataCall();
    });
  };

  const exportToCSV = async (check) => {
    try {
      let ids = [];
      ids = pagination.current.state.checkedIds;
      // if (check === "unmarked") ids = pagination.current.state.checkedIds;
      // else if (check === "open") ids = paginationOpen.current.state.checkedIds;

      let res = await transactionServices.exportCSV({ transactionIds: ids });
      // if(res.data.length == 0){
      //    dispatch(alertActions.error("You can't export of this transaction type"));
      //    return
      // }
      if (check === "unmarked") {
        res.data.forEach((t) => {
          t.vendor.email = "";
        });
      }

      let result = exportData(res.data, inventories);
      let ws = XLSX.utils.aoa_to_sheet(result);
      ws["!merges"] = [XLSX.utils.decode_range("A1:G1")];
      ws["!rows"] = [{ hpt: 20 }];
      ws["A1"].s = {
        font: {
          sz: 16,
          color: {
            rgb: "FF0000",
          },
        },
        fill: {
          fgColor: { rgb: "FFFF00" },
        },
        alignment: {
          horizontal: "center",
          vertical: "center",
        },
      };
      const get_header_row = (sheet) => {
        let range = XLSX.utils.decode_range(sheet["!ref"]);
        let C,
          R = 1;
        let headers = [];
        for (C = range.s.c; C <= range.e.c; ++C) {
          let cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
          var cell_address = { c: C, r: R };
          var cell_ref = XLSX.utils.encode_cell(cell_address);
          if (cell && cell.t) headers.push(cell_ref);
        }
        return headers;
      };
      const h = get_header_row(ws);
      h.map(
        (a) =>
          (ws[a].s = {
            font: {
              bold: true,
            },
            alignment: {
              horizontal: "center",
              vertical: "center",
            },
          })
      );

      ws["!cols"] = fitToColumn(result);
      function fitToColumn(result) {
        let a = result[1].map((a, i) => ({
          wch: Math.max(
            ...result.map((a2) => (a2[i] ? a2[i].toString().length + 3 : 0))
          ),
        }));
        return a;
      }
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Transaction Detail");
      XLSX.writeFile(wb, "transaction_detail.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  // to export all transaction
  const handleAllExportTransaction = async (exportDataObj, check) => {
    try {
      if (exportDataObj) {
        const res = await transactionServices.ExportAllDataCsv(exportDataObj);

        if (check === "unmarked") {
          res.data.forEach((t) => {
            t.vendor.email = "";
          });
        }

        let result = exportData(res.data, inventories);
        let ws = XLSX.utils.aoa_to_sheet(result);
        ws["!merges"] = [XLSX.utils.decode_range("A1:G1")];
        ws["!rows"] = [{ hpt: 20 }];
        ws["A1"].s = {
          font: {
            sz: 16,
            color: {
              rgb: "FF0000",
            },
          },
          fill: {
            fgColor: { rgb: "FFFF00" },
          },
          alignment: {
            horizontal: "center",
            vertical: "center",
          },
        };
        const get_header_row = (sheet) => {
          let range = XLSX.utils.decode_range(sheet["!ref"]);
          let C,
            R = 1;
          let headers = [];
          for (C = range.s.c; C <= range.e.c; ++C) {
            let cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
            var cell_address = { c: C, r: R };
            var cell_ref = XLSX.utils.encode_cell(cell_address);
            if (cell && cell.t) headers.push(cell_ref);
          }
          return headers;
        };
        const h = get_header_row(ws);
        h.map(
          (a) =>
            (ws[a].s = {
              font: {
                bold: true,
              },
              alignment: {
                horizontal: "center",
                vertical: "center",
              },
            })
        );

        ws["!cols"] = fitToColumn(result);
        function fitToColumn(result) {
          let a = result[1].map((a, i) => ({
            wch: Math.max(
              ...result.map((a2) => (a2[i] ? a2[i].toString().length + 3 : 0))
            ),
          }));
          return a;
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Transaction Detail");
        XLSX.writeFile(wb, "transaction_detail.xlsx");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleDownloadAllInvoiceSource = async () => {
    try {
      const res = await transactionServices.allInvoice();
      if (res.data.length > 0) {
        handleZipFileDownload(res.data);
      }

      if (res.status === "error") {
        dispatch(alertActions.error(res.message));
        return;
      }
    } catch (error) {
      console.log("download invoice  error ", error);
    }
  };

  const dwnldTmpltHeading = () => {
    let heading = [
      "Amount",
      "Merchant Name",
      "Date(yyyy/mm/dd)",
	  "Card Number",
      "Order Number",
      "Warehouse Address",
      // "Zip Code",
      // "State ",
      // "City",
      "Email address",
      "Order Status",
      "Taxes",
      "Shipping",
    ];
    let giftCardsHead = [];
    let itemHeading = ["item name", "Qty", "Price"];
    let shipmentHeading = ["Courier", "Tracking Number"];
    let shipmentItemsHeading = ["Shipment Item Name", "Expected Qty"];
    // gift cards heading
    for (let i = 1; i <= 10; i++) {
      giftCardsHead.push(`Gift_Card_${i}`, "UseAmount");
    }
    // items headings
    let allItmsHeading = new Array(7).fill(itemHeading).flat();
    // shipment headings
    let shpmntItmRptHeading = new Array(7).fill(shipmentItemsHeading).flat();
    let snglShipmntHeading = shipmentHeading.concat(shpmntItmRptHeading);
    let allShpmntsHeading = new Array(7).fill(snglShipmntHeading).flat();
    //merge headings
    heading = heading
      .concat(giftCardsHead)
      .concat(allItmsHeading)
      .concat(allShpmntsHeading)
      .flat();
    return heading;
  };
  const downloadTemplate = async () => {
    try {
      let data = [];
      let h1 = ["   "];
      let heading = dwnldTmpltHeading();
      data.length = heading.length;

      let result = [h1, heading, data];
      let ws = XLSX.utils.aoa_to_sheet(result);
      ws["!merges"] = [XLSX.utils.decode_range("A1:FF1")];
      ws["!rows"] = [{ hpt: 20 }];
      ws["A1"].s = {
        font: {
          sz: 16,
          color: {
            rgb: "FF0000",
          },
        },
        fill: {
          fgColor: { rgb: "FFFF00" },
        },
        alignment: {
          horizontal: "center",
          vertical: "center",
        },
      };

      const get_header_row = (sheet) => {
        let range = XLSX.utils.decode_range(sheet["!ref"]);
        let C,
          R = 1;

        let headers = [];
        for (C = range.s.c; C <= range.e.c; ++C) {
          let cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
          var cell_address = { c: C, r: R };
          var cell_ref = XLSX.utils.encode_cell(cell_address);
          if (cell && cell.t) headers.push(cell_ref);
        }
        return headers;

      };
      const h = get_header_row(ws);
      // adding bgcolors for headers
      h.map((a) => {
        if (
          ws[a].v === "Amount" ||
          ws[a].v === "Merchant Name" ||
          ws[a].v === "Date(yyyy/mm/dd)"
        ) {
          ws[a].s = {
            font: {
              bold: true,
            },
            fill: {
              fgColor: { rgb: "b1df45" },
            },

            alignment: {
              horizontal: "center",
              vertical: "center",
            },
          };
        } else {
          ws[a].s = {
            font: {
              bold: true,
            },
            alignment: {
              horizontal: "center",
              vertical: "center",
            },
          };
        }
      });

      ws["!cols"] = fitToColumn(result);
      function fitToColumn(result) {
        let a = result[1].map((a, i) => ({
          wch: Math.max(
            ...result.map((a2) => (a2[i] ? a2[i].toString().length + 9 : 0))
          ),
        }));
        return a;
      }
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Manual Transaction Detail");
      XLSX.writeFile(wb, "Manual Transaction_detail.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const handleFile = async (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = async (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        cellDates: true,
        dateNF: "dd.mm.yyyy",
      });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data1 = XLSX.utils.sheet_to_json(ws, { header: 1, defval: "" });
      if (data1[1][0] === "Amount") {
        dispatch(alertActions.error("please select a valid file"));
        return;
      }
      const formattedData = formatData(data1, inventories);

      checkData(formattedData);
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  const handleManualTransaction = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = async (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        cellDates: true,
        dateNF: "dd.mm.yyyy",
      });

      const wsname = wb.SheetNames[0];

      const ws = wb.Sheets[wsname];
      const data1 = XLSX.utils.sheet_to_json(ws, { header: 1, defval: "" });

      let headings = dwnldTmpltHeading();
      let find;
      data1[1].map((h) => {
        let found = headings.find((f) => f == h);
        if (!found) find = false;
      });
      if (find === false) {
        dispatch(alertActions.error("please select a valid file"));
        return;
      }
      if (data1.length === 2) {
        dispatch(
          alertActions.error(
            "You have uploaded an empty file,please first fill it"
          )
        );
        return;
      }
      const formattedData = transactionFormat(data1);
      checkData(formattedData);
      // let validate = checkFormattedData(formattedData);
      // validate= false
      // if (validate) saveData(formattedData);
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  const checkFormattedData = (data) => {
    let errors = [];
    data.map((d, i) => {
      if (d.amount === "") {
        errors.push([`Amount is required .`]);
      }
      if (d.merchantName === "") {
        errors.push([`Merchant name is required .`]);
      }

      let isNumber = !isNaN(parseFloat(d.amount)) && isFinite(d.amount);
      if (isNumber === false && d.amount !== "") {
        errors.push([
          ` Amount must be in number. Please check your amount ${d.amount}`,
        ]);
      }
    });
    if (errors.length > 0) {
      dispatch(alertActions.error(errors));
      return false;
    } else {
      return true;
    }
  };

  const saveData = async (data) => {
    let res = await transactionServices.saveManualTransaction(data);
    if (res) {
      dispatch(alertActions.success("Manually transaction saved"));
    }
  };

  const getShipmentName = (id) => {
    let item = inventories.find((i) => i.id === id);
    if (item) {
      return item.name;
    }
    return;
  };

  const verifyShipmentName = (shipments, items) => {
    items.forEach((i) => {
      let found = shipments.find((s) => s.name === i.name);

      if (found) {
        if (found.qty === "") {
          found.qty = 0;
        }
        i.itmCount += parseInt(found.qty);
        if (i.itmCount <= i.qty) return true;
        else {
          return false;
        }
      }
    });
    return;
  };

  const checkData = async (data, i) => {
    //  let name = [];
    await getCardData();
    let errors = [];
    data &&
      data.forEach((d, index) => {
        let amount;
        let cal = 0;
        let itemsCount = [];
        let newItems = [];

        // check merchant name
        if (d.name === "") {
          errors.push([
            `Merchant name is required. Please check your row ${index}.`,
          ]);
        }

        // check date isvalid or not  (For manual transaction )

        if (d.date) {
          let valid = moment(d.date).isValid();
          if (!valid) {
            let err;
            err = `Invalid date at row ${index}`;
            errors.push(err);
          }
        }
        // check gift card is valid or not (For manual transaction)
        let balance = 0;
        let price = d.amount;
        let foundCards = [];
        d.giftCards &&
          d.giftCards.length > 0 &&
          d.giftCards.forEach (async (card) => {

            if (card.card_no !== "") {
              let found = gitfCardlatest.find((f) => f.card_no == card.card_no);

              if (found && found.balance && found.balance < card.useAmount) {
                let err = `Use amount of Card no ${card.card_no} is greater than Balance `;
                errors.push(err);
              }
              if (!found) {
                let err = `Invalid Card no ${card.card_no} at row ${index}`;
                errors.push(err);
              } else {
                let exist = foundCards.find((f) => f.card_no == found.card_no);

                if (!exist) {
                  // balance += Math.floor(parseInt(found.balance));
                  balance += parseFloat(found.balance);
                  // card.balance = Math.floor(parseInt(found.balance));
                  card.balance = parseFloat(found.balance);
                  card.balance = card.balance - price >= 0 ? card.balance - price : 0;
                  // found.balance =  card.balance
                  foundCards.push(found);
                  if (price > 0) {
                    price =
                      price - Math.floor(parseInt(found.balance)) >= 0
                        ? price - Math.floor(parseInt(found.balance))
                        : 0;
                  }
                }
              }
            }
          });

        foundCards = [];
        //check gift card balance  (For  manual Transaction)
        if (
          d.giftCards &&
          d.giftCards.length > 0 &&
          d.amount &&
          d.amount > balance
        ) {
          let err = `Your amount is exceeded from your giftCards balance at row ${index}`;
          errors.push(err);
        }
        let sumOfUseamount =
        d.giftCards && d.giftCards.reduce((a, v) => (a = a + v.useAmount), 0);

        let sumOfCardsAmount = parseFloat(sumOfUseamount).toFixed(2);
        if (
          d.giftCards &&
          d.giftCards.length > 0 &&
          Number(sumOfCardsAmount) !== d.amount
        ) {
          let err = `Your useAmount is not equal to transaction amount`;
          errors.push(err);
        }
        // check gift card balance

        d.items &&
          d.items.map((inv) => {
            let found = inventories.find((f) => f.name === inv.name);
            if (!found) {
              newItems.push(inv.name);
            }
          });
        if (newItems.length > 0) {
          createNewInventories(newItems);
        }

        amount = d && Number(d.amount);
        let shippingNoAmount = d.order.shippingNo;
        let taxesAmount = d.order.taxes;
        d &&
          d.items &&
          d.items.forEach((i) => {
            let obj = {};
            obj["total_orders"] = 0;
            obj["name"] = i.name;
            obj["qty"] = i.qty_ordered;
            itemsCount.push(obj);

            cal += i.qty_ordered * i.price
          });

          cal += taxesAmount + shippingNoAmount;
        if (cal > amount) {
          let err;
          err = `In row ${index} items must be equal or less than amount`;
          errors.push(err);
        }
        if (d.items && d.items.length > 0) {
          //check amount
          if (d.amount === "") {
            errors.push(`Amount is required. Please check your row ${index}.`);
          }

          let isNumber = !isNaN(parseFloat(d.amount)) && isFinite(d.amount);
          if (isNumber === false && d.amount !== "") {
            errors.push(
              ` Amount must be in number. Please check your amount ${d.amount} at row ${index}`
            );
          }

          // check duplicate items;
          const duplicates = d.items
            .map((el, i) => {
              return d.items.find((element, index) => {
                if (i !== index && element.name === el.name) {
                  return el;
                }
                return undefined;
              });
            })
            .filter((x) => x);
          if (duplicates.length > 0) {
            let err = `In row ${index + 3} items name are repeated.`;
            errors.push(err);
          }
        }

        // check shipment item name
        let itmObj = {};
        d.shipments &&
          d.shipments.forEach((shipment, i) => {
            shipment.items.forEach((shItm) => {
              let sum = 0;
              if (shItm.name) {
                let found = d.items.find((itm) => itm.name === shItm.name);
                if (!found) {
                  errors.push([
                    `Shipment Item name:"${shItm.name}" did not match any order item name`,
                  ]);
                } else {
                  if (found && shItm.qty_expected === "") {
                    shItm.qty_expected = 0;
                    //  errors.push(['Shipment Item expected quantity is missing']);
                  } else {
                    d.items.forEach((itm) => {
                      if (itm.name === shItm.name) {
                        if (itm.qty_ordered < shItm.qty_expected) {
                          errors.push(
                            `shipmentItem: ${shItm.name} quantity is greater than orderItem: ${itm.name} quantity `
                          );
                        } else {
                          if (itmObj[`${itm.name}`]) {
                            itmObj[`${itm.name}`] += shItm.qty_expected;
                            if (itmObj[`${itm.name}`] > itm.qty_ordered) {
                              errors.push(
                                `shipmentItem: ${shItm.name} quantity is greater than orderItem: ${itm.name} quantity`
                              );
                            }
                          } else {
                            sum += shItm.qty_expected;
                            itmObj[`${itm.name}`] = sum;
                          }
                        }
                      }
                    });
                  }
                }
              }
            });
          });

        // validate shipment expected quantity
        if (d.shipments && d.shipments.length > 0) {
          d.items.forEach((item, i) => {
            let sum = 0;
            d.shipments.map((ship, s) => {
              ship.items.map((si) => {
                let name = getShipmentName(si.inventory_id);
                if (name && name === item.name) {
                  sum = sum + si.qty_expected;
                }
              });
            });
            if (sum > item.qty_ordered) {
              let err = ["Quantity expected is greater than quantity order "];
              errors.push(err);
              return false;
            }
          });
        }
      });
    //  let filterdErr = errors.filter(err => err[0]);
    if (errors.length > 0 && errors) {
      dispatch(alertActions.error(errors));
      errors = [];
    } else {
      setData(data);
      setDataModal(true);
    }
  };

  const importDataSubmit = async (data) => {
    const response = await transactionServices.importFromExcel(data);
    if (response.error) {
      setDataModal(false);
      dispatch(alertActions.error(response.message));
    }

    if (response.status) {
			let obj = response.getInventories;
			let transaction = null;
			if(obj) {
				for (var key in obj) {
					if(typeof(obj[key]) === 'object') {
						transaction = obj[key];
						if(transaction) {
							if (transaction.hasOwnProperty('transaction_id')) {
								//api to store the data of shipments tracking number for ups
								transactionServices.getTrackingDetails(transaction['transaction_id']);
							}
						}
					}
				}
			}

      setDataModal(false);
      dispatch(alertActions.success(response.message));
      pagination.current.dataCall();
    }
    // window.location.reload();
    // paginationCall();
  };

  const onChangeMultipleOpenAndClose = async (e) => {
    let data = {
      transaction_type_id: Number(e.target.value),
      ids: paginationOpen.current.state.checkedIds,
    };
    await transactionServices.changeTransactionType(data).then((response) => {
      dispatch(alertActions.success(response.message));
      pagination.current.dataCall();
      paginationOpen.current.dataCall();
    });
  };

  const columns = [
    {
      Header: "Transaction Status Listing",
      headerClassName: "headerPagination",
      // className: "test123",
      columns: [
        {
          Header: "Date",
          accessor: "date",
          className: "action-center",
          Cell: ({ row, value }) => {
            return (
              <div>
                {moment(value).format("LL")}{" "}
              </div>
            );
          },
          maxWidth: 150,
          sortable: false,
        },
        {
          Header: "Order Number",
          accessor: "date",
          className: "action-center",
          Cell: ({ row, value }) => {
            return (
              <div style={{marginLeft: '10px' , }}>
                {row._original.order && row._original.order.order_number ? row._original.order.order_number : '----'}
              </div>
            );
          },
          // maxWidth: 150,
          sortable: false,
        },
        {
          // Header: "Name",
          // className: "action-center",
          // maxWidth: 400,
          // minWidth: 400,
          // width: 400,
          Header: () => (
            <div
              style={{
                textAlign: "center",
              }}
            >
              Vendor
            </div>
          ),
          accessor: "name",
          sortable: false,
          Cell: ({ row, value }) => (
            <div>
              {value}
            </div>
          ),
        },
        {
          Header: () => <div style={{ textAlign: "center" }}>Amount</div>,
          accessor: "amount",
          sortable: false,
          // className: "action-center",
          Cell: ({ row, value }) =>
            (
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  color: row._original.status === "Posted" ? "black" : "blue",
                }}
              >
                $ {value}
              </div>
            ),
          maxWidth: 120,
        },
        {
          Header: "Cards",
          accessor: "account",
          alignItems: "left",
          className: "action-center",
          maxWidth: 300,
          Cell: ({ row, value }) => {
            return (
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
              >

                { row && row._original.card_no ? row._original.card_no : value && value.official_name ? value.official_name : " " }
				{ row && row._original.card_no ? " " : value && value.mask ? value.mask : " " }
				{" "}
              </div>
            );
          },
          sortable: false,
        },
        filterBy !== "open"
          ? {
              Header: "Account",
              accessor: "transaction_type.name",
              className: "action-center",
              sortable: false,
              Cell: ({ row, value }) => {
                return (
                  <>
                    <select
                      className=" form-control"
                      // style={{color: row._original.deleted === '1' ? 'red' : ''}}
                      onChange={(e) => onChangeSingle(e, row._original.id)}
                      // defaultValue={row._original.transaction_type.id}
                    >
                      {options &&
                        options
                          .filter((opt) => opt.label !== "All")
                          .map((option, i) => (
                            <option
                              key={i}
                              value={option.id}
                              selected={
                                row._original.transaction_type &&
                                row._original.transaction_type.id === option.id
                              }
                            >
                              {option.label}
                            </option>
                          ))}
                    </select>
                  </>
                );
              },
              maxWidth: 200,
            }
          : {
              Header: "",
              accessor: "",
              // className: "btn",
              Cell: () => {
                return <div />;
              },
              maxWidth: 0,
              sortable: false,
            },

        filterBy === "open"
          ? {
              Header: "Order Status",
              accessor: "status",
              maxWidth: 150,
              sortable: false,
              Cell: ({ row, value }) => {
                return (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    {row._original.order && row._original.order.status}
                  </div>
                );
              },
            }
          : {
              Header: "",
              accessor: "",
              // className: "btn",
              Cell: () => {
                return <div />;
              },
              maxWidth: 0,
              sortable: false,
            },

        filterBy === "show_all"
          ? {
              Header: "State",
              accessor: "transaction_state.name",
              // className: "btn",
              Cell: ({ row, value }) => {
                return (
                  <div
                    style={{
                      backgroundColor:
                        value === "close"
                          ? "#00c400"
                          : value === "open"
                          ? "#f2f216"
                          : value === "unmarked"
                          ? "#c80000"
                          : "",

                      height: "15px",
                      width: "15px",
                      borderRadius: "20px",
                    }}
                  ></div>
                );
              },
              maxWidth: 70,
              sortable: false,
            }
          : {
              Header: "",
              accessor: "",
              // className: "btn",
              Cell: () => {
                return <div />;
              },
              maxWidth: 0,
              sortable: false,
            },
      ],
    },
  ];

  const findInventory = (id) => {
    return inventories.find((e) => e.id === id).name;
  };

  const findItransaction_type = (id) => {
    return options.find((e) => e.id === id).name;
  };

  const changeFormInv = (e, index) => {
    e.preventDefault();
    const { name, value, type } = e.target;
    const transDetail = [...transactionDetail];
    transDetail[index][name] = Number(value) > 0 ? Number(value) : null;
    setTransactionDetail(transDetail);
  };

  const submitInventory =  async (event, id, tid) => {
	event.preventDefault();
	event.stopPropagation();

    let should_submit = 0;

    // transactionDetail.forEach((transaction) => {
    //   let row_total = 0
    //   transaction.shipments.forEach((shipment) => {
    //     row_total = parseInt(row_total) + parseInt(shipment.qty_expected)
    //   })

    //   if (row_total > transaction.qty_ordered) {
    //     let inventory = findInventory(transaction.inventory_id)
    //     dispatch(
    //       alertActions.error([
    //         [
    //           `Sum of expected quantities of ${inventory} should be less than Quantity`,
    //         ],
    //       ])
    //     )
    //     should_submit = true
    //   }
    // })

    // if (should_submit === true) {
    //   return false
    // }

    //only send those items to  backend which have inventory and quantity order

    // console.log('-----tran', transactionDetail)

    // const newresult = transactionDetail.filter((tran,id)=>{
    //       return (tran.inventory_id!=='' && (tran.qty_ordered!==null || tran.qty_ordered!==0))
    // })

    // console.log('newresult', newresult)

	if (order.status === "Received in full") {
		dispatch(alertActions.error([[`There is no change in the transaction as it is received fully`] ]));
		return false;
	}

    const result = transactionDetail.map((transaction) => {
      const newTransaction = {
        ...transaction,
        qty: transaction.qty || 0,
        price: transaction.price || 0,
        taxes: transaction.taxes || 0,
        shipping: transaction.shipping || 0,
      };
      return { ...newTransaction, order, vendor };
    });

    // to extract taxes and shipping no from order
    const { taxes, shippingNo, ...rest } = order;
    let tax = parseInt(taxes, 10);
    let shipNo = parseInt(shippingNo, 10);

    let ONRequire = 0;
    result.forEach((item) => {
      if (item.new && item.new === true) {
        if (item.order.order_number === "") {
          ONRequire++;
          return false;
        }
      }
    });

    if (ONRequire > 0) {
      dispatch(
        alertActions.error([
          [`Order number is required to save this transaction details`],
        ])
      );
      return false;
    }
        // to check if order no is empty
    if( order && order.order_number.trim() === '') {
      dispatch(
        alertActions.error([
          [`Order number is required to save this transaction details`],
        ])
      );
      return false;
    }

    const oldTransactions = result.filter((item) => {
      return "id" in item;
    });

    //console.log('oldTransactions',oldTransactions)
    //console.log('result',result)

    let inventory_require = false;

    if (oldTransactions.length > 0) {
      result.forEach((item) => {
        if (item.inventory_id === null) {
          inventory_require = true;
          return false;
        }
      });
    }
    if (inventory_require) {
      dispatch(
        alertActions.error([
          [`Select inventory to save this transaction details`],
        ])
      );
      return false;
    }
    let cal = 0;
    result.forEach((i) => {
      cal = i.qty_ordered * i.price + cal ;
    });
    cal +=  Number(result[0].order.shippingNo) + Number(result[0].order.taxes);

    if (cal > currentTran.amount) {
      dispatch(
        alertActions.error([
          ["Sum of inventory must be equal or less than transaction amount"],
        ])
      );
      return false;
    }

    if (checkOrderStatus) {
      if (
        order.status === "Received in full" ||
        // order.status === "Received partial" ||
        order.status === "Received missing" ||
        order.status === "Received damaged" ||
        order.status === "Received/Refunded"
      ) {
        dispatch(
          alertActions.error([["Selected Order Status is not acceptable"]])
        );
        return false;
      }
    }

    //this is to restrict user storing quantity expected greater than quantity order
    if (shipment.length > 0) {
      let count = 0;
      transactionDetail.map((trans, ti) => {
        let sum = 0;
        shipment.map((ship, si) => {
          const item = ship.items.filter((item) => {
            return item.inventory_id === trans.inventory_id;
          });
          if (item.length > 0) {
            sum = sum + item[0].qty_expected;
          }
        });
        if (sum > trans.qty_ordered) {
          count++;
        }
        if (count > 0) {
          dispatch(
            alertActions.error([
              ["Quantity expected is greater than quantity order"],
            ])
          );
          should_submit++;
          return false;
        }
      });
    }

    // this is to restrict the user without enter the tracking number and courier in shipment
    if (shipment.length > 0) {
      let count = 0;
      shipment.map((ship) => {
        if (ship.courier === "" || ship.tracking_number === "") {
          count++;
        }
      });
      if (count > 0) {
        if (count > 0) {
          dispatch(
            alertActions.error([
              [
                "Please select the courier and enter the tracking number in shipment",
              ],
            ])
          );
          should_submit++;
          return false;
        }
      }
    }

    if (should_submit > 0) {
      return false;
    }

	// Make submit button state disable true
	await setSubmitted(true);

    const data = {
      items: result,
      transaction_id: id,
      order: order,
      vendor: vendor,
      shipments: shipment,
      email_id: emailId,
      source: invoiceImageData,
    };

    // return false


    transactionServices.storeAndImportItemsBadge({ data, type: "store" }).then(
      (response) => {
        transactionServices.getTrackingDetails(tid); //api to store the data of shipments tracking number for ups
		// Make submit button state false again
		setSubmitted(false);
        dispatch(alertActions.success(response.message));
        pagination.current && pagination.current.dataCall();
        // paginationOpen.current && paginationOpen.current.dataCall();
        setExpended({ 0: false });
        setExpended1({ 0: false });
        setIvoiceImageData([]);
        getInventoriesAsyncFunction();
        transactionServices.bulkUpdateItems({
          oldTransactions,
          order,
          shipments: shipment,
        });
        //paginationOpen.current && paginationOpen.current.dataCall();
		//pagination.current && pagination.current.dataCall();
      },
      (error) => {
        dispatch(alertActions.error(error));
		setSubmitted(false);
      }
    );
  };

  const addRow = () => {
    let temp = JSON.parse(JSON.stringify(OC.TRANSACTIONDETAIL));
    setTransactionDetail((transactionDetail) => [...transactionDetail, temp]);
  };

  const handleRemoveItem = (key) => {
    const newArr = [...transactionDetail];
    newArr.splice(
      newArr.findIndex((item, i) => i === key),
      1
    );
    setTransactionDetail(newArr);
  };

  const addShipmentRow = (item, ind) => {
    let len;
    let blank;
    if (item.shipments.length === 0) {
      len = item.shipments.length;
      blank = { ...shipmentObj, item: "" };
    } else {
      len = item.shipments.length - 1;
      blank = { ...shipmentObj, item: item.shipments[len].item };
    }

    const tran = {
      ...item,
      shipments: [...item.shipments, { ...blank }],
    };

    const transactions = transactionDetail.map((t, i) => {
      return i === ind ? tran : t;
    });
    setTransactionDetail([...transactions]);
  };

  const removeShipmentRow = (item, idx, ind) => {
    const newShipments = item.shipments.filter((s, i) => {
      return idx !== i;
    });

    const tran = {
      ...item,
      shipments: [...newShipments],
    };

    const transactions = transactionDetail.map((t, i) => {
      return i === ind ? tran : t;
    });
    setTransactionDetail([...transactions]);
  };

  const updateShipment = (e, ins) => {
    const { name, value, type } = e.target;
    const ship = [...shipment];
    ship[ins][name] = value;
    setShipment(ship);
  };

  const setOrderValues = (e) => {
    setOrder({ ...order, [e.target.name]: e.target.value });
    setCheckOrderStatus(true);
  };

  const deleteItem = (item_id) => {
    const tranDtls = transactionDetail.filter(({ id }) => id !== item_id);

    transactionServices.deleteItem(item_id).then((response) => {
      dispatch(alertActions.success(response.message));
      setTransactionDetail(tranDtls);
      pagination.current.dataCall();
      // paginationOpen.current.dataCall();
      setExpended({ 0: false });
      window.location.reload();
    });
  };

  const appendShipment = () => {
    let count = 0;
    transactionDetail.map((item) => {
      if (
        item.inventory_id === "" ||
        item.qty_ordered === null ||
        item.qty_ordered === 0
      ) {
        count++;
      }
    });

    if (count > 0) {
      dispatch(
        alertActions.error([
          [
            "First add the inventory and quantity order than you are able to add shipment",
          ],
        ])
      );
      return false;
    }

    if (shipment.length === 0) {
      //console.log('inside 0')
      let newData = JSON.parse(JSON.stringify(shipmentObject));
      transactionDetail.map((item, i) => {
        let newDataItem = JSON.parse(JSON.stringify(shipmentObjectItems));
        newDataItem.qty_expected = JSON.parse(JSON.stringify(item.qty_ordered));
        newDataItem.inventory_id = JSON.parse(
          JSON.stringify(item.inventory_id)
        );
        newData.items.push(newDataItem);
      });
      setShipment((shipment) => [...shipment, newData]);
    } else {
      let temp = [];
      let error = 0;
      if (shipment.length === 1) {
        //console.log('inside 1')
        let items = transactionDetail.length;
        let sitems = shipment[0].items.length;
        if (items === sitems) {
          shipment[0].items.map((shipmentItems, si) => {
            let data;
            if (
              shipmentItems.qty_expected < transactionDetail[si].qty_ordered
            ) {
              data = JSON.parse(JSON.stringify(shipmentItems));
              let diffrence =
                transactionDetail[si].qty_ordered - shipmentItems.qty_expected;
              data.qty_expected = diffrence;
              temp.push(data);
            } else {
              error++;
            }
          });
        }
      }

			/**
			 * If shipments count is equal to or more than 2
			 */
	  	if (shipment.length >= 2) {

        let shipmentItemObj = []

        for (let shipInd = 0; shipInd < shipment.length; shipInd++) {

          for (let itemInd = 0; itemInd < shipment[shipInd].items.length; itemInd++) {

            if (!!shipment[shipInd].items[itemInd].qty_expected) {
              shipmentItemObj.push(
                {
                  id: shipment[shipInd].items[itemInd].inventory_id,
                  sum: shipment[shipInd].items[itemInd].qty_expected
                }
              )
            }
          }
        }

        let resultObject = shipmentItemObj.reduce((ac, a) => {
          let ind = ac.findIndex(x => x.id === a.id);
          ind === -1 ? ac.push(a) : ac[ind].sum += a.sum;
          return ac;
        }, [])

        for (let trans = 0; trans < transactionDetail.length; trans++) {
          for (let res = 0; res < resultObject.length; res++) {
						if (transactionDetail[trans].inventory_id ===  resultObject[res].id &&
								transactionDetail[trans].qty_ordered > resultObject[res].sum)  {
							let diffrence = transactionDetail[trans].qty_ordered - resultObject[res].sum ;
							let newData = JSON.parse(JSON.stringify(shipmentObjectItems));
							newData.inventory_id = JSON.parse(
								JSON.stringify(transactionDetail[trans].inventory_id)
							);
							newData.qty_expected = JSON.parse(JSON.stringify(diffrence));
							temp.push(newData);
						} else {
							error++;
						}
          }
        }
      }

      if (temp.length > 0) {
        let newShipment = JSON.parse(JSON.stringify(shipmentObject));
        //console.log('newShipment',newShipment)
        newShipment.items = temp;
        setShipment((shipment) => [...shipment, newShipment]);
      } else {
        if (error > 0) {
          dispatch(
            alertActions.error([
              ["Expected quantity is greater than quantity order"],
            ])
          );
        } else {
          dispatch(
            alertActions.error([
              [
                "Sum of Expected Qty and Qty Ordered is equal so new shipment will not create",
              ],
            ])
          );
          return false;
        }
      }
    }
  };

  const removeShipment = (id, sid) => {
    const newShipments = shipment.filter((s, i) => {
      return id !== i;
    });
    if (sid === "") {
      setShipment(newShipments);
    } else {
      transactionServices.deleteShipment(sid).then((response) => {
        dispatch(alertActions.success(response.message));
        setShipment(newShipments);
      });
    }
  };

  const markAsReceived = async () => {
    if (ids) {
      let payload = {
        ids,
      };
      let res = await transactionServices.markAsReceived(payload);

      if (!res.error) {
        setSelectedIds([]);
        dispatch(alertActions.success([res.message]));
        window.location.reload();
      } else {
        setSelectedIds([]);
        dispatch(alertActions.error([res.message]));
      }
    }
  };

  // for add return items
  const handleReturnItems = (row) => {
    if (row) {
      const arr = row.items;
      const temp = cloneDeep(arr);
      setReturnItemsDetail(temp);
    }
  };

  // to download zipfile for invoice

  const handleZipFileDownload = useCallback(async (data) => {
    var zip = new JSZip();

    const imagesFolder = zip.folder("Invoices");

    const convertBase64ToFile = (base64String, fileName) => {
      let arr = base64String.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let uint8Array = new Uint8Array(n);
      while (n--) {
        uint8Array[n] = bstr.charCodeAt(n);
      }
      let file = new File([uint8Array], fileName, { type: mime });
      return file;
    };

    const imagesFetcher = await Promise.all(
      data.map(async (imgSrc, index) => {
        let file = convertBase64ToFile(imgSrc.source, "images");
        return file;
      })
    );

    imagesFetcher.forEach((imgBlob, index) => {
      if (imgBlob.type === "image/png") {
        imagesFolder.file(`invoice_${index}.png`, imgBlob, { blob: true });
      }

      if (imgBlob.type === "image/jpg") {
        imagesFolder.file(`invoice_${index}.jpg`, imgBlob, { blob: true });
      }

      if (imgBlob.type === "image/jpeg") {
        imagesFolder.file(`invoice_${index}.jpeg`, imgBlob, { blob: true });
      }
      if (imgBlob.type === "application/pdf") {
        imagesFolder.file(`invoice_${index}.pdf`, imgBlob, { blob: true });
      }
    });

    zip.generateAsync({ type: "blob" }).then(function(content) {
      saveAs(content, "Invoice.zip");
    });
  }, []);

  // to store return transaction
  const handleReturnTransations = async () => {

    for (let i =0; i < returnItemsDetail.length ; i++) {
      if (transactionDetail[i].qty_ordered < returnItemsDetail[i].qty_ordered) {
        dispatch(
          alertActions.error([["Quantity return is greater than quantity order"]])
        );
        setReturnItemObj({});
        setReturnItemsDetail();
        return;
        }

    }
    const currentqty = returnItemsDetail.map((item) => item.qty_ordered );

    if(returnItemObj.courierReturn === "" || returnItemObj.returnTrackingNumber  === "" || returnItemObj.returnRmaNumber === 0)  {

      dispatch(alertActions.error([["Please provide  Return qunatity , courier , RMA and Tracking number to add returns"]]));
      setReturnItemObj({});
        setReturnItemsDetail();
        setShowReturnSec(false);
      return;
    }

    else {

      const returnTransactionID = returnItemsDetail.map(
        (id) => id.transaction_id
      );
      const returnDataObj = {
        ...returnItemObj,
        id: returnTransactionID[0],
        returnItemsDetail,
      };
      let res = await transactionServices.returnTransactions(returnDataObj);
      if (res.message) {
        dispatch(alertActions.success(res.message));
        setReturnItemObj({});
        setReturnItemsDetail();
        setShowReturnSec(false);
        pagination.current.dataCall();
      } else {
        dispatch(alertActions.error([["Return items not added"]]));
      }
    }


    const perqty = transactionDetail.map((item) => item.qty_ordered);



  };

  const openViewer = () => {
    setShowImageViewer(!showImageViewer);
  };

  const showItems = (row, check) => {
    const returedItemsData = row.original.returnshipments;
    const filterReturnItem = returedItemsData.map((i) => i.returnshipmentitem);
    if (row.original.invoice) {
      setShowInvoiceImg(row.original.invoice);
    }

    let defaultAddress =
      row.original.order &&
      addresses.findIndex((el) => el.id === row.original.order.address_id);
    let defaultEmail =
      row.original.email &&
      emails.findIndex((el) => el.id === row.original.email_id);

    let savedItems = 0;
    if (row.original)
      transactionDetail.forEach((t) => {
        if (t["new"] === undefined) {
          savedItems += 1;
        }
      });
    return (
      <form
        onSubmit={(event) => {
          submitInventory(event, row.original.transaction_id, row.original.id);
        }}
        style={{ overflowX: "scroll" }}
      >
        <div style={{ width: "95%", margin: "auto" }}>
          {row.original.giftcard && row.original.giftcard.length > 0 ? (
            <>
              <div className="row" style={{ background: "whitesmoke" }}>
                {row.original.giftcard.map((cards, i) => (
                  <>
                    {cards.card && (
                      <div className="col-sm-4">
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          Gift Card #{i + 1}
                        </span>
                        &nbsp;:&nbsp; {cards.card.card_no}
                      </div>
                    )}
                  </>
                ))}

                {/* <div className="col-sm-4">
                Gift Card #2
              </div>
              <div className="col-sm-4">
                Gift Card #3
              </div> */}
              </div>
              {/* <div className="row table" style={{margin: 'auto'}}>
                <div className="col-sm-4" >
                  {row.original.giftcard[0] ?row.original.giftcard[0].card.card_no : ''}
                </div>
                <div className="col-sm-4" style={{paddingleft: 30}}>
                {row.original.giftcard[1] ? row.original.giftcard[1].card.card_no : ''}
                </div>
                <div className="col-sm-4" style={{paddingLeft: 30}}>
                {row.original.giftcard[2] ? row.original.giftcard[2].card.card_no : ''}
                </div>
              </div> */}
            </>
          ) : null}
          <div className="row">&nbsp;</div>
          <div className="row">
            <div className="col-sm-4">
              <FieldGroup
                id="order_number"
                type="text"
                label="ORDER NUMBER "
                name="order_number"
                value={order ? order.order_number : ""}
                style={{minHeight:"45px"}}
                onChange={(e) => {
                  setOrderValues(e);
                }}
              />
            </div>

            <div className="col-sm-4">
              <div className="form-group">
                <label style={{ fontSize: "12px" }}>EMAIL</label>
                <Select
                  getOptionLabel={(option) =>
                    `${option.id ? option.email : ""}`
                  }
                  getOptionValue={(option) => option.id}
                  defaultValue={defaultEmail != -1 ? emails[defaultEmail] : ""}
                  className="new-font-size select-dropdown"
                  style={{ borderRadius: "5px" }}
                  options={emails}
                  onChange={handleChangeDropdown}
                  placeholder="Select Email"
                  name="selectedEmail"
                  isClearable={true}
                  isSearchable={true}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label style={{ fontSize: "12px" }}>ORDER STATUS</label>
                <select
                  className="form-control"
                  // disabled={!("new" in product) ? true : false}
                  name="status"
                  style={{minHeight:"45px"}}
                  onChange={setOrderValues}
                >
                  <option value="">-----</option>
                  {ORDER_STATUSES.map(({ value, label }) => {
                    return (
                      <option
                        key={value}
                        value={value}
                        selected={order && order.status === value}
                      >
                        {label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className={
                row.original.invoice === null ? "col-sm-10" : "col-sm-8"
              }
            >
              <label style={{ fontSize: "12px" }}>WAREHOUSE ADDRESS</label>
              <Select
                getOptionLabel={(option) =>
                  `${option.id ? option.address : ""}`
                }
                getOptionValue={(option) => option.id}
                className="new-font-size"
                style={{ borderRadius: "5px" }}
                options={addresses}
                defaultValue={
                  defaultAddress != -1 ? addresses[defaultAddress] : ""
                }

                // onChange={(e) => {
                //   setOrder({ ...order, address_id: e.id });
                // }}
                placeholder="Select Address"
                onChange={handleChangeDropdown}
                name="address_id"
                isClearable={true}
                isSearchable={true}
              />
            </div>
            {/* <div className="col-sm-2">
              <FieldGroup
                id="Taxes"
                type="number"
                label="Taxes"
                name="taxes"
                min='0'
                value={order ? order.taxes : 0}
                onChange={(e) => {
                  setOrder({ ...order, taxes: e.target.value })
                }}
              />
            </div>
            <div className="col-sm-2">
              <FieldGroup
                id="shipping"
                type="number"
                 min="0"
                label="Shipping"
                name="shippingNo"
                value={order ? order.shippingNo : 0}
                onChange={(e) =>{
                  setOrder({ ...order, shippingNo: e.target.value })
                }}
              />
            </div> */}

            <div
              className={
                row.original.invoice === null ? "col-sm-2" : "col-sm-4"
              }
              style={{ marginTop: 16 }}
            >
              {row.original.invoice === null ? (
                <Button
                  className="btn btn-lg ml-sm"
                  style={{
                    background: "#CEDCF7",
                    color: "black",
                    borderRadius: 5,
                    borderStyle: "hidden",
                  }}
                  // disabled= {row.source.source ? true : false}
                  onClick={() => {
                    setShowUploader(!showUploader);
                    setImageUploaderData({ DataId: row.id });
                  }}
                >
                  Upload Invoice <FiPaperclip />
                </Button>
              ) : (
                <div style={{ marginLeft: 10 }}>
                  <Button
                    className="btn btn-lg ml-sm"
                    style={{
                      background: "#CEDCF7",
                      color: "black",
                      borderRadius: 5,
                      borderStyle: "hidden",
                    }}
                    onClick={() => {
                      if (
                        row.original.invoice &&
                        row.original.invoice.extension === "pdf"
                      ) {
                        setShowPdf(true);
                        setPdfFileData(
                          row.original.invoice
                            ? row.original.invoice.source
                            : ""
                        );
                      } else {
                        openViewer();
                        setImageUploaderData({ DataId: row.id });
                      }
                    }}
                  >
                    Show Invoice <FiPaperclip />
                  </Button>

                  <Button
                    className="btn btn-lg ml-sm"
                    style={{
                      background: "#CEDCF7",
                      color: "black",
                      borderRadius: 5,
                      borderStyle: "hidden",
                    }}
                    onClick={() => {
                      setShowUploader(!showUploader);
                      setImageUploaderData({ DataId: row.id });
                    }}
                  >
                    Update Invoice <FiPaperclip />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Item <span style={{ color: 'red'}}> * </span> </th>
              <th>Qty Ordered <span style={{ color: 'red'}}> * </span>  </th>
              <th>Cost  <span style={{ color: 'red'}}> * </span>  </th>
              {/* <th>Taxes</th>
              <th>Shipping</th> */}
            </tr>
          </thead>
          <tbody>
            {transactionDetail &&
              transactionDetail.map(function(item, ind) {
                return (
                  <>
                    <tr key={ind}>
                      <td>
                        {!("new" in item) && (
                          <>
                            {/* <Button bsStyle="warning" bsSize="small" onClick={(e) => editItem(e, item, ind)}>Edit</Button>{' '} */}
                            {savedItems >= 2 ? (
                              <Button
                                bsStyle="danger"
                                bsSize="small"
                                onClick={(e) => deleteItem(item.id)}
                              >
                                Delete
                              </Button>
                            ) : null}
                          </>
                        )}
                      </td>
                      <td style={{ width: 500 }}>
                        <AsyncCreatableSelect
                          isMulti={false}
                          defaultOptions={inventories}
                          options={inventories}
                          maxMenuHeight={140}
                          // defaultValue={{id:item.inventory_id, name:'item'}}
                          defaultValue={
                            item.inventory_id
                              ? {
                                  id: item.inventory_id,
                                  name: returnColumnValueWithColumnMatch(
                                    inventories,
                                    "name",
                                    [item.inventory_id],
                                    "id"
                                  ),
                                }
                              : null
                          }
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          // defaultInputValue
                          loadOptions={loadOptions}
                          onChange={(selectedOption, name) =>
                            handleChangeDestination(selectedOption, name, ind)
                          }
                          className="selectUser"
                          placeholder="Select Inventory"
                          getNewOptionData={(inputValue, optionLabel) => ({
                            id: inputValue,
                            name: optionLabel,
                          })}
                        />
                      </td>
                      <td className="" style={{ position: "relative" }}>
                        <input
                          value={item.qty_ordered}
                          name="qty_ordered"
                          style={{ minHeight: 45}}
                          onChange={(e) => {
                            const { value } = e.target;
                            const transDetail = [...transactionDetail];
                            // if (!transDetail[ind].id) {
                            //   transDetail[ind].qty = Number(value)
                            // }
                            if (Number(value) > 0) {
                              transDetail[ind].qty_ordered = Number(value);
                            } else {
                              transDetail[ind].qty_ordered = null;
                            }
                            setTransactionDetail(transDetail);
                          }}
                          className="form-control leftPadding"
                          type="number"
                          min="0"
                        />
                      </td>
                      <td className="" style={{ position: "relative" }}>
                        <span
                          className="dollarSign"
                          //  style={{ marginTop: '6px' }}
                        >
                          $
                        </span>
                        <input
                          value={item.price}

                          name="price"
                          onChange={(e) => changeFormInv(e, ind)}
                          className="form-control leftPadding"
                          type="number"
                          min="0"
                          step="any"
                          style={{ paddingLeft: "30px" , minHeight: 45 }}
                        />{" "}
                      </td>
                      {/* <td
                        className="bigFieldSec"
                        style={{ position: "relative" }}
                      >
                        <span
                          className="dollarSign"
                          // style={{ marginTop: '6px' }}
                        >
                          $
                        </span>
                        <input
                          value={item.taxes}
                          name="taxes"
                          onChange={(e) => changeFormInv(e, ind)}
                          className="form-control leftPadding"
                          type="number"
                          min="0"
                          step="any"
                          style={{ paddingLeft: "30px" }}
                        />{" "}
                      </td> */}
                      {/* <td
                        className="bigFieldSec"
                        style={{ position: "relative" }}
                      >
                        <span
                          className="dollarSign"
                          // style={{ marginTop: '6px' }}
                        >
                          $
                        </span>
                        <input
                          value={item.shipping}
                          name="shipping"
                          onChange={(e) => changeFormInv(e, ind)}
                          className="form-control leftPadding"
                          type="number"
                          min="0"
                          step="any"
                          style={{ paddingLeft: "30px" }}
                        />{" "}
                      </td> */}
                    </tr>
                  </>
                );
              })}

            {shipment &&
              shipment.map(function(ship, ins) {
                return (
                  <tr>
                    <td colSpan="6">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>SHIPMENT</th>
                            <th>Item</th>
                            <th>Expected QTY</th>
                            <th>
                             { filterBy === "close" ? '' :
                              <button
                                type="button"
                                value="remove"
                                className="btn btn-sm btn-danger"
                                style={{ display: "flex", marginLeft: "auto" }}
                                onClick={() => {
                                  // removeShipmentRow(item, idx, ind)
                                  removeShipment(ins, ship.id);
                                }}
                              >
                                <i className="glyphicon glyphicon-remove"></i>
                                &nbsp;
                              </button>
                              }
                            </th>

                            {/* <th>courier</th>
                                  <th>tracking No.</th> */}
                            {/* <th>
                                    <button
                                      onClick={() => addShipmentRow(item, ind)}
                                      type='button'
                                      className='btn btn-sm btn-info'
                                      style={{ marginLeft: '30px' }}
                                    >
                                      <i className='glyphicon glyphicon-plus'></i>
                                      &nbsp;
                                    </button>
                                  </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {ship.items &&
                            ship.items.map((item, idx) => {
                              return (
                                <tr>
                                  {idx === 0 ? (
                                    <td className="smallFieldSec">
                                      <input
                                        value={ins + 1}
                                        name="id"
                                        //className='form-control leftPadding'
                                        disabled={idx > 0}
                                        //type="number"
                                        min="1"
                                        //step="any"
                                        style={{
                                          paddingLeft: "25px",
                                          width: "40px",
                                          border: "0px",
                                          fontWeight: 500,
                                        }}
                                        readOnly
                                      />{" "}
                                    </td>
                                  ) : (
                                    <td className="smallFieldSec"></td>
                                  )}
                                  <td className="bigFieldThird">
                                    <input
                                      value={
                                        item.inventory_id
                                          ? findInventory(item.inventory_id)
                                          : null
                                      }
                                      name={"inventory_id"}
                                      className="form-control leftPadding"
                                      style={{ paddingLeft: "15px" }}
                                      readOnly
                                    />
                                  </td>
                                  <td className="hundredField">
                                    <input
                                      value={item.qty_expected}
                                      name="qty_expected"
                                      onChange={
                                        (e) => {
                                          let details = [...shipment];
                                          if (Number(e.target.value) > 0) {
                                            details[ins].items[
                                              idx
                                            ].qty_expected = Number(
                                              e.target.value
                                            );
                                          } else {
                                            details[ins].items[
                                              idx
                                            ].qty_expected = null;
                                          }
                                          setShipment(details);
                                        }
                                        //updateShipment(e, shipment, idx, ind)
                                      }
                                      className="form-control leftPadding"
                                      type="number"
                                      min="0"
                                      // max={checkExpectedQuantity(
                                      //   item,
                                      //   idx + 1
                                      // )}
                                      step="any"
                                      style={{ paddingLeft: "15px" }}
                                    />{" "}
                                  </td>
                                  <td></td>

                                  {/* <td className='mediumFieldSec'>
                                          <select
                                            className='form-control'
                                            name='courier'
                                            onChange={(e) =>
                                              updateShipment(e, shipment, idx, ind)
                                            }
                                          >
                                            <option value=''>-----</option>
                                            {CARRIERS.map(({ value, label }) => {
                                              return (
                                                <option
                                                  key={value}
                                                  value={value}
                                                  selected={
                                                    shipment.courier === value
                                                  }
                                                >
                                                  {label}
                                                </option>
                                              )
                                            })}
                                          </select>{' '}
                                        </td>
                                        <td className='mediumFieldSec'>
                                          <input
                                            value={shipment.tracking_number}
                                            name='tracking_number'
                                            onChange={(e) =>
                                              updateShipment(e, item, idx, ind)
                                            }
                                            className='form-control leftPadding'
                                            type='text'
                                            min='0'
                                            step='any'
                                            style={{ paddingLeft: '15px' }}
                                          />{' '}
                                        </td>
                                        <td
                                          className='mediumFieldSec'
                                          style={{ position: 'relative' }}
                                        >
                                          <button
                                            type='button'
                                            value='remove'
                                            className='btn btn-sm btn-danger'
                                            style={{ marginLeft: '30px' }}
                                            onClick={() => {
                                              removeShipmentRow(item, idx, ind)
                                            }}
                                          >
                                            <i className='glyphicon glyphicon-remove'></i>
                                            &nbsp;
                                          </button>
                                        </td> */}
                                </tr>
                              );
                            })}
                          <tr>
                            <td colSpan="14">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div
                                  style={{
                                    flexDirection: "column",
                                    marginLeft: "75px",
                                  }}
                                >
                                  <th
                                    style={{
                                      fontSize: "12px",
                                      color: "#9a9a9a",
                                      fontWeight: 500,
                                    }}
                                  >
                                    COURIER <span style={{ color: 'red'}}> * </span>
                                  </th>
                                  {/* <input
                                    className='form-control leftPadding'
                                    style={{ marginTop: '2px', width: '100px' }}
                                  /> */}
                                  <select
                                    className="form-control"
                                    name="courier"
                                    onChange={(e) => updateShipment(e, ins)}
                                    required
                                  >
                                    <option value="">-----</option>
                                    {CARRIERS.map(({ value, label }) => {
                                      return (
                                        <option
                                          key={value}
                                          value={value}
                                          selected={ship.courier === value}
                                        >
                                          {label}
                                        </option>
                                      );
                                    })}
                                  </select>{" "}
                                </div>
                                <div
                                  style={{
                                    marginLeft: "20px",
                                    flexDirection: "column",
                                  }}
                                >
                                  {" "}
                                  <th
                                    style={{
                                      fontSize: "12px",
                                      color: "#9a9a9a",
                                      fontWeight: 500,
                                    }}
                                  >
                                    TRACKING NUMBER <span style={{ color: 'red'}}> * </span>
                                  </th>
                                  <input
                                    value={ship.tracking_number}
                                    name="tracking_number"
                                    type="text"
                                    required
                                    onChange={(e) => {
                                      updateShipment(e, ins);
                                    }}
                                    className="form-control leftPadding"
                                    style={{ width: "250px" }}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                );
              })}

            <tr>
              <td colSpan="14">
                {shipment.length === 0 && showReturnSec === false && (
                  <button
                    onClick={addRow}
                    type="button"
                    className="btn btn-sm btn-info"
                  >
                    <i className="glyphicon glyphicon-plus"></i>
                    &nbsp;
                  </button>
                )}
                {hasNew && shipment.length === 0 && (
                  <button
                    type="button"
                    value="remove"
                    className="ml-sm  btn btn-sm btn-danger"
                    onClick={handleRemoveItem}
                  >
                    <i className="glyphicon glyphicon-remove"></i>&nbsp;
                  </button>
                )}
                { filterBy === "close" ? '' :
                  <input
                    type="button"
                    value={"Add Shipment"}
                    onClick={() => {
                      appendShipment();
                    }}
                    className="btn btn-sm btn-info ml-sm"
                  />
                }
                {row.original.returnshipments.length
                  ? ""
                  : filterBy === "close" && (
                      <input
                        type="button"
                        value={"Add Return"}
                        onClick={() => {
                          setShowReturnSec(true);
                          handleReturnItems(row.original);
                        }}
                        className="btn btn-sm btn-info ml-sm"
                      />
                    )}
              </td>
            </tr>
            {/* return items on abnormal page */}

            {filterBy === "close" &&
              row.original.returnshipments.length > 0 &&
              filterReturnItem.length > 0 &&
              filterReturnItem.map((item, ind) => {
                return (
                  <>
                    {item.map((i, k) => {
                      return (
                        <tr>
                          <td colSpan="18">
                            <tr key={k}>
                              <td style={{ width: "240px", padding: "6px" }}>
                                <label
                                  style={{
                                    fontSize: "12px",
                                    color: "#9a9a9a",
                                    fontWeight: 500,
                                    padding: "2px 2px",
                                  }}
                                >
                                  Item Return
                                </label>

                                <input
                                  value={
                                    i.inventory_id
                                      ? findInventory(Number(i.inventory_id))
                                      : null
                                  }
                                  name={"inventory_id"}
                                  className="form-control leftPadding"
                                  style={{ paddingLeft: "15px" }}
                                  disabled={true}
                                />
                              </td>
                              <td>
                                <label
                                  style={{
                                    fontSize: "12px",
                                    color: "#9a9a9a",
                                    fontWeight: 500,
                                    padding: "2px 2px",
                                  }}
                                >
                                  Qty Return
                                </label>
                                <input
                                  value={i.return_item_qty}
                                  name="returnQty"
                                  style={{ width: "250px" }}
                                  disabled={true}
                                  className="form-control leftPadding"
                                  type="number"
                                  min="0"
                                />
                              </td>
                            </tr>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                );
              })}

            {filterBy === "close" &&
              row.original.returnshipments.length > 0 &&
              returedItemsData && (
                <tr>
                  <td colSpan="14">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: "-55px",
                      }}
                    >
                      <div
                        style={{ flexDirection: "column", marginLeft: "75px" }}
                      >
                        <th
                          style={{
                            fontSize: "12px",
                            color: "#9a9a9a",
                            fontWeight: 500,
                          }}
                        >
                          COURIER Return
                        </th>
                        <input
                          value={returedItemsData? returedItemsData[0].courier: ''}
                          name="courier"
                          type="text"
                          className="form-control leftPadding"
                          style={{ width: "250px" }}
                          disabled={true}
                        />
                      </div>
                      <div
                        style={{ marginLeft: "20px", flexDirection: "column" }}
                      >
                        <th
                          style={{
                            fontSize: "12px",
                            color: "#9a9a9a",
                            fontWeight: 500,
                          }}
                        >
                          RETURN TRACKING NUMBER
                        </th>
                        <input
                          value={returedItemsData? returedItemsData[0].tracking_no : ''}
                          name="trackingNumber"
                          type="text"
                          disabled={true}
                          className="form-control leftPadding"
                          style={{ width: "250px" }}
                        />
                      </div>
                      <div
                        style={{ marginLeft: "20px", flexDirection: "column" }}
                      >
                        <th
                          style={{
                            fontSize: "12px",
                            color: "#9a9a9a",
                            fontWeight: 500,
                          }}
                        >
                          RETURN RMA NUMBER
                        </th>
                        <input
                          value={returedItemsData ? returedItemsData[0].rma_no : ''}
                          name="rmaNumber"
                          type="number"
                          disabled={true}
                          className="form-control leftPadding"
                          style={{ width: "250px" }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            {/* end returns items */}

            {/* for add return item section */}
            {showReturnSec &&
              returnItemsDetail.length > 0 &&
              returnItemsDetail.map((item, ind) => {
                return (
                  <>
                    <tr>
                      <td colSpan="18">
                        <tr key={ind}>
                          <td style={{ width: "240px", padding: "6px" }}>
                            <label
                              style={{
                                fontSize: "12px",
                                color: "#9a9a9a",
                                fontWeight: 500,
                                padding: "2px 2px",
                              }}
                            >
                              Item
                            </label>
                            <input
                              value={
                                item.inventory_id
                                  ? findInventory(item.inventory_id)
                                  : null
                              }
                              name={"inventory_id"}
                              className="form-control leftPadding"
                              style={{ paddingLeft: "15px" }}
                              disabled={true}
                            />
                          </td>
                          <td>
                            <label
                              style={{
                                fontSize: "12px",
                                color: "#9a9a9a",
                                fontWeight: 500,
                                padding: "2px 2px",
                              }}
                            >
                              Qty Return <span style={{ color: 'red'}}> * </span>
                            </label>
                            <input
                              // value={initailRetunQty}
                              name="returnQty"
                              required
                              style={{ width: "250px" }}
                              onChange={(e) => {
                                const { value } = e.target;
                                const transDetail = [...returnItemsDetail];

                                if (Number(value) > 0) {
                                  transDetail[ind].qty_ordered = Number(value);
                                } else {
                                  transDetail[ind].qty_ordered = 0;
                                }
                                setReturnItemsDetail(transDetail);
                              }}
                              className="form-control leftPadding"
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                      </td>
                    </tr>
                  </>
                );
              })}

            {showReturnSec && (
              <tr>
                <td colSpan="14">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "-55px",
                    }}
                  >
                    <div
                      style={{ flexDirection: "column", marginLeft: "75px" }}
                    >
                      <th
                        style={{
                          fontSize: "12px",
                          color: "#9a9a9a",
                          fontWeight: 500,
                        }}
                      >
                        COURIER  <span style={{ color: 'red'}}> * </span>
                      </th>
                      <select
                        className="form-control"
                        name="courier"
                        required
                        onChange={(e) => {
                          const { value } = e.target;
                          const obj = {
                            ...returnItemObj,
                            courierReturn: value,
                          };
                          setReturnItemObj(obj);
                        }}
                      >
                        <option value="">-----</option>
                        {CARRIERS.map(({ value, label }) => {
                          return (
                            <option
                              key={value}
                              value={value}
                              // selected={ship.courier === value}
                            >
                              {label}
                            </option>
                          );
                        })}
                      </select>{" "}
                    </div>
                    <div
                      style={{ marginLeft: "20px", flexDirection: "column" }}
                    >
                      <th
                        style={{
                          fontSize: "12px",
                          color: "#9a9a9a",
                          fontWeight: 500,
                        }}
                      >
                        RETURN TRACKING NUMBER <span style={{ color: 'red'}}> * </span>
                      </th>
                      <input
                        value={returnItemObj.returnTrackingNumber}
                        name="trackingNumber"
                        type="text"
                        required
                        onChange={(e) => {
                          const obj = {
                            ...returnItemObj,
                            returnTrackingNumber: e.target.value,
                          };
                          setReturnItemObj(obj);
                        }}
                        className="form-control leftPadding"
                        style={{ width: "250px" }}
                      />
                    </div>
                    <div
                      style={{ marginLeft: "20px", flexDirection: "column" }}
                    >
                      <th
                        style={{
                          fontSize: "12px",
                          color: "#9a9a9a",
                          fontWeight: 500,
                        }}
                      >
                        RETURN RMA NUMBER <span style={{ color: 'red'}}> * </span>
                      </th>
                      <input
                        value={returnItemObj.returnRmaNumber}
                        name="rmaNumber"
                        type="number"
                        onChange={(e) => {
                          const obj = {
                            ...returnItemObj,
                            returnRmaNumber: e.target.value,
                          };
                          setReturnItemObj(obj);
                        }}
                        className="form-control leftPadding"
                        style={{ width: "250px" }}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {/* end return item section */}
            {/* for taxes and shipping */}
            <tr>
              <td colSpan={10}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    float: "right",
                  }}
                >
                  <div style={{ marginLeft: "20px", flexDirection: "column" }}>
                    <FieldGroup
                      id="Taxes"
                      type="number"
                      step="0.01"
                      label="Taxes"
                      name="taxes"
                      min="0"
                      value={order ? order.taxes : 0}
                      onChange={(e) => {
                        setOrder({ ...order, taxes: e.target.value });
                      }}
                    />
                  </div>

                  <div style={{ marginLeft: "20px", flexDirection: "column" }}>
                    <FieldGroup
                      id="shipping"
                      type="number"
                      step="0.01"
                      min="0"
                      label="Shipping"
                      name="shippingNo"
                      value={order ? order.shippingNo : 0}
                      onChange={(e) => {
                        setOrder({ ...order, shippingNo: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td colSpan="14">
                <span
                  className="pull-right"
                  style={{ position: "sticky", right: "5px" }}
                >
                  {/* { filterBy === undefined && order.order_number &&
                  <input
                    type="button"
                    value={"Attach Order No"}
                    className="btn btn-md btn-info mr-sm"
                    onClick={() => {
                       handleAttachOrderNo(row)
                      }}

                  />
                 } */}

                  {showReturnSec === false && (
                    <input
                      type="submit"
                      value={"Save"}
					  disabled = {submitted }
                      className="btn btn-md btn-info"
                    />
                  )}
                  {showReturnSec === true && filterBy === "close" && (
                    <input
                      type="button"
                      value={"Save Returns"}
                      onClick={() => {

                        handleReturnTransations();
                        setExpended({ 0: false });
                        setExpended1({ 0: false });
                      }}
                      className="btn btn-sm btn-primary ml-sm"
                    />
                  )}
                  <input
                    type="button"
                    value={"Cancel"}
                    onClick={() => {
                      // toggleExpanded(row.index, check)
                      setExpended({ 0: false });
                      setExpended1({ 0: false });
                      setShipment([]);
                    }}
                    className="btn btn-md btn-danger ml-sm"
                  />
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
      </form>
    );
  };

  const manipulateData = (row) => {
    return row.items.length > 0
      ? row.items.length === 1 &&
        row.items[0].inventory_id === null &&
        row.items[0].qty_ordered === 0
        ? [OC.TRANSACTIONDETAIL]
        : row.items.map((obj) => ({
            ...obj,
            order: { ...row.order },
            vendor: { ...row.vendor },
          }))
      : [OC.TRANSACTIONDETAIL];
  };

  async function expandChange(newExpanded, index, event, cellinfo, check) {
    const row = cellinfo.original;
    const transDet = await manipulateData(row);
    const returnItemsArray = cellinfo.original.items;
    const temparr = cloneDeep(returnItemsArray);
    setCurrentTran(row);
    setTransactionDetail(transDet);
    setReturnItemsDetail(temparr);
    toggleExpanded(cellinfo.index, check);

    const object = {
      order_number:
        row.order && row.order.order_number ? row.order.order_number : "",
      tracking_number:
        row.order && row.order.tracking_number ? row.order.tracking_number : "",
      shipping_address:
        row.order && row.order.shipping_address
          ? row.order.shipping_address
          : "",
      zipcode: row.order && row.order.zipcode ? row.order.zipcode : "",
      state: row.order && row.order.state ? row.order.state : "",
      city: row.order && row.order.city ? row.order.city : "",
      status: row.order && row.order.status ? row.order.status : "",
      address_id: row.order && row.order.address_id ? row.order.address_id : "",
      taxes: row.order && row.order.taxes ? row.order.taxes : 0,
      shippingNo: row.order && row.order.shippingNo ? row.order.shippingNo : 0,
    };
    setOrder(object);
    setShipment(cellinfo.original.shipments);
    setEmailId(row.email_id)
  }

  async function expandChangeOpen(newExpanded, index, event, cellinfo, check) {
    const row = cellinfo.original;
    const transDet = await manipulateData(row);

    setTransactionDetail(transDet);

    setCurrentTran(row);
    const selectTran = transactionDetailOpen[index[0]];
    const empty_object = {
      order_number: selectTran.order_number,
      tracking_number: selectTran.tracking_number,
      shipping_address: selectTran.order && selectTran.order.shipping_address,
      zipcode: selectTran.order && selectTran.order.zipcode,
      state: selectTran.order && selectTran.order.state,
      city: selectTran.order && selectTran.order.city,
      status: selectTran.order && selectTran.order.status,
    };
    setOrder({ ...empty_object });

    if (row.shipments !== undefined && row.shipments.length > 0) {
      setShipment(row.shipments);
    } else {
      setShipment([]);
    }
    toggleExpanded(cellinfo.index, check);
  }

  const toggleExpanded = (index, check) => {
    if (check === "unmarked") {
      // const existingExpandValue = Object.values(expanded).indexOf(true) >= 0;
      // setExpended({ [index]: true });
      const existingExpandNumber = Object.keys(expanded);
      let existingExpandValue = Object.values(expanded).indexOf(true) >= 0;
      if (existingExpandNumber[0] == index) {
        existingExpandValue = !existingExpandValue;
      } else {
        existingExpandValue = true;
      }
      setExpended({ [index]: existingExpandValue });
    } else if (check === "other") {
      const existingExpandValue = Object.values(expanded1).indexOf(true) >= 0;
      setExpended1({ [index]: !existingExpandValue });
    }
  };

  useEffect(() => {
    if (transactionDetail) {
      var result = transactionDetail.some((e) => e.hasOwnProperty("new"));
      const transaction = transactionDetail[0];

      if (!vendor) {
        setVendor(transaction.vendor);
      }
      if (!order) {
        setOrder(transaction.order);
      }

      setHasNew(result);
    }
  }, [transactionDetail]);

  const getInventoriesAsyncFunction = (data = null) => {
    transactionServices.getInventoriesAsync(data).then((response) => {
      setInventories(response);
    });
  };

  const getAllAddressData = (data = null) => {
    addresServices.getAllAddress(data).then((response) => {
      if (response.data) {
        setAddresses(response.data.pagination);
      }
    });
  };
  const getAllEmails = (data = null) => {
    emailServices.getAllEmails(data).then((response) => {
      if (response.data) {
        setEmails(response.data.pagination);
      }
    });
  };

  const createNewInventories = async (data) => {
    let res = await transactionServices.createNewItems(data);
    if (res) {
      setInventories(res);
    }
  };

  // const promiseOptions = (inputValue) =>
  //   new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve(
  //         transactionServices.getInventoriesAsync({ inputValue: inputValue })
  //       )
  //     }, 1000)
  //   })

  const filterInventories = (inputValue) => {
    return inventories.filter((i) =>
      i.name.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterInventories(inputValue));
    }, 500);
  };

  const handleChangeDestination = (selectedOption, name, index) => {
    const selectedITem = transactionDetail[index];

    const item_name = selectedOption["name"];

    if (name.action === "select-option") {
      const temp = cloneDeep(transactionDetail);
      temp[index]["inventory_id"] = selectedOption.id;
      // temp[index].shipments = newItems
      setTransactionDetail(temp);
    }

    if (name.action === "create-option") {
      transactionServices
        .storeInventory({ name: selectedOption.name })
        .then((response) => {
          dispatch(alertActions.success(response.message));
          const temp = cloneDeep(transactionDetail);
          temp[index]["inventory_id"] = response.inventory.id;
          // temp[index].shipments = newItems
          setTransactionDetail(temp);
          getInventoriesAsyncFunction();
        },
				(error) => {
					dispatch(alertActions.error(error));
				});
    }
  };

  const getRowProps = (rowInfo) => {
    return {
      className:
        rowInfo.transaction_type.name === "inventory"
          ? "showExpand"
          : "showExpand", //'hideExpand',
    };
  };

  const getCheckRefund = (rowInfo) => {
    let check = false;

    if (rowInfo.transaction_type.name === "refund") {
      check = true;
    }

    return check;
  };

  const detectChanges = (data, flag) => {
    if (flag === "pagination") {
      setPaginationBulkAction(data.length > 0 ? true : false);
      // return data.length;
    } else if (flag === "paginationOpen") {
      setPaginationOpenBulkAction(data.length > 0 ? true : false);
      // return data.length > 0
    }
  };

  useEffect(() => {
    plaidServices.getLinkPlaid("credit_card").then((response) => {
      getAccountsStore(response.link.link_token);
    });
  }, []);

  function getAccountsStore(cardLink, publicTokenObjectUpdated = false) {
    plaidServices.getBanks(data).then((response) => {
      setState({
        ...state,
        accountModal: {
          ...state.accountModal,
          show: false,
          bank_id: null,
        },
        banks: response.banks,
        cardLink: cardLink ? cardLink : state.cardLink,
        publicTokenObjectUpdated: publicTokenObjectUpdated,
      });
    });
  }

  useEffect(() => {
    if (state.publicTokenObjectUpdated) {
      setPublicTokenObject(null);
      plaidServices
        .exchangeToken({ publicTokenObject: state.publicTokenObject })
        .then((res) => {
          getAccountsStore(null, false);
        });
    }
  }, [state]);

  useEffect(() => {
    plaidServices.getBankAccounts();
  }, []);

  const refreshTransections = () => {
    plaidServices.fetchTransection().then((respnse) => {
      // window.location.reload();
    });
  };
  const manualTransactionBtn = () => {
    return (
      <Import
        file={handleManualTransaction}
        btnName="Import Manual Transaction"
      />
    );
  };
  const deleteBank = (id) => {
    plaidServices.deteleBank(id).then((response) => {
      getAccountsStore();
    });
  };

  const handleChangeDropdown = (selectedOptionType, info) => {
    if (info["action"] === "select-option" && info["name"] === "selectedBank") {
      setSelectedBank({ ...selectedOptionType });
      setBankAccounts([...selectedOptionType.accounts]);
      setSelectedAccount();
    }

    if (
      info["action"] === "select-option" &&
      info["name"] === "selectedAccount"
    ) {
      setSelectedAccount({ ...selectedOptionType });
    }

    if (
      info["action"] === "select-option" &&
      info["name"] === "transactionAccount"
    ) {
      setTransactionAccount(selectedOptionType.label);
    }

    if (
      info["action"] === "select-option" &&
      info["name"] === "selectedEmail"
    ) {
      setEmailId(selectedOptionType.id);
    }

    // to clear the address and email field
    if ( info["action"] === "select-option" && info["name"] === "address_id" ) {
      setOrder({ ...order, address_id: selectedOptionType.id });
    }

    if ( info["action"] === "clear" && info["name"] === "address_id" ) {
      setOrder({ ...order, address_id: '' });
    }

    if ( info["action"] === "clear" && info["name"] === "selectedEmail" ) {
      setEmailId('');
    }

    if (info["action"] === "clear" && info["name"] === "selectedBank") {
      setSelectedBank(null);
      setSelectedAccount(null);
    }

    if (info["action"] === "clear" && info["name"] === "transactionAccount") {
      setTransactionAccount();
    }

    if (info["action"] === "clear" && info["name"] === "selectedAccount") {
      setSelectedAccount(null);
    }
    // pagination.current.dataCall(1)
  };

  const handleChangeDropdownTwo = (selectedOptionType, info) => {
    if (
      info["action"] === "select-option" &&
      info["name"] === "selectedBankTwo"
    ) {
      setSelectedBankTwo({ ...selectedOptionType });
      setBankAccountsTwo([...selectedOptionType.accounts]);
    }

    if (
      info["action"] === "select-option" &&
      info["name"] === "selectedAccountTwo"
    ) {
      setSelectedAccountTwo({ ...selectedOptionType });
    }

    if (info["action"] === "clear" && info["name"] === "selectedBankTwo") {
      setSelectedBankTwo({});
    }

    if (info["action"] === "clear" && info["name"] === "selectedAccountTwo") {
      setSelectedAccountTwo({});
    }
    // pagination.current.dataCall(1)
  };

  const { cardLink, banks, accountDateModal, bankCollapse } = state;

  // const onSuccess = (token, metadata) => {
  //   setPublicTokenObject(state.publicTokenObject);
  //   setState({
  //     ...state,
  //     publicTokenObject: metadata,
  //     publicTokenObjectUpdated: true,
  //   });
  // };

  return (
    <div>
      <Grid fluid>
        <Row>
          <Card
            ctTableResponsive
            content={
              <div>
                <Grid fluid>
                  <Row>
                    <Col>
                      <Col md={6} lg={6}>
                        <div className="secHeading">Transaction State Page</div>
                      </Col>
                      <Col md={6} lg={6}>
                        <div className="secHeading">
                          <ul
                            style={{
                              listStyle: "none",
                              display: "flex",
                              justifyContent: "right",
                            }}
                          >
                            <li style={{ marginRight: "10px" }}>
                              {/* <button
                                className="btn headingBtn"
                                title="Refresh Transaction"
                                onClick={() => refreshTransections()}
                              >
                                <span className="fa fa-repeat"></span>
                              </button> */}
                            </li>
                            <li>
                              {/* <DropdownButton
                                bsStyle="default"
                                noCaret
                                onToggle={() => setOpenToogle(!openToogle)}
                                open={openToogle}
                                pullRight
                                role="menu"
                                className="btn headingBtn"
                                id="dropdown-no-caret"
                                title={
                                  <span className="fa fa-ellipsis-v"></span>
                                }
                                // title={ <span className="fa fa-list"></span>}
                              >  */}
                              {/* <label htmlFor="file" className="btn menu" onClick={() => setOpenToogle(false)}>

                                  <Import handleFile={handleFile} />
                                </label> */}

                              {/* <label className="btn menu" htmlFor="file2" onClick={() => setOpenToogle(false)}>
                                  {" "}
                                  <ImportManual
                                    file={handleManualTransaction}
                                    btnName="Import Manual Transaction"
                                  />
                                </label> */}

                              {/* <label
                                  className="btn menu"
                                  id="dropdown-autoclose-inside"
                                  onClick={() =>{
                                    downloadTemplate()
                                    setOpenToogle(false)
                                   }}
                                >
                                  {" "}
                                  <span style={{ color: "black" }}>
                                    {" "}
                                    Download Template{" "}
                                  </span>
                                </label> */}

                              {/* </MenuItem> */}
                              {/* </DropdownButton> */}
                            </li>
                          </ul>
                        </div>
                      </Col>
                    </Col>
                    <Col>
                      {/* {pagination.current &&
                                pagination.current.state.checkedIds.length >
                                  0 && (
                                    <ul className="dropDown_btns"> */}
                      {/* <li> */}
                      {/* <DropdownButton
                                    // bsStyle="selectType"
                                    title={title}
                                    className="selectType"
                                    style={{fontWeight:'500',padding:"12px 21px"}}
                                     id="dropdown-basic-Default`"
                                    >  */}
                      {/*
                                    <MenuItem eventKey="2" onClick={() => {
                                      //  setTitle("Inventory")
                                       onChangeMultipleUnmark("1")}
                                    }

                                      >

                                        Inventory
                                    </MenuItem>   */}
                      {/* <MenuItem eventKey="3" onClick={() => {
                                      setTitle("Refund")
                                      onChangeMultipleUnmark("2")}}>
                                        Refund
                                    </MenuItem>   */}
                      {/* <MenuItem eventKey="4" onClick={() =>
                                      {
                                      // setTitle("Business")
                                      onChangeMultipleUnmark("3")
                                      }
                                     }>
                                       Business
                                    </MenuItem>  */}
                      {/* <MenuItem eventKey="5" onClick={() => {
                                      //  setTitle("Non Business")
                                      onChangeMultipleUnmark("4")}}>
                                      Non Business
                                    </MenuItem>    */}

                      {/* </DropdownButton> */}
                      {/* </li> */}
                      {/* <li> */}
                      {/* <DropdownButton
                                    // bsStyle="selectType"
                                    title={title2}
                                    className="selectType"
                                    style={{fontWeight:'500',padding:"12px 21px"}}
                                    //  id="dropdown-basic-Default`"
                                    >  */}
                      {/* <MenuItem eventKey="1" onClick={() => {
                                      // setTitle2("Export transaction ")
                                      exportToCSV("unmarked")}}>
                                        Export transaction
                                    </MenuItem>    */}
                      {/* <MenuItem eventKey="2"  className="submenu" onClick={() => {
                                      //  setTitle2("Select Account ")
                                       }
                                    }

                                      >

                                        Select Account<span className="fa fa-caret-right"></span>
                                       <MenuItem>
                                        <ul role="menu" className="dropdown-menu subitems" >
                                         {options && options.map((option)=>(
                                           <li role="presentation" key={option.id} onClick={()=> onChangeMultipleUnmark(option.id)}>
                                           <a role="menuitem"> {option.name} </a>
                                            </li>
                                         ))}


                                        </ul>
                                        </MenuItem>
                                    </MenuItem>   */}

                      {/* <MenuItem eventKey="3" disabled={filterBy === 'open'? false: true} onClick={() => {
                                      // setTitle2("Marked As Received")
                                      markAsReceived();
                                     }}>
                                        Mark As received in full
                                    </MenuItem>   */}
                      {/* <MenuItem eventKey="4" disabled onClick={() =>
                                      {
                                      // setTitle2("Merge Transactions")

                                      }
                                     }>
                                       Merge Transactions
                                    </MenuItem>  */}

                      {/* </DropdownButton> */}
                      {/* </li> */}
                      {/* </ul> */}

                      {/* )} */}
                    </Col>
                    {/* <Col> */}
                    {/* <Row
                        style={{
                          width: "100%",
                          margin: "auto",
                          marginBottom: "20px",
                        }}
                      > */}
                    {/* <Col md={6}> */}
                    {/* <ul
                            style={{ listStyle: "none", marginLeft: "-38px" }}
                          > */}
                    {/* import   */}
                    {/* <li
                              style={{
                                display: "inline-block",
                                float: "left",
                                marginRight: "10px",
                                height: "50px",
                              }}
                            >
                              <Import handleFile={handleFile} />
                            </li> */}
                    {/* export */}
                    {/* <li
                              style={{
                                display: "inline-block",
                                marginRight: "10px",
                                height: "50px",
                                float: "left",
                              }}
                            >
                              {pagination.current &&
                                pagination.current.state.checkedIds.length >
                                  0 && (
                                  <label
                                    className="btn btn-default btn-lg headBtn"
                                    onClick={() => exportToCSV("unmarked")}
                                  >
                                    <span style={{ color: "black" }}>
                                      {" "}
                                      Export{" "}
                                    </span>
                                  </label>
                                )}
                            </li> */}
                    {/* Select type */}
                    {/* <li
                              style={{
                                display: "inline-block",
                                marginRight: "10px",
                                height: "50px",
                                float: "left",
                              }}
                            > */}
                    {/* {pagination.current &&
                                pagination.current.state.checkedIds.length >
                                  0 && (
                                  <label className="btn btn-default btn-lg headBtn">
                                    <select
                                      defaultValue={"DEFAULT"}
                                      onChange={(e) =>
                                        onChangeMultipleUnmark(e)
                                      }

                                      // className="slct"
                                      // style={{
                                      //   // background: "white",
                                      //   width: "100%",
                                      //   border: "none",
                                      //   cursor: "pointer",
                                      //   // fontSize: "23px",
                                      //   color: "black",
                                      // }}
                                    >
                                      <option value="DEFAULT" disabled>
                                        Select Type
                                      </option>
                                      {options &&
                                        options.map((option, i) => (
                                          <option
                                            key={i}
                                            value={option.id}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                    </select>
                                  </label>
                                )} */}
                    {/* </li>
                          </ul> */}
                    {/* </Col> */}

                    {/* <Col md={6}> */}
                    {/* <div style={{ width: "100%" }}> */}
                    {/* <div
                              style={{
                                display: "inline-block",
                                marginRight: "10px",
                                float: "right",
                                height: "50px",
                              }}
                            >
                              <label
                                className="btn btn-default btn-sm trans_lbl"
                                onClick={() => refreshTransections()}
                              >
                                {" "}
                                Refresh Transactions
                              </label>
                            </div> */}

                    {/* <div
                              style={{
                                display: "inline-block",
                                marginRight: "10px",
                                float: "right",
                                height: "50px",
                              }}
                            >
                              <label
                                className="btn btn-default btn-sm trans_lbl"
                                onClick={downloadTemplate}
                              >
                                {" "}
                                Download Template
                              </label>
                            </div> */}

                    {/* <div
                              style={{
                                display: "inline-block",
                                marginRight: "10px",
                                float: "right",
                                height: "50px",
                              }}
                            >
                              <label
                                className="btn btn-default btn-sm trans_lbl"
                                htmlFor="file2"
                              >
                                {" "}
                                <ImportManual
                                  file={handleManualTransaction}
                                  btnName="Import Manual Transaction"
                                />
                              </label>
                            </div> */}

                    {/* <div
                             style={{
                               display: 'inline-block',
                               marginRight: '10px',
                               float: 'right',
                               height: '50px',
                             }}
                           >
                             <label
                               className='btn btn-default btn-sm trans_lbl'
                               htmlFor='file2'
                             >
                               {' '}
                               <ImportManual
                                 file={handleManualTransaction}
                                 btnName='Import Manual Transaction'
                               />
                             </label>
                           </div> */}

                    {/* here */}
                    {/* </div> */}
                    {/* </Col> */}
                    {/* </Row> */}
                    {/* </Col> */}

                    <Col md={12}></Col>

                    {/* <hr /> */}
                    {/* {pagination.current &&
                      pagination.current.state.checkedIds.length > 0 && (
                        <Col md={2}>
                          <div style={{ marginLeft: 30 }}>
                            <select
                              defaultValue={"DEFAULT"}
                              onChange={(e) => onChangeMultipleUnmark(e)}
                              className="form-control"
                            >
                              <option value="DEFAULT" disabled>
                                Select Type
                              </option>
                              {options &&
                                options.map((option, i) => (
                                  <option key={i} value={option.id}>
                                    {option.label}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </Col>
                      )} */}
                    {/* {pagination.current &&
                      pagination.current.state.checkedIds.length > 0 && (
                        <Col md={2}>
                          <div style={{ marginLeft: 30 }}>
                            <Button onClick={() => exportToCSV("unmarked")}>
                              Export
                            </Button>
                          </div>
                        </Col>
                      )} */}
                    <Col md={2}>{/* <Import handleFile={handleFile} /> */}</Col>
                    {dataModal && (
                      <Modal
                        backdrop={"static"}
                        show={dataModal}
                        onHide={() => setDataModal(false)}
                        aria-labelledby="ModalHeader"
                        dialogClassName="fullscreenmodal"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title
                            id="ModalHeader"
                            className="headerTitle"
                            style={{ width: 800 }}
                          >
                            Import Data Preview
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card
                                bsClass={["innerCard mb-none"]}
                                content={<Accordion data={data} />}
                              />
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            bsStyle="danger"
                            onClick={() => setDataModal(false)}
                            style={{
                              border: "white",
                              color: "white",
                              backgroundColor: "#EE2D20",
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            bsStyle="primary"
                            style={{
                              border: "white",
                              color: "white",
                              backgroundColor: "#2F4576",
                            }}
                            onClick={() => importDataSubmit(data)}
                          >
                            Upload
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    )}
                    <Col md={12}>
                      <div className="panel panel-default">
                        {/* <div className="panel-heading"> */}
                        <div
                          className="row"
                          style={{ margin: "auto", background: "#F7F8FA" }}
                        >
                          <div className="col-md-8">
                            <ul className="tabsLst">
                              <li>
                                <div
                                  className="btn btn-default btn-sm  tab_lbl"
                                  style={{
                                    marginBottom: -1,
                                    backgroundColor: unmark && "#006EEB",
                                    color: unmark && "white",
                                  }}
                                  onClick={() => {
                                    setAll(false);
                                    setOpen(false);
                                    setClose(false);
                                    setUnmark(true);
                                    setTerms(false);
                                    setFilterBy();
                                    setStartDate(startDateDefault);
                                    setEndDate(today);
                                    setSelectedBank(null);
                                    setSelectedAccount(null);
                                    setSelectedIds([]);
                                    setExpended({ 0: false });
                                    setExpended1({ 0: false });
                                  }}
                                >
                                  Unmarked
                                </div>
                              </li>

                              <li>
                                <div
                                  className="btn btn-default btn-sm  tab_lbl"
                                  style={{
                                    marginBottom: -1,
                                    backgroundColor: open && "#006EEB",
                                    color: open && "white",
                                  }}
                                  onClick={() => {
                                    setAll(false);
                                    setOpen(true);
                                    setClose(false);
                                    setUnmark(false);
                                    setFilterBy("open");
                                    setTerms(false);
                                    setStartDate(startDateDefault);
                                    setEndDate(today);
                                    setSelectedBank(null);
                                    setSelectedAccount(null);
                                    setSelectedIds([]);
                                    setExpended({ 0: false });
                                    setExpended1({ 0: false });
                                  }}
                                >
                                  Open
                                </div>
                              </li>
                              <li>
                                <div
                                  className="btn btn-default btn-sm  tab_lbl"
                                  style={{
                                    marginBottom: -1,
                                    backgroundColor: close && "#006EEB",
                                    color: close && "white",
                                  }}
                                  onClick={() => {
                                    setAll(false);
                                    setOpen(false);
                                    setClose(true);
                                    setUnmark(false);
                                    setTerms(false);
                                    setFilterBy("close");
                                    setStartDate(startDateDefault);
                                    setEndDate(today);
                                    setSelectedBank(null);
                                    setSelectedAccount(null);
                                    setSelectedIds([]);
                                    setExpended({ 0: false });
                                    setExpended1({ 0: false });
                                  }}
                                >
                                  Closed
                                </div>
                              </li>
                              <li>
                                <div
                                  className="btn btn-default btn-sm tab_lbl"
                                  style={{
                                    marginBottom: -1,
                                    backgroundColor: all && "#006EEB",
                                    color: all && "white",
                                  }}
                                  onClick={() => {
                                    setAll(true);
                                    setOpen(false);
                                    setClose(false);
                                    setUnmark(false);
                                    setTerms(false);
                                    setFilterBy("show_all");
                                    setStartDate(startDateDefault);
                                    setEndDate(today);
                                    setSelectedBank(null);
                                    setSelectedAccount(null);
                                    setTransactionAccount();
                                    setSelectedIds([]);
                                    setExpended({ 0: false });
                                    setExpended1({ 0: false });
                                  }}
                                >
                                  All
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-4">
                            <ul
                              style={{
                                justifyContent: "right",
                                listStyle: "none",
                                display: "flex",
                              }}
                              // className="tabsLst"
                            >
                              <li style={{ marginRight: "10px" }}>
                                <button
                                  className="btn headingBtn tab_btn"
                                  title="Refresh Transaction"
                                  onClick={() => refreshTransections()}
                                >
                                  <span className="fa fa-repeat"></span>
                                </button>
                              </li>
                              <li>
                                <DropdownButton
                                  bsStyle="default"
                                  noCaret
                                  onToggle={() => setOpenToogle(!openToogle)}
                                  open={openToogle}
                                  pullRight
                                  role="menu"
                                  className="btn headingBtn tab_btn"
                                  id="dropdown-no-caret"
                                  title={
                                    <span className="fa fa-ellipsis-v"></span>
                                  }
                                  // title={ <span className="fa fa-list"></span>}
                                >
                                  <label
                                    htmlFor="file"
                                    className="btn menu"
                                    onClick={() => setOpenToogle(false)}
                                  >
                                    <Import handleFile={handleFile} />
                                  </label>

                                  <label
                                    className="btn menu"
                                    htmlFor="file2"
                                    onClick={() => setOpenToogle(false)}
                                  >
                                    {" "}
                                    <ImportManual
                                      file={handleManualTransaction}
                                      btnName="Import Manual Transaction"
                                    />
                                  </label>

                                  <label
                                    className="btn menu"
                                    onClick={() => {
                                      handleAllExportTransaction(
                                        exportAllTransactionObj,
                                        "unmarked"
                                      );
                                      setOpenToogle(false);
                                    }}
                                  >
                                    <span style={{ color: "black" }}>
                                      Export all from Filter
                                    </span>
                                  </label>

                                  <label
                                    className="btn menu"
                                    id="dropdown-autoclose-inside"
                                    onClick={() => {
                                      handleDownloadAllInvoiceSource();
                                      setOpenToogle(false);
                                    }}
                                  >
                                    {" "}
                                    <span style={{ color: "black" }}>
                                      Download All Invoice
                                    </span>
                                  </label>

                                  <label
                                    className="btn menu"
                                    id="dropdown-autoclose-inside"
                                    onClick={() => {
                                      downloadTemplate();
                                      setOpenToogle(false);
                                    }}
                                  >
                                    {" "}
                                    <span style={{ color: "black" }}>
                                      {" "}
                                      Download Manual Transaction Template{" "}
                                    </span>
                                  </label>
                                </DropdownButton>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* </div> */}
                        {!filterBy && (
                          <TrasactionTable
                            pagination={pagination}
                            columns={columns}
                            paginationCall={paginationCall}
                            // row={row}
                            inventories={inventories}
                            emails={emails}
                            addresses={addresses}
                            toggleExpanded={toggleExpanded}
                            setExpended={setExpended}
                            setExpended1={setExpended1}
                            setRefreshEverythingElseData={
                              setRefreshEverythingElseData
                            }
                            detectChanges={detectChanges}
                            setSelectedIds={setSelectedIds}
                            ids={ids}
                            startDate={startDate}
                            expandChange={expandChange}
                            banks={banks}
                            expanded={expanded}
                            today={today}
                            startDateDefault={startDateDefault}
                            handleChangeDropdown={handleChangeDropdown}
                            page={0}
                            selectedBank={selectedBank}
                            showItems={showItems}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            filterBy={filterBy}
                            selectedAccount={selectedAccount}
                            bankAccounts={bankAccounts}
                            options={options}
                            title2={title2}
                            exportToCSV={exportToCSV}
                            onChangeMultipleUnmark={onChangeMultipleUnmark}
                            markAsReceived={markAsReceived}
                          />
                        )}
                        {filterBy === "open" && (
                          <TrasactionTable
                            pagination={pagination}
                            columns={columns}
                            paginationCall={paginationCall}
                            // row={row}
                            inventories={inventories}
                            emails={emails}
                            addresses={addresses}
                            toggleExpanded={toggleExpanded}
                            setExpended={setExpended}
                            setExpended1={setExpended1}
                            setRefreshEverythingElseData={
                              setRefreshEverythingElseData
                            }
                            detectChanges={detectChanges}
                            setSelectedIds={setSelectedIds}
                            ids={ids}
                            startDate={startDate}
                            expandChange={expandChange}
                            banks={banks}
                            expanded={expanded}
                            today={today}
                            startDateDefault={startDateDefault}
                            handleChangeDropdown={handleChangeDropdown}
                            page={0}
                            selectedBank={selectedBank}
                            showItems={showItems}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            filterBy={filterBy}
                            selectedAccount={selectedAccount}
                            bankAccounts={bankAccounts}
                            options={options}
                            title2={title2}
                            exportToCSV={exportToCSV}
                            onChangeMultipleUnmark={onChangeMultipleUnmark}
                            markAsReceived={markAsReceived}
                          />
                        )}
                        {filterBy === "close" && (
                          <TrasactionTable
                            pagination={pagination}
                            columns={columns}
                            paginationCall={paginationCall}
                            // row={row}
                            inventories={inventories}
                            emails={emails}
                            addresses={addresses}
                            toggleExpanded={toggleExpanded}
                            setExpended={setExpended}
                            setExpended1={setExpended1}
                            setRefreshEverythingElseData={
                              setRefreshEverythingElseData
                            }
                            detectChanges={detectChanges}
                            setSelectedIds={setSelectedIds}
                            ids={ids}
                            startDate={startDate}
                            expandChange={expandChange}
                            banks={banks}
                            expanded={expanded}
                            today={today}
                            startDateDefault={startDateDefault}
                            handleChangeDropdown={handleChangeDropdown}
                            page={0}
                            selectedBank={selectedBank}
                            showItems={showItems}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            filterBy={filterBy}
                            selectedAccount={selectedAccount}
                            bankAccounts={bankAccounts}
                            options={options}
                            title2={title2}
                            exportToCSV={exportToCSV}
                            onChangeMultipleUnmark={onChangeMultipleUnmark}
                            markAsReceived={markAsReceived}
                          />
                        )}

                        {filterBy === "show_all" && (
                          <TrasactionTable
                            pagination={pagination}
                            columns={columns}
                            paginationCall={paginationCall}
                            // row={row}
                            inventories={inventories}
                            emails={emails}
                            addresses={addresses}
                            toggleExpanded={toggleExpanded}
                            setExpended={setExpended}
                            setExpended1={setExpended1}
                            setRefreshEverythingElseData={
                              setRefreshEverythingElseData
                            }
                            detectChanges={detectChanges}
                            setSelectedIds={setSelectedIds}
                            ids={ids}
                            startDate={startDate}
                            expandChange={expandChange}
                            banks={banks}
                            expanded={expanded}
                            today={today}
                            startDateDefault={startDateDefault}
                            handleChangeDropdown={handleChangeDropdown}
                            page={0}
                            selectedBank={selectedBank}
                            showItems={showItems}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            filterBy={filterBy}
                            selectedAccount={selectedAccount}
                            bankAccounts={bankAccounts}
                            options={options}
                            title2={title2}
                            exportToCSV={exportToCSV}
                            onChangeMultipleUnmark={onChangeMultipleUnmark}
                            markAsReceived={markAsReceived}
                          />
                        )}
                      </div>
                    </Col>

                    {/* This modal is used to open image uploader */}

                    {showUploader && (
                      <ImageUploaderModal
                        open={showUploader}
                        setOpen={() => {
                          setShowUploader(false);
                        }}
                        imageUploaderData={imageUploaderData}
                        setImageUploaderData={() => {
                          setImageUploaderData({ DataId: "" });
                        }}
                        hidenotes={true}
                        stateData={invoiceImageData}
                        setStateData={setIvoiceImageData}
                      />
                    )}

                    {showImageViewer && (
                      <ImageViewerModal
                        open={showImageViewer}
                        setOpen={() => {
                          setShowImageViewer(false);
                        }}
                        imageUploaderData={imageUploaderData}
                        setImageUploaderData={() => {
                          setImageUploaderData({ DataId: "" });
                        }}
                        invoiceImg={true}
                        stateData={showInvoiceImg}
                        setStateData={setShowInvoiceImg}
                      />
                    )}

                    {/* for pdf viewer */}
                    {showPdf && pdfFileData && (
                      <PdfViwer
                        pdfFile={pdfFileData}
                        open={showPdf}
                        setOpen={() => {
                          setShowPdf(false);
                        }}
                      />
                    )}

                    {/* removed unused code */}
                  </Row>
                </Grid>
              </div>
            }
          />
        </Row>
      </Grid>
    </div>
  );
}

export default TransactionStatusList;
