import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import HeaderLinks from "components/Admin/Header/HeaderLinks";
import { basePathClient } from "helper/basePathClient";
import {
  // Tooltip,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import dashboardRoutes from "routes/dashboard.jsx";

class Header extends Component {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.getAlert = this.getAlert.bind(this);
    this.toggleHeaderMenu = this.toggleHeaderMenu.bind(this);
    this.state = {
      sidebarExists: true,
      canvasMenuToggle: false,
      // toggleIcon: false,
    };
  }
  getAlert() {
    alert("getAlert from Child");
  }

  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === true) {
      this.setState({
        sidebarExists: true,
      });
    }
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  }
  getBrand() {
    var name;
    dashboardRoutes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      } else {
        if (prop.redirect) {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        } else {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        }
      }
      return null;
    });
    return name;
  }

  toggleHeaderMenu() {
    document.querySelector(".Sidebar").classList.toggle('active');
    document.querySelector("#main-panel").classList.toggle('active');
    document.querySelector(".header-menu-bars").classList.toggle('fa-times');
    document.querySelector(".header-menu-bars").classList.toggle('fa-bars');
  }
  render() {
    const UserData = JSON.parse(localStorage.getItem("user"))
    return (
      <Navbar className="CustomHeader" fluid>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            alignContent: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex" }}>
            { UserData && UserData.user.status === 0  &&
             <i
              onClick={() => {
                this.toggleHeaderMenu();
                // this.setState((s) => ({
                //   toggleIcon: !s.toggleIcon,
                // }));
              }}
              className="header-menu-bars fa fa-bars"
              aria-hidden="true"
            ></i>
            }
            <Navbar.Header>
              <OverlayTrigger
                overlay={<Popover id="tooltip">Reload Current Module</Popover>}
              >
                <Navbar.Brand>
                  <div className="logo">
                    <Link
                      to={basePathClient + "Admin"}
                      className="simple-text logo-normal"
                    >
                      <div className="logo-img">
                        <strong>Application Logo</strong>
                      </div>
                    </Link>
                  </div>
                </Navbar.Brand>
              </OverlayTrigger>

              {/* <Navbar.Toggle onClick={this.mobileSidebarToggle} /> */}
              {/* <div style={{ display: "flex", justifyContent: "right" }}> */}
              {/* </div> */}
            </Navbar.Header>
          </div>
          <div style={{ display: "flex" }}>
            <HeaderLinks />
          </div>
        </div>
      </Navbar>
    );
  }
}

export default Header;
