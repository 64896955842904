import React, { useState, useRef } from 'react'
import Modal from 'react-bootstrap-modal'
import { Button } from 'react-bootstrap'
import styles from './searchModalStyles'
import Files from 'react-files'
import { useDispatch } from "react-redux";
// import { alertActions } from "redux/actions/alert-actions";

//import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader


const ImageUploaderModal = ({
  open,
  setOpen,
  imageUploaderData,
  setImageUploaderData,
  stateData,
  hidenotes,
  setStateData,
}) => {

  // const dispatch = useDispatch();

  const [images, setImages] = useState([])
  const [invoices, setInvoices] = useState([])
  const [imagesData, setImagesData] = useState([])
  const [note, setNote] = useState('')
  const idD = parseInt(imageUploaderData.DataId)
  const idI = parseInt(imageUploaderData.ItemId)
  const idS = parseInt(imageUploaderData.ShipmentId)

  const removeImage = (file) => {
    setImages(
      images.filter((i) => {
        if (file.id !== i.id) {
          return i
        }
      })
    )
  }

  const hiddenFileInput = useRef(null)

  const handleClick = event => hiddenFileInput.current.click()

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setInvoices(fileUploaded)
    // if (fileUploaded.size >= 1048576) {
    //   dispatch(alertActions.error('File to big Please select file upto 1mb '));
    //   return
    // }
    // else {
    // }
  }


  const handleRemoveInvoice = (file) => {
    setInvoices()
    setImagesData([])
  }

  function MouseEnter(event) {
    event.target.style.background = '#8D8D8D'
    event.target.style.cursor = 'pointer'
  }

  function MouseLeave(event) {
    event.target.style.background = '#d3d2d2'
    event.target.style.cursor = 'initial'
  }

  const convertToBase64 = async (files) => {
    await Promise.all(
      files.map((file, id) => {
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () =>
            resolve(
              imagesData.push({
                id: '',
                transactions_items_shipments_id: '',
                source: reader.result,
                note: note,
              })
            )
          reader.onerror = (error) => reject(error)
        })
      })
    )

  }
  const convertInvoiceToBase64 = async (file) => {
    await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () =>
        resolve(
          imagesData.push({
            id: '',
            transactions_items_shipments_id: '',
            source: reader.result,
            note: note,
            extension:  file.type === 'application/pdf' ? 'pdf' : file.type
          })
        )
      reader.onerror = (error) => reject(error)
    })
  }

  const updateData = () => {
    const temp = [...stateData]
    temp[idD].shipments[idS].items[idI].images = imagesData

    setStateData(temp)
    setImageUploaderData({
      DataId: '',
      ShipmentId: '',
      ItemId: '',
    })
    setOpen(false)
  }

  const updateInvoiceData = () => {

    let invoiceImage = imagesData
    setStateData(invoiceImage)
    setImageUploaderData({
      DataId: '',
    })
    setOpen(false)
  }

  return (
    <>
      <Modal
        backdrop={'static'}
        show={open}
        onHide={() => setOpen(false)}
        aria-labelledby='ModalHeader'
        dialogClassName='fullscreenmodal'
      >
        <Modal.Header closeButton>
          <Modal.Title
            id='ModalHeader'
            className='headerTitle'
            style={{ width: 600 }}
          ></Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {hidenotes ?
            <>

              <div
                style={styles.imageUploaderContainer}
                onMouseEnter={MouseEnter}
                onMouseLeave={MouseLeave}
                onClick={handleClick}
              >
                <p> Drop files here or click to upload</p>
                <i className='fa fa-upload fa-2x'></i>
                <input type="file"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: 'none' }}
                  accept={['.png', '.jpg', '.jpeg', '.pdf']}
                />
              </div>
              {invoices &&
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <span style={{ marginTop: '15px' }}>
                    {invoices.name}
                  </span>
                  <span
                    className={invoices.name ? 'files-list-item-remove' : ''}
                    onClick={() => { handleRemoveInvoice(invoices) }}
                  />
                </div>
              }
            </>
            :
            <div style={styles.fileUploaderDiv}>
              <Files
                className='files-dropzone'
                onChange={(file) => {
                  setImages(file)
                }}
                accepts={['.png', '.jpg', '.jpeg']}
                multiple
                maxFiles={6}
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <div
                  style={styles.imageUploaderContainer}
                  onMouseEnter={MouseEnter}
                  onMouseLeave={MouseLeave}
                >
                  <p> Drop files here or click to upload</p>
                  <i className='fa fa-upload fa-2x'></i>
                </div>
              </Files>
              {images.length > 0 ? (
                <div className='files-list'>
                  <ul>
                    {images.map((file, index) => (
                      <li className='files-list-item' key={file.id}>
                        <div className='files-list-item-preview'>
                          {file.preview.type === 'image' ? (
                            <img
                              className='files-list-item-preview-image'
                              src={file.preview.url} alt=''
                            />
                          ) : (
                            <div className='files-list-item-preview-extension'>
                              {file.extension}
                            </div>
                          )}
                        </div>
                        <div className='files-list-item-content'>
                          <div className='files-list-item-content-item files-list-item-content-item-1'>
                            {file.name}
                          </div>
                          <div className='files-list-item-content-item files-list-item-content-item-2'>
                            {file.sizeReadable}
                          </div>
                        </div>
                        <div
                          id={file.id}
                          className='files-list-item-remove'
                          onClick={() => { removeImage(file) }}
                        />
                      </li>
                    ))}
                  </ul>
                </div>

              ) : null}


              <hr />
              {hidenotes ? '' :

                <div style={styles.divTextAreaNote}>
                  <label
                    style={{
                      fontSize: '16px',
                      color: '#5d5d5d',
                    }}
                  >
                    Note
                  </label>
                  <textarea
                    aria-multiline
                    type='textarea'
                    style={styles.textAreaNote}
                    name='transaction_id'
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  ></textarea>
                </div>
              }
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button
              type='submit'
              bsStyle='primary'
              style={styles.updateBtn}
              disabled={invoices ? false : true}
              onClick={async () => {
                if (hidenotes) {
                  await convertInvoiceToBase64(invoices)
                  updateInvoiceData()
                }
                else {
                  await convertToBase64(images)
                  updateData()
                }
              }}
            >
              Submit
            </Button>
            <Button
              bsStyle='danger'
              onClick={() => setOpen(false)}
              style={styles.cancelBtn}
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ImageUploaderModal
