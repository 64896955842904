import React from 'react'
import ReactDOM from 'react-dom'
import { Router, Route, Switch } from 'react-router-dom'
import indexRoutes from './routes/index.jsx'
import { history } from './helper/history'
import store from './redux/store/index'
import { Provider } from 'react-redux'
import Scroller from './components/Scroller'
import HttpsRedirect from 'react-https-redirect'
import "bootstrap/dist/css/bootstrap.min.css";


// ReactDOM.render(
// 	<Provider store={store}>

// 		<Router history={history} >
// 			<Scroller>
// 				<Switch>
// 					{indexRoutes.map((prop, key) => {
// 						return <Route exact={prop.exactPro} path={prop.path} component={prop.component} key={key} />;
// 					})}
// 				</Switch>
// 			</Scroller>
// 		</Router>

// 	</Provider>,
// 	document.getElementById("root")
// );

ReactDOM.render(
  <Provider store={store}>
    <HttpsRedirect>
      <Router history={history}>
        <Scroller>
          <Switch>
            {indexRoutes.map((prop, key) => {
              return (
                <Route
                  exact={prop.exactPro}
                  path={prop.path}
                  component={prop.component}
                  key={key}
                />
              )
            })}
          </Switch>
        </Scroller>
      </Router>
    </HttpsRedirect>
  </Provider>,
  document.getElementById('root')
)
