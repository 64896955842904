import React, { useState, useEffect } from 'react'
import FieldGroup from './components/FieldGroup'
//import { Grid, Row, Col, Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { alertActions } from 'redux/actions/alert-actions'
import { ORDER_STATUSES, CARRIERS } from '../../../constants'
import { transactionServices } from '../../../services/transaction'

const ShowRefundItems = ({
  row,
  inventories,
  emails,
  address,
  setExpended1,
  setRefreshEverythingElseData,
}) => {
  const dispatch = useDispatch()
  const [refundtransactionDetail, setRefundTransactionDetail] = useState()
  const [refundOrdrStatus, setRefundOrdrStatus] = useState({
    id: null,
    status: null,
  })

  const [refundOrderNo, setRefundOrderNo] = useState('')
  const [alreadyLinked, setAlreadyLinked] = useState(false)
  const [emailIndex , setEmailIndex] =useState(null)
  const [addressIndex , setAddressIndex] =useState(null)
  //const [rowId,setRowId]=useState(null)
  
  const[ filterReturnItem , setFilterReturnItem ]= useState([]) 

  useEffect(() => {
    transactionServices.getRefundParent(row.original.id).then(
      (response) => {
        setRefundTransactionDetail(response.data.parent)
        if (response.data.parent) {
          setRefundOrdrStatus({
            id: response.data.parent.order.id,
            status: response.data.parent.order.status,
          })

          let defaultAddress = response.data.parent.order.address_id &&
           address.find(el => el.id === response.data.parent.order.address_id)
          if (defaultAddress) {
            setAddressIndex(defaultAddress.address)
          }

          let defaultEmail = response.data.parent.email_id &&
            emails.find(el => el.id === response.data.parent.email_id)
          if (defaultEmail) {
            setEmailIndex(defaultEmail.email)
          }

          setAlreadyLinked(true)
          setRefundOrderNo(response.data.parent.order.order_number)
          if (response.data.parent.returnshipments) {
            const tempArr = response.data.parent.returnshipments ? response.data.parent.returnshipments.map(i => i.returnshipmentitem) : []
            setFilterReturnItem(tempArr)
          }
          else {
            setFilterReturnItem([])
          }
        }
      },
      (error) => {
        //console.log('error', error)
      }
    )
  }, [])

  const findInventory = (id) => {
    return inventories.find((e) => e.id === id).name
  }

  const setrefundOrderStatusValue = (e) => {
    setRefundOrdrStatus({ ...refundOrdrStatus, status: e.target.value })
  }

  const refundSubmitInventory = async (rowId) => {
    //e.preventDefault()

    //await linkRefundTransaction()
    //console.log('refundtransactionDetail', refundtransactionDetail)

    // if (!refundtransactionDetail) {
    //   dispatch(alertActions.error([['Link it first than you can store']]))
    //   return false
    // }

    const data = {
      parent_id: refundtransactionDetail.id,
      child_id: rowId,
      order: refundOrdrStatus,
    }



    transactionServices.attachRefundTransaction(data).then(
      (response) => {
        //console.log('response', response)
        dispatch(alertActions.success(response.message))
        // setRefundTransactionDetail(null)
        setExpended1({ 0: false })
        setRefreshEverythingElseData(true)
      },
      (error) => {
        dispatch(alertActions.error(error))
      }
    )
  }

  const linkRefundTransaction = async () => {
    // console.log('transactionDetails', transactionDetail)

    if (!refundOrderNo) {
      dispatch(alertActions.error('add order number to link'))
      return
    }

    let res = await transactionServices.getRefundTransaction(refundOrderNo)

    //console.log('========res', res)

    if (res.status === false) {
      dispatch(alertActions.error(res.message))
      setRefundTransactionDetail(null)
      //setTransactionDetail(null)
      return
    } else {
      setRefundTransactionDetail(res.data)
      setRefundOrdrStatus({
        id: res.data.order.id,
        status: res.data.order.status,
      })
      setRefundOrderNo(res.data.order.order_number)
      //setTransactionDetail(res.data.items)
    }
  }

  useEffect(() => {
    if (refundOrderNo !== '') {
      refundSubmitInventory(row.original.id)
    }
  }, [refundtransactionDetail])

  return (
    <form
      onSubmit={(e, id) => {
        //console.log('onsubmit call')
        //refundSubmitInventory(e, row.original.id)
        //linkRefundTransaction()
      }}
      style={{ overflowX: 'scroll' }}
      //method='post'
    >
      <div style={{ width: '95%', margin: 'auto' }}>
        <div className='row'>
          <div className='col-sm-4'>
            <FieldGroup
              id='order_number'
              type='text'
              label='ORDER NUMBER'
              name='order_number'
              value={
                refundOrderNo
                // refundtransactionDetail
                //   ? refundtransactionDetail.order.order_number
                //   : refundOrderNo
              }
              onChange={(e) => {
                setRefundOrderNo(e.target.value)
                // setOrderValues(e)
              }}
            />
          </div>

          <div className='col-sm-4'>
            <FieldGroup
              id='email'
              type='email'
              label='EMAIL'
              name='email'
              value={ emailIndex ? emailIndex : '' }
              readOnly
            />
          </div>
          <div className='col-sm-4'>
            <div className='form-group'>
              <label style={{ fontSize: '12px' }}>ORDER STATUS</label>
              <select
                className='form-control'
                // disabled={!("new" in product) ? true : false}
                name='status'
                onChange={setrefundOrderStatusValue}
              >
                <option value=''>-----</option>
                {ORDER_STATUSES.map(({ value, label }) => {
                  return (
                    <option
                      key={value}
                      value={value}
                      selected={
                        refundtransactionDetail &&
                        refundtransactionDetail.order.status === value
                      }
                    >
                      {label}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <FieldGroup
              id='shipping_address'
              type='text'
              label='SHIPPING ADDRESS'
              name='shipping_address'
              value={ addressIndex? addressIndex : '' }
              readOnly
            />
          </div>
          {/* <div className='col-sm-2'>
            <FieldGroup
              id='zipcode'
              type='text'
              label='ZIP CODE'
              name='zipcode'
              value={
                refundtransactionDetail
                  ? refundtransactionDetail.order.zipcode
                  : ''
              }
              readOnly
            />
          </div>
          <div className='col-sm-2'>
            <FieldGroup
              id='state'
              type='text'
              label='STATE'
              name='state'
              value={
                refundtransactionDetail
                  ? refundtransactionDetail.order.state
                  : ''
              }
              readOnly
            />
          </div>
          <div className='col-sm-2'>
            <FieldGroup
              id='city'
              type='text'
              label='CITY'
              name='city'
              value={
                refundtransactionDetail
                  ? refundtransactionDetail.order.city
                  : ''
              }
              readOnly
            />
          </div> */}
        </div>
      </div>

      <table className='table'>
        <thead>
          {refundtransactionDetail && (
            <tr>
              <th></th>
              <th>Item</th>
              <th>Qty Ordered</th>
              <th>Cost</th>
              <th>Taxes</th>
              <th>Shipping</th>
            </tr>
          )}
        </thead>
        <tbody>
          {refundtransactionDetail &&
            refundtransactionDetail.items.map(function(item, ind) {
              return (
                <>
                  <tr key={ind}>
                    <td width='1px'></td>

                    <td width='250px'>
                      <input
                        value={
                          item.inventory_id
                            ? findInventory(item.inventory_id)
                            : null
                        }
                        name='qty_ordered'
                        readOnly
                        className='form-control leftPadding'
                        type='text'
                        min='0'
                      />
                    </td>
                    <td
                      className='bigFieldSec'
                      style={{ position: 'relative' }}
                    >
                      <input
                        value={item.qty_ordered}
                        name='qty_ordered'
                        readOnly
                        className='form-control leftPadding'
                        type='number'
                        min='0'
                      />
                    </td>
                    <td
                      className='bigFieldSec'
                      style={{ position: 'relative' }}
                    >
                      <span
                        className='dollarSign'
                        //  style={{ marginTop: '6px' }}
                      >
                        $
                      </span>
                      <input
                        value={item.price}
                        name='price'
                        readOnly
                        className='form-control leftPadding'
                        type='number'
                        min='0'
                        step='any'
                        style={{ paddingLeft: '30px' }}
                      />{' '}
                    </td>
                    <td
                      className='bigFieldSec'
                      style={{ position: 'relative' }}
                    >
                      <span className='dollarSign'>$</span>
                      <input
                        value={item.taxes}
                        name='taxes'
                        readOnly
                        className='form-control leftPadding'
                        type='number'
                        min='0'
                        step='any'
                        style={{ paddingLeft: '30px' }}
                      />{' '}
                    </td>
                    <td
                      className='bigFieldSec'
                      style={{ position: 'relative' }}
                    >
                      <span className='dollarSign'>$</span>
                      <input
                        value={item.shipping}
                        name='shipping'
                        readOnly
                        className='form-control leftPadding'
                        type='number'
                        min='0'
                        step='any'
                        style={{ paddingLeft: '30px' }}
                      />{' '}
                    </td>
                  </tr>
                  {/* <tr>
                    <td colSpan='6'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th></th>
                            <th>SHIPMENT</th>
                            <th>Item</th>
                            <th>Expected QTY</th>
                            <th>courier</th>
                            <th>tracking No.</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.shipments &&
                            item.shipments.map((shipment, idx) => {
                              return (
                                <tr style={{ justifyContent: 'space-around' }}>
                                  <td className='smallFieldSec'></td>
                                  <td className='smallFieldSec'>
                                    <input
                                      value={idx + 1}
                                      name='id'
                                      className='form-control leftPadding'
                                      min='1'
                                      style={{ paddingLeft: '25px' }}
                                      readOnly
                                    />{' '}
                                  </td>
                                  <td
                                    className='mediumFieldSec'
                                    style={{ marginLeft: 10 }}
                                  >
                                    <input
                                      value={
                                        item.inventory_id
                                          ? findInventory(item.inventory_id)
                                          : null
                                      }
                                      name={'item'}
                                      className='form-control leftPadding'
                                      style={{ paddingLeft: '15px' }}
                                      readOnly
                                    />
                                  </td>
                                  <td className='mediumFieldSec'>
                                    <input
                                      value={shipment.qty_expected}
                                      name='qty_expected'
                                      readOnly
                                      className='form-control leftPadding'
                                      type='number'
                                      min='0'
                                      step='any'
                                      style={{ paddingLeft: '15px' }}
                                    />{' '}
                                  </td>
                                  <td className='mediumFieldSec'>
                                    <select
                                      className='form-control'
                                      name='courier'
                                      disabled={true}
                                      readOnly
                                    >
                                      <option value=''>-----</option>
                                      {CARRIERS.map(({ value, label }) => {
                                        return (
                                          <option
                                            key={value}
                                            value={value}
                                            selected={
                                              shipment.courier === value
                                            }
                                          >
                                            {label}
                                          </option>
                                        )
                                      })}
                                    </select>{' '}
                                  </td>
                                  <td className='mediumFieldSec'>
                                    <input
                                      value={shipment.tracking_number}
                                      name='tracking_number'
                                      readOnly
                                      className='form-control leftPadding'
                                      type='text'
                                      min='0'
                                      step='any'
                                      style={{ paddingLeft: '15px' }}
                                    />{' '}
                                  </td>
                                  <td className='smallFieldSec'></td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                    </td>
                  </tr> */}
                </>
              )
            })}
          {refundtransactionDetail &&
            refundtransactionDetail.shipments.map(function(ship, ins) {
              return (
                <tr>
                  <td colSpan='6'>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th>SHIPMENT</th>
                          <th>Item</th>
                          <th>Expected QTY</th>
                          <th>
                            <button
                              type='button'
                              value='remove'
                              className='btn btn-sm btn-danger'
                              style={{ display: 'flex', marginLeft: 'auto' }}
                              onClick={() => {
                                // console.log('id',ins)
                                // removeShipmentRow(item, idx, ind)
                                // removeShipment(ins, ship.id)
                              }}
                            >
                              <i className='glyphicon glyphicon-remove'></i>
                              &nbsp;
                            </button>
                          </th>

                          {/* <th>courier</th>
                                  <th>tracking No.</th> */}
                          {/* <th>
                                    <button
                                      onClick={() => addShipmentRow(item, ind)}
                                      type='button'
                                      className='btn btn-sm btn-info'
                                      style={{ marginLeft: '30px' }}
                                    >
                                      <i className='glyphicon glyphicon-plus'></i>
                                      &nbsp;
                                    </button>
                                  </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {ship.items &&
                          ship.items.map((item, idx) => {
                            return (
                              <tr>
                                {idx === 0 ? (
                                  <td className='smallFieldSec'>
                                    <input
                                      value={ins + 1}
                                      name='id'
                                      //className='form-control leftPadding'
                                      disabled={idx > 0}
                                      //type="number"
                                      min='1'
                                      //step="any"
                                      style={{
                                        paddingLeft: '25px',
                                        width: '40px',
                                        border: '0px',
                                        fontWeight: 500,
                                      }}
                                      readOnly
                                    />{' '}
                                  </td>
                                ) : (
                                  <td className='smallFieldSec'></td>
                                )}
                                <td className='bigFieldThird'>
                                  <input
                                    value={
                                      item.inventory_id
                                        ? findInventory(item.inventory_id)
                                        : null
                                    }
                                    name={'inventory_id'}
                                    className='form-control leftPadding'
                                    style={{ paddingLeft: '15px' }}
                                    readOnly
                                  />
                                </td>
                                <td className='hundredField'>
                                  <input
                                    value={item.qty_expected}
                                    name='qty_expected'
                                    // onChange={
                                    //   (e) => {
                                    //     let details = [...shipment]
                                    //     if (Number(e.target.value) > 0) {
                                    //       details[ins].items[
                                    //         idx
                                    //       ].qty_expected = Number(
                                    //         e.target.value
                                    //       )
                                    //     } else {
                                    //       details[ins].items[
                                    //         idx
                                    //       ].qty_expected = null
                                    //     }
                                    //     setShipment(details)
                                    //   }
                                    //   //updateShipment(e, shipment, idx, ind)
                                    // }
                                    className='form-control leftPadding'
                                    type='number'
                                    min='0'
                                    // max={checkExpectedQuantity(
                                    //   item,
                                    //   idx + 1
                                    // )}
                                    step='any'
                                    style={{ paddingLeft: '15px' }}
                                    readOnly
                                  />{' '}
                                </td>
                                <td></td>

                                {/* <td className='mediumFieldSec'>
                                          <select
                                            className='form-control'
                                            name='courier'
                                            onChange={(e) =>
                                              updateShipment(e, shipment, idx, ind)
                                            }
                                          >
                                            <option value=''>-----</option>
                                            {CARRIERS.map(({ value, label }) => {
                                              return (
                                                <option
                                                  key={value}
                                                  value={value}
                                                  selected={
                                                    shipment.courier === value
                                                  }
                                                >
                                                  {label}
                                                </option>
                                              )
                                            })}
                                          </select>{' '}
                                        </td>
                                        <td className='mediumFieldSec'>
                                          <input
                                            value={shipment.tracking_number}
                                            name='tracking_number'
                                            onChange={(e) =>
                                              updateShipment(e, item, idx, ind)
                                            }
                                            className='form-control leftPadding'
                                            type='text'
                                            min='0'
                                            step='any'
                                            style={{ paddingLeft: '15px' }}
                                          />{' '}
                                        </td>
                                        <td
                                          className='mediumFieldSec'
                                          style={{ position: 'relative' }}
                                        >
                                          <button
                                            type='button'
                                            value='remove'
                                            className='btn btn-sm btn-danger'
                                            style={{ marginLeft: '30px' }}
                                            onClick={() => {
                                              removeShipmentRow(item, idx, ind)
                                            }}
                                          >
                                            <i className='glyphicon glyphicon-remove'></i>
                                            &nbsp;
                                          </button>
                                        </td> */}
                              </tr>
                            )
                          })}
                        <tr>
                          <td colSpan='14'>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              <div
                                style={{
                                  flexDirection: 'column',
                                  marginLeft: '75px',
                                }}
                              >
                                <th
                                  style={{
                                    fontSize: '12px',
                                    color: '#9a9a9a',
                                    fontWeight: 500,
                                  }}
                                >
                                  COURIER
                                </th>
                                {/* <input
                                    className='form-control leftPadding'
                                    style={{ marginTop: '2px', width: '100px' }}
                                  /> */}
                                <select
                                  className='form-control'
                                  name='courier'
                                  disabled={true}
                                  //onChange={(e) => updateShipment(e, ins)}
                                >
                                  <option value=''>-----</option>
                                  {CARRIERS.map(({ value, label }) => {
                                    return (
                                      <option
                                        key={value}
                                        value={value}
                                        selected={ship.courier === value}
                                      >
                                        {label}
                                      </option>
                                    )
                                  })}
                                </select>{' '}
                              </div>
                              <div
                                style={{
                                  marginLeft: '20px',
                                  flexDirection: 'column',
                                }}
                              >
                                {' '}
                                <th
                                  style={{
                                    fontSize: '12px',
                                    color: '#9a9a9a',
                                    fontWeight: 500,
                                  }}
                                >
                                  TRACKING NUMBER
                                </th>
                                <input
                                  value={ship.tracking_number}
                                  name='tracking_number'
                                  type='text'
                                  readOnly
                                  // onChange={(e) => {
                                  //   updateShipment(e, ins)
                                  // }}
                                  className='form-control leftPadding'
                                  style={{ width: '250px' }}
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              )
            })}

             {/* return items on abnormal page */}
            
              
             { refundtransactionDetail && refundtransactionDetail.returnshipments.length > 0 
               && filterReturnItem.length > 0 &&
              filterReturnItem.map((item, ind) => {
                return (
                  <>
                    {item.map((i, k) => {
                      return (
                        <tr>
                          <td colSpan="18">
                            <tr key={k}>
                              <td style={{ width: '240px', padding: '6px' }}>
                                <label
                                  style={{ fontSize: "12px", color: "#9a9a9a", fontWeight: 500, padding: '2px 2px' }}>
                                  Item Return
                                </label>
                                
                                <input
                                  value=    {
                                    i.inventory_id ? findInventory( Number( i.inventory_id))
                                    : null
                                   }
                                  name={"inventory_id"}
                                  className="form-control leftPadding"
                                  style={{ paddingLeft: "15px" }}
                                  disabled={true}
                                />
                                
                              </td>
                              <td>
                                <label
                                  style={{ fontSize: "12px", color: "#9a9a9a", fontWeight: 500, padding: '2px 2px' }}>
                                  Qty Return
                                </label>
                                <input
                                  value={i.return_item_qty}
                                  name="returnQty"
                                  style={{ width: '250px' }}
                                  disabled={true}
                                  className="form-control leftPadding"
                                  type="number"
                                  min="0"
                                />
                              </td>
                            </tr>
                          </td>
                        </tr>
                      )
                    })}
                  </>
                );
              })}
                       
             { refundtransactionDetail && refundtransactionDetail.returnshipments.length > 0 && 
              <tr>
                <td colSpan="14">
                  <div
                    style={{ display: "flex", flexDirection: "row", marginLeft: '-55px' }}
                  >
                    <div
                      style={{ flexDirection: "column", marginLeft: "75px" }}
                    >
                      <th
                        style={{ fontSize: "12px", color: "#9a9a9a", fontWeight: 500, }}
                      >
                        COURIER Return
                      </th>
                      <input
                        value={refundtransactionDetail.returnshipments[0].courier}
                        name="courier"
                        type="text"
                        className="form-control leftPadding"
                        style={{ width: "250px" }}
                        disabled={true}
                      />

                    </div>
                    <div
                      style={{ marginLeft: "20px", flexDirection: "column", }}
                    >
                      <th
                        style={{ fontSize: "12px", color: "#9a9a9a", fontWeight: 500, }}
                      >
                        RETURN TRACKING NUMBER
                      </th>
                      <input
                        value={refundtransactionDetail.returnshipments[0].tracking_no}
                        name="trackingNumber"
                        type="text"
                        disabled={true}
                        className="form-control leftPadding"
                        style={{ width: "250px" }}
                      />
                    </div>
                    <div
                      style={{ marginLeft: "20px", flexDirection: "column" }}
                    >
                      <th
                        style={{
                          fontSize: "12px", color: "#9a9a9a", fontWeight: 500,
                        }}
                      >
                        RETURN RMA NUMBER
                      </th>
                      <input
                        value={refundtransactionDetail.returnshipments[0].rma_no}
                        name="rmaNumber"
                        type="number"
                        disabled={true}
                        className="form-control leftPadding"
                        style={{ width: "250px" }}
                      />
                    </div>

                  </div>
                </td>
              </tr>
            }
            {/* end returns items */}

          {/* <tr>
            <td colSpan='14'>
              {refundtransactionDetail &&
                refundtransactionDetail.shipments.length === 0 && (
                  <button
                    //onClick={addRow}
                    type='button'
                    className='btn btn-sm btn-info'
                  >
                    <i className='glyphicon glyphicon-plus'></i>
                    &nbsp;
                  </button>
                )}
              {refundtransactionDetail && refundtransactionDetail.shipments
                .length === 0 && (
                <button
                  type='button'
                  value='remove'
                  className='ml-sm  btn btn-sm btn-danger'
                  //onClick={handleRemoveItem}
                >
                  <i className='glyphicon glyphicon-remove'></i>&nbsp;
                </button>
              )}
              <input
                type='button'
                value={'Add Shipment'}
                // onClick={() => {
                //   appendShipment()
                // }}
                className='btn btn-sm btn-info ml-sm'
              />
            </td>
          </tr> */}
        </tbody>

        <tfoot>
          <tr>
            <td colSpan='14'>
              <span
                className='pull-right'
                style={{ position: 'sticky', right: '5px' }}
              >
                {/* <input
                  type='button'
                  value={'Link'}
                  onClick={() => linkRefundTransaction()}
                  style={{ marginRight: 10 }}
                  disabled={alreadyLinked}
                  className='btn btn-md btn-primary ml-sm'
                /> */}

                <input
                  type='button'
                  value={'Link & Save'}
                  className='btn btn-md btn-info'
                  onClick={() => {
                    linkRefundTransaction()
                  }}
                />
                <input
                  type='button'
                  value={'Cancel'}
                  onClick={() => {
                    setExpended1({ 0: false })
                    setRefundTransactionDetail(null)
                  }}
                  className='btn btn-md btn-danger ml-sm'
                />
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </form>
  )
}

export default ShowRefundItems
