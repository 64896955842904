import moment from 'moment'
function formatData (chunked,inventories) {
  let data = chunked.slice(2);
  let a = []
  for (let i = 0; i < data.length; i++) {
    let order = {}
    let items = []
    let shipments = []
    let vendor = {}
    let transaction_type = {}
    let transaction_state = {}
    let o = { order, vendor, items, shipments,transaction_type, transaction_state }
    let row = data[i]
    let l = (row.length - 14) / 5;
    if (row.length > 0) {
      for (let j = 0; j < row.length; j++) {
        o.transaction_id = row[0]
        o.amount = row[1]
        o.account_name = row[2]
        o.date = moment(new Date(row[3])).format("YYYY-MMM-DD")
        o.name = row[4]
        o.transaction_type.name = row[5]
        o.transaction_state.name = row[6]
        o.order.order_number = row[7]
        o.order.shipping_address = row[8]
        o.email = row[9]
        o.order.status = row[10]
        o.order.taxes = row[11]
        o.order.shippingNo = row[12]
        // o.order.zipcode = row[9]
        // o.order.state = row[10]
        // o.order.city = row[11]
        // o.order.tracking_number = row[13]
      }

      if (l > 0) {
        let v = 14
        for (let k = 0; k < 7; k++) {
          let b = { new: true }
          b.name = row[v++]
          b.qty_ordered = row[v++]
          b.price = row[v++]
          // b.taxes = row[v++]
          // b.shipping = row[v++]
          if (b.qty_ordered === '') b.qty_ordered = 0
          if (b.price === '') b.price = 0
          // if (b.taxes === '') b.taxes = 0
          // if (b.shipping === '') b.shipping = 0
          if (b.name !== '') o.items.push(b)
        }
      }
      let shipmentIndex = 35;
       for(let i = shipmentIndex; i < row.length;  i){
         let c = {new :true};
        
         c.courier = row[i++];
         c.tracking_number = row[i++] 
         c.items = [];
         for(let j=0; j< 7; j++){
             let obj= {};
             obj["name"]= row[i] ? row[i] : '';
             i++;
             obj["qty_expected"] = row[i] ? parseInt(row[i]) : ''
             i++;
             if(obj.name !== '') c.items.push(obj)
            }
         if(c.courier !== '' && c.tracking_number !== '' ) o.shipments.push(c)
       }
      if (o.transaction_id !== '') a.push(o)
     
    }
  }
  return a
}

/*function getInventoryId(name,items) {
   let data = items.find(i => { 
     if(i.name === name){
       return i.id
     }
   });
   if(data){
    return data.id;
   }
   
    
  
}*/

export { formatData }
