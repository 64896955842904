import { SC } from "../helper/ServerCall";

export const ErrorServices = {
  getErrors,
  deleteErrors,
  deleteAllError
};

function getErrors() {
  return SC.getCall(`geterrors`);
}

function deleteErrors(id) {
    return SC.getCall(`deleteerror/${id}`);

  }

function deleteAllError() {
    return SC.deleteCall(`delete-all-error-logs`);
}
  