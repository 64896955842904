import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { objectsConstants as OC } from "redux/constant/objects-constant";
import useValidator from "components/ThemeComponents/useValidator";
import { inventoryServices } from "../../../services/inventory";
import { useDispatch } from "react-redux";
import { alertActions } from "redux/actions/alert-actions";

const Form = ({ type, setAdd, setUpdate, id, paginationReload }) => {
  const [validator, showValidationMessage] = useValidator();
  const [product, setProduct] = useState(OC.INVENTORY);
  const dispatch = useDispatch();
  const onChange = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };
  const { name, in_bound, on_hand } = product;

  const handleSubmit = () => {
    if (validator.allValid()) {
      if (type === "add") {
        addInventory();
      }
      if (type === "update") {
        updateInventory();
      }
    } else {
      showValidationMessage(true);
    }
  };

  const addInventory = async () => {
    await inventoryServices.addInventory(product).then(
      (response) => {
        dispatch(alertActions.success(response.message));
        setAdd(false);
      },
      (error) => {
        dispatch(alertActions.error(error));
      }
    );

    await paginationReload();
  };
  const updateInventory = async () => {
    await inventoryServices.updateInventory(product, id).then(
      (response) => {
        dispatch(alertActions.success(response.message));
        setUpdate(false);
      },
      (error) => {
        dispatch(alertActions.error(error));
      }
    );
    await paginationReload();
  };

  const getInventory = async () => {
    const res = await inventoryServices.getSingleInventory(id);
    setProduct(res.inventory);
  };
  useEffect(() => {
    if (type === "update") getInventory();
  }, [type]);

  return (
    <div>
      <form className="shadow-lg">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            className="form-control"
            name="name"
            value={name}
            onChange={onChange}
          />
          {validator.message("name", name, "required", {
            messages: {
              required: "Name is required",
            },
          })}
        </div>

        {/* <div className="form-group">
          <label htmlFor="qty">Quantity</label>
          <input
            type="text"
            id="qty"
            className="form-control"
            name="qty"
            value={qty}
            onChange={onChange}
          />
          {validator.message("qty", qty, "required|numeric", {
            messages: {
              required: "Quantity is required",
            },
          })}
        </div> */}

        <div className="form-group">
          <label htmlFor="in_bound">In Bound</label>
          <input
            type="text"
            id="in_bound"
            className="form-control"
            name="in_bound"
            value={in_bound}
            onChange={onChange}
          />
          {/* {validator.message("in_bound", in_bound, "required|numeric", {
            messages: {
              required: "In Bound is required",
            },
          })} */}
        </div>
        <div className="form-group">
          <label htmlFor="on_hand">On Hand</label>
          <input
            type="text"
            id="on_hand"
            className="form-control"
            name="on_hand"
            value={on_hand}
            onChange={onChange}
          />
          {/* {validator.message("on_hand", on_hand, "required|numeric", {
            messages: {
              required: "On Hand is required",
            },
          })} */}
        </div>
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: -10,
          }}
        >
          <Button
            bsSize="small"
            style={{
              marginRight: 10,
              border: "white",
              color: "white",
              backgroundColor: "#EE2D20",
            }}
            onClick={
              type === "add"
                ? () => setAdd(false)
                : type === "update"
                  ? () => setUpdate(false)
                  : null
            }
          >
            Close
          </Button>
          <Button
            bsSize="small"
            style={{
              border: "white",
              color: "white",
              backgroundColor: "#2F4576",
            }}
            onClick={handleSubmit}
          >
            {type === "add" ? "Add" : type === "update" ? "Update" : null}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Form;
