import { SC } from "../helper/ServerCall";

export const addresServices = {
  getAddress,
  // getInventoryPagination,
  
  addAddress,
  updatedAddress,
  getSingleAddress,
  getAllAddress
 
};


/* function getInventoryPagination(page, filter, sort, pageSize) {
  return SC.getCall(
    "inventories?page=" +
      page +
      "&filter=" +
      filter +
      "&sort=" +
      sort +
      "&pageSize=" +
      pageSize
  );
} */

function getSingleAddress(id) {
  return SC.getCall(`getSingleAddress/${id}`);
}

function getAllAddress(data) {
  return SC.getCall(`getaddress`, data);
}

//getAddress
function getAddress(data) {
  return SC.postCall(`getAddress`, data);
}
function addAddress(data) {
  return SC.postCall(`addAddress`, data);
}


function updatedAddress(data) {
  return SC.postCall(`addAddress`, data);
}